export enum HealthPlanStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0,
}

export const HealthPlanStatusEnumLabels = (status: HealthPlanStatusEnum) => {
  const labels = {
    [HealthPlanStatusEnum.ACTIVE]: 'Ativo',
    [HealthPlanStatusEnum.INACTIVE]: 'Inativo',
  };

  return labels[status];
};
