import { makeAutoObservable } from "mobx";

export interface ScheduleTypesLight {
  id: number;
  name: string;
}

class ListAllScheduleTypesStore {
  healthPlansLight: ScheduleTypesLight[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setScheduleTypesLight(healthPlansLight: ScheduleTypesLight[]) {
    this.healthPlansLight = healthPlansLight;
  }

  getScheduleTypesLight(): ScheduleTypesLight[] {
    return this.healthPlansLight;
  }

  healthPlansLightIsEmpty(): boolean {
    return !this.healthPlansLight?.length;
  }

  cleanData(): void {
    this.healthPlansLight = [];
  }
}

const listAllScheduleTypesStore = new ListAllScheduleTypesStore();

export default listAllScheduleTypesStore;
