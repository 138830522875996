import { format } from "date-fns";
import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";
import { ScheduleEnumsIcons } from "../../../common/schedule-enums-view";
import { ScheduleTypeLabels } from "domain/schedule/enums/schedule-type.enum";
export const CustomCalendarEvent = (event: any) => {
  const formattedStartTime = format(event.event.start, "HH:mm");
  const formattedEndTime = format(event.event.end, "HH:mm");

  const getAppointmentName = () => {
    if (event.event?.type !== null && event.event?.type !== undefined) {
      return ScheduleTypeLabels(event.event?.type);
    } else if (event.event?.schedule_type?.name) {
      return event.event?.schedule_type?.name;
    }
    return "";
  };
  return (
    <>
      <div className="mt-1 mb-1">{event.event?.patient?.name ?? 'AGENDA BLOQUEADA'}</div>

      <div className="my-1 fs-12">
        
        <span>
          <i
            className={
              ScheduleEnumsIcons[event.event.status as ScheduleStatusEnum]
            }
            aria-hidden="true"
          />
        </span>
        <span> </span>
        {getAppointmentName()} 
        ({`${formattedStartTime} - ${formattedEndTime}`})
      </div>
    </>
  );
};
