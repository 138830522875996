import { CustomTable } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { ListAdminsResponse } from "services/user-service";

export interface ListAdminsTableProps {
  admins: ListAdminsResponse | null;
  onSelectAdmin: (adminId: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const ListAdminsTable = ({
  admins,
  onSelectAdmin,
  onChangePage,
  tableType,
  setTableType,
}: ListAdminsTableProps) => {
  const columnNames = {
    document_number: "CPF",
    name: "Nome",
    created_at: "Criado em",
  };

  return (
    <>
      {admins !== null ? (
        <>
          <CustomTable
            columnNames={columnNames}
            data={admins.items}
            onSelect={onSelectAdmin}
            tableType={tableType}
            setTableType={setTableType}
            btnLabelNotFound="+ Criar Administrador"
            btnClickNotFound={() => alert("Criar Administrador")}
          />
          <CustomPagination
            {...Object.assign({}, admins.meta)}
            onChangePage={onChangePage}
          />{" "}
        </>
      ) : (
        <> </>
      )}
    </>
  );
};
