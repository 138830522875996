import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  EletronicPrescriptionService,
  GetPrescriptionDownloadVerificationCodeResponse,
} from "services/eletronic-prescription-service";
import { errorHandler } from "helpers/http-helper";

export const actionGetLinkDownloadPrescriptionValidationCode = action(
  async (
    verificationCode: string,
    verificationCodeConfirmation: string
  ): Promise<GetPrescriptionDownloadVerificationCodeResponse[]> => {
    SetIsLoadingState(true);
    try {
      return await EletronicPrescriptionService.getLinkDownloadPrescriptionValidationCode(
        verificationCode,
        verificationCodeConfirmation
      );
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
