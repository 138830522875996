import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { dateFormatter } from "helpers/date-formatter";
import { ListAllEstimatedReturnAppointmentItems } from "services/schedule-service";
import { useState } from "react";
import ScheduleCalendarTab from "../../schedule/index/tabs/calendar/schedule-calendar-tab";
import { EstimatedReturnHeaderInfo } from "./estimated-return-header-info";

interface EstimatedReturnDetailProps {
  data: ListAllEstimatedReturnAppointmentItems | null;
  onRefresh?: () => void;
}

export const EstimatedReturnDetail: React.FC<EstimatedReturnDetailProps> = ({
  data,
  onRefresh,
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);

  const onSubmitCreateAppointment = async (_input: any) => {
    setOpenCalendar(false);
    onRefresh && (await onRefresh());
  };

  return (
    <>
      {data && (
        <>
          <Row className="p-0">
            <Row className="mb-3">
              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Nome Profissional
                </Col>
                <Col sm="12" md="7">
                  {data.doctor?.name}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Nome Paciente
                </Col>
                <Col sm="12" md="7">
                  {data.patient?.name}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Data Atendimento
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(data.current_appointment_date, "dd/MM/yyyy")}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Previsão de Retorno
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(data.estimated_return_date, "dd/MM/yyyy")}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Observação
                </Col>
                <Col sm="12" md="7">
                  {data.estimated_return_observation ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <div className="col-12">
                  <Button
                    color="secondary"
                    onClick={() => setOpenCalendar(true)}
                    className=" form-control"
                  >
                    Marcar Retorno
                  </Button>
                </div>
              </Row>
            </Row>
          </Row>

          <Modal
            isOpen={openCalendar}
            toggle={() => setOpenCalendar(false)}
            size="xl"
          >
            <ModalHeader toggle={() => setOpenCalendar(false)}>
              Detalhes
            </ModalHeader>
            <ModalBody>
              <EstimatedReturnHeaderInfo
                patientName={data.patient?.name ?? ""}
                doctorName={data.doctor?.name ?? ""}
                currentAppointmentDate={data.current_appointment_date}
                estimatedReturnDate={data.estimated_return_date}
              />

              <hr />
              <ScheduleCalendarTab
                selectedDate={new Date(data.estimated_return_date)}
                doctorId={data.doctor?.id}
                onSubmitCreateAppointment={onSubmitCreateAppointment}
              />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};
