import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListAdminsProps,
  ListAdminsResponse,
  UserService,
} from "services/user-service";

export const actionFetchAllAdmins = action(
  async (input: ListAdminsProps): Promise<ListAdminsResponse> => {
    SetIsLoadingState(true);
    try {
      return UserService.fetchAllAdmins(input);
    } catch (error) {
      return {
        items: [],
        meta: {},
      } as unknown as ListAdminsResponse;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
