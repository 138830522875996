import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  Calendar,
  NavigateAction,
  SlotInfo,
  View,
  Views,
  dateFnsLocalizer,
} from "react-big-calendar";
import { Form, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useEffect, useMemo, useState } from "react";
import ptBR from "date-fns/locale/pt-BR";
import getDay from "date-fns/getDay";
import format from "date-fns/format";
import parse from "date-fns/parse";

import { CustomCalendarEvent } from "views/painel/schedule/index/tabs/calendar/custom-calendar-event";
import { actionListAllSchedule } from "actions/schedule/action-list-all-schedule";
import { ListSchedule } from "views/painel/schedule/list-schedule/list-schedule";
import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";
import { actionUpdateSchedule } from "actions/schedule/action-update-schedule";
import { actionMakeSchedule } from "actions/schedule/action-make-schedule";
import {
  HealthPlanItem,
  MakeScheduleProps,
  ScheduleTypeItem,
} from "services/schedule-service";
import { errorMessage } from "helpers/toast";
import {
  MakeScheduleContainer,
  MakeScheduleDataProps,
} from "views/painel/schedule/common/make-schedule-container";
import {
  SCHEDULE_STATUS_BLOCKED,
  ScheduleTypeBackgroundColors,
  ScheduleTypeColors,
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { dateFormatter, utcToSaoPaulo } from "helpers/date-formatter";
import { ScheduleOriginContactEnum } from "domain/schedule/enums/schedule-origin-contact.enum";

import {
  SelectDoctorState,
  SelectDoctorStateProps,
} from "components/form/input/select-doctor-state-form";
import { CustomInputSearchClearable } from "components/form/input/custom-input-search-clearable";
import UserIdentity from "domain/user/entities/user-identity";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import { labelMaskFormatToReal } from "helpers/format-money";
import { PaymentTypeEnum } from "domain/schedule/enums/payment-type.enum";

import "./schedule.style.css";
import { actionListAllHealthPlanLight } from "actions/health-plans/action-listall-health-plans-light";
import { ScheduleBlockedStatusEnum } from "domain/schedule/enums/schedule-blocked-status.enum";
import { CalendarCustomToolbar } from "./calendar-custom-toolbar";
import DatePicker from "react-datepicker";
import { startOfWeek, endOfWeek, addMonths } from "date-fns";

const locales = {
  "pt-BR": ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface IntervalDate {
  start: Date;
  end: Date;
  view: View;
}

export interface ScheduleDataProps {
  id: number;
  title: string;
  doctor: {
    id: number;
    name: string;
  };
  patient: {
    id: number;
    name: string;
    phone: string;
  } | null;
  start: Date;
  end: Date;
  status: ScheduleStatusEnum;
  type: ScheduleTypeEnum;
  origin_contact: ScheduleOriginContactEnum | null;
  observation: string;
  amount: string;
  payment_type?: PaymentTypeEnum;
  amount_paid?: string;
  paid_at?: string;
  payment_observation?: string;
  health_plan: HealthPlanItem | null;
  schedule_type: ScheduleTypeItem | null;
  is_schedule_blocked: ScheduleBlockedStatusEnum;
}

export interface ScheduleCalendarMakeAppointmentPaymentProps {
  amount_paid: number;
  payment_type: PaymentTypeEnum;
  paid_at: string;
  payment_observation?: string;
  health_plan: HealthPlanItem | null;
}

const eventStyleGetter = (
  event: any,
  _start: any,
  _end: any,
  _isSelected: boolean
) => {
  //@FIXME - When add color in schedule type, change here
  // var color = ScheduleTypeColors[event.type as ScheduleTypeEnum]["hex"];
  // var backgroundColor =
  //   ScheduleTypeBackgroundColors[event.type as ScheduleTypeEnum]["hex"];

  var color, backgroundColor;

  if (event.is_schedule_blocked === ScheduleBlockedStatusEnum.BLOCKED) {
    color = ScheduleTypeColors[SCHEDULE_STATUS_BLOCKED]["hex"];
    backgroundColor =
      ScheduleTypeBackgroundColors[SCHEDULE_STATUS_BLOCKED]["hex"];
  } else {
    color = ScheduleTypeColors[ScheduleTypeEnum.APPOINTMENT]["hex"];
    backgroundColor =
      ScheduleTypeBackgroundColors[ScheduleTypeEnum.APPOINTMENT]["hex"];
  }
  var style = {
    color: color,
    border: `1px solid ${color}`,
    backgroundColor: backgroundColor,
    borderRadius: "7px",
    fontSize: "13px",
    fontWeight: 500,
  };
  return {
    style: style,
  };
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

interface ScheduleCalendarTabProps {
  selectedDate?: Date;
  doctorId?: number;
  onSubmitCreateAppointment?: (input: any) => void;
}
const PAGE_SIZE = 150;

const ScheduleCalendarTab: React.FC<ScheduleCalendarTabProps> = ({
  selectedDate,
  doctorId,
  onSubmitCreateAppointment,
}) => {
  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEventSelected, setModalEventSelected] = useState(false);
  const [slots, setSlots] = useState<ScheduleDataProps[]>([]);
  const [eventSelected, setEventSelected] = useState<ScheduleDataProps>();
  const [appointmentData, setAppointmentData] = useState<MakeScheduleDataProps>(
    {} as {} as MakeScheduleDataProps
  );
  const [selectedDoctor, setSelectedDoctor] =
    useState<SelectDoctorStateProps | null>();

  const defaultView = UserIdentity.hasRole([
    UserRoleEnum.OWNER_DOCTOR,
    UserRoleEnum.ADMIN_DOCTOR,
    UserRoleEnum.DOCTOR,
  ])
    ? Views.AGENDA
    : Views.WEEK;

  const [intervalDate, setIntervalDate] = useState<IntervalDate>();

  const [isSubmitSearchPatient, setIsSubmitSearchPatient] = useState(false);
  const [inputSearchPatient, setInputSearchPatient] = useState("");

  const toggleCreate = () => setModalCreate(!modalCreate);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const toggleEventSelected = () => setModalEventSelected(!modalEventSelected);

  const getAppointments = async (
    start: string,
    end: string,
    doctor_id?: string,
    patient_name?: string
  ) => {
    try {
      const response = await actionListAllSchedule({
        start,
        end,
        limit: PAGE_SIZE,
        page: 1,
        ...(doctor_id && { doctor_id }),
        ...(patient_name && { patient_name }),
      });

      const mapped = response.items.map((item) => ({
        ...item,
        start: new Date(utcToSaoPaulo(item.start)),
        end: new Date(utcToSaoPaulo(item.end)),
        title: `${ScheduleTypeLabels(item.type)} - ${item.patient?.name}`,
        amount: labelMaskFormatToReal(item.amount ? String(item.amount) : "0"),
        amount_paid: labelMaskFormatToReal(
          item.amount_paid ? String(item.amount_paid) : "0"
        ),
        payment_type: item.payment_type,
        payment_observation: item.payment_observation ?? "",
        // health_plan: item.health_plan,
        schedule_type: item.schedule_type,
      }));

      setSlots(mapped);
    } catch {}
  };

  const isDoctor = (): Boolean => UserIdentity.hasRole([UserRoleEnum.DOCTOR]);

  const calculateIntervalDate = (selectedDate: Date, view: View) => {
    let start = new Date();
    let end = new Date();

    console.log(selectedDate, view);
    if (view === Views.WEEK) {
      start = startOfWeek(new Date(selectedDate), {
        weekStartsOn: 0,
      });
      end = endOfWeek(new Date(selectedDate), {
        weekStartsOn: 0,
      });
    } else if (view === Views.DAY) {
      start = new Date(selectedDate);
      end = new Date(selectedDate);
    } else if (view === Views.AGENDA) {
      start = new Date(selectedDate);
      end = addMonths(start, 1);
    }

    setIntervalDate(
      (prev) =>
        ({
          view,
          start,
          end,
        } as IntervalDate)
    );
  };

  useEffect(() => {
    (async () => {
      if (!intervalDate && !selectedDoctor) return;
      await getAppointments(
        dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
        dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
        selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
        isSubmitSearchPatient ? inputSearchPatient : undefined
      );
    })();
  }, [intervalDate, selectedDoctor, isSubmitSearchPatient]);

  const fetchHealthPlansData = async () => {
    await actionListAllHealthPlanLight();
  };

  useEffect(() => {
    (async () => {
      let initialDate = selectedDate ?? new Date();

      await calculateIntervalDate(initialDate, defaultView);
      await fetchHealthPlansData();
    })();
  }, []);

  const getDateWeeks = useMemo(() => {
    if (!intervalDate) return "";

    switch (intervalDate.view) {
      case Views.WEEK:
      case Views.AGENDA:
        return `${dateFormatter(
          intervalDate.start,
          "dd/MM/yyyy"
        )} a ${dateFormatter(intervalDate.end, "dd/MM/yyyy")}`;
      default:
        return `${dateFormatter(intervalDate.start, "dd/MM/yyyy")}`;
    }
  }, [intervalDate]);

  const onCreateEvent = (slot: SlotInfo) => {
    toggleCreate();
    setAppointmentData({
      type: null,
      start: slot.start,
      end: slot.end,
      observation: "",
      origin_contact: null,
      doctor: {
        id: undefined,
        name: "Selecione um profissional",
      },
      patient: {
        id: undefined,
        name: "Digite nome do paciente",
        phone: "",
      },
      amount: "R$ 0,00",
      schedule_type: null,
    });
  };

  const onSelectEvent = (event: any) => {
    const isScheduleBlocked = () =>
      event.is_schedule_blocked === ScheduleBlockedStatusEnum.BLOCKED &&
      event.patient === null;

    setAppointmentData({
      id: event.id,
      type: event.type,
      start: event.start,
      end: event.end,
      origin_contact: event.origin_contact,
      observation: event.observation,
      doctor: event.doctor,
      patient: !isScheduleBlocked()
        ? event.patient
        : {
            id: undefined,
            name: "Digite nome do paciente",
            phone: "",
          },
      amount: event.amount,
      schedule_type: event.schedule_type,
      is_schedule_blocked: event.is_schedule_blocked,
    });
    setModalEventSelected(true);
    setEventSelected(event);
  };

  const onRangeChange = (range: any) => {
    let start, end;
    if (range?.start && range?.end) {
      start = new Date(range.start);
      end = new Date(range.end);
    } else if (range?.length) {
      start = new Date(range[0]);
      end = new Date(range[range.length - 1]);
    }

    if (start && end) {
      setIntervalDate(
        (prev) =>
          ({
            ...prev,
            start,
            end,
          } as IntervalDate)
      );
    }
  };

  const onCreateEventSubmit = async (input: MakeScheduleProps) => {
    await actionMakeSchedule(input);
    onNextSubmit();
  };

  const onEditEventSubmit = async (input: MakeScheduleProps) => {
    if (appointmentData.id) {
      await actionUpdateSchedule(appointmentData.id.toString(), input);
      onNextSubmit();
      return;
    }
    errorMessage("Agendamento não encontrado");
  };

  const onUpdateStatus = async (status: ScheduleStatusEnum) => {
    setEventSelected((prev) => ({ ...prev, status } as ScheduleDataProps));

    if (!intervalDate) return;
    await getAppointments(
      dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
      dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
      selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
      isSubmitSearchPatient ? inputSearchPatient : undefined
    );
  };

  const onMakePayment = async (
    input: ScheduleCalendarMakeAppointmentPaymentProps
  ) => {
    const {
      amount_paid,
      payment_type,
      paid_at,
      payment_observation,
      health_plan,
    } = input;
    setEventSelected(
      (prev) =>
        ({
          ...prev,
          amount_paid: labelMaskFormatToReal(String(amount_paid)),
          payment_type,
          paid_at: new Date(`${paid_at}T03:00:00Z`).toISOString(), //Becausae timezone GMT(-3)
          payment_observation,
          health_plan,
        } as ScheduleDataProps)
    );
    if (!intervalDate) return;
    await getAppointments(
      dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
      dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
      selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
      isSubmitSearchPatient ? inputSearchPatient : undefined
    );
  };
  const onNextSubmit = () => {
    setModalCreate(false);
    setModalEventSelected(false);
    setModalEdit(false);
    getAppointments(
      dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
      dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
      selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
      isSubmitSearchPatient ? inputSearchPatient : undefined
    );
  };

  const onSearchPatient = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitSearchPatient(true);
  };

  const onClearInput = () => {
    if (!inputSearchPatient || !isSubmitSearchPatient) return;
    setIsSubmitSearchPatient(false);
    setInputSearchPatient("");
  };

  return (
    <div>
      <div className="col-12 d-flex flex-wrap mb-2 mb-md-0 p-0">
        {!isDoctor() && (
          <div className="col-12 col-xl-3 p-0">
            <SelectDoctorState
              selectedDoctor={selectedDoctor}
              setSelectedDoctor={setSelectedDoctor}
              setDefaultDoctor={true}
              doctorId={doctorId}
            />
          </div>
        )}

        <div className="col-12 col-xl-5 px-0 px-xl-2 mb-1">
          <Form onSubmit={onSearchPatient}>
            <Label>Deseja buscar um paciente ?</Label>
            <CustomInputSearchClearable
              placeholder="Buscar paciente por nome"
              inputSearch={inputSearchPatient}
              setInputSearch={setInputSearchPatient}
              onClearInput={onClearInput}
            />
          </Form>
        </div>

        <div className="col-12 col-xl-4 p-0 mb-1">
          <Label>Data da Agenda?</Label>
          <DatePicker
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            className="form-control"
            wrapperClassName="w-100"
            selected={intervalDate?.start ?? new Date()}
            value={getDateWeeks}
            onChange={(date) => {
              if (date) {
                calculateIntervalDate(date, intervalDate?.view ?? defaultView);
              }
            }}
          />
        </div>
      </div>

      <div className="col-12 d-flex flex-wrap mb-1">
        <div className="col-12 d-flex flex-wrap mb-1 justify-content-end">
          <div
            className="btn-calendar"
            onClick={() =>
              onCreateEvent({
                start: new Date(),
                end: new Date(),
              } as SlotInfo)
            }
          >
            <div className="btn-calendar-title">
              <i className="fa fa-plus" aria-hidden="true"></i> Novo agendamento
            </div>
          </div>
        </div>
      </div>
      <Calendar
        culture="pt-BR"
        localizer={localizer}
        events={slots}
        startAccessor="start"
        min={new Date(0, 0, 0, 7, 0, 0)}
        max={new Date(0, 0, 0, 22, 0, 0)}
        step={15}
        timeslots={1}
        endAccessor="end"
        views={[Views.WEEK, Views.DAY, Views.AGENDA]}
        popup={true}
        selectable={true}
        style={{ minHeight: "100vh" }}
        defaultView={defaultView}
        onRangeChange={onRangeChange}
        onView={(view: View) => {
          setIntervalDate((prev) => ({ ...prev, view } as IntervalDate));
        }}
        onNavigate={(newDate: Date, view: View, action: NavigateAction) => {
          // console.log('ONNAVIGATE, newDate, view, action', newDate, view, action);
        }}
        messages={{
          next: "Próximo",
          previous: "Anterior",
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          event: "Agendamento",
          time: "Horário",
          date: "Data",
          noEventsInRange: "Nenhum agendamento encontrado",
        }}
        date={intervalDate?.start ?? new Date()}
        defaultDate={intervalDate?.start ?? new Date()}
        onSelectSlot={onCreateEvent}
        onSelectEvent={onSelectEvent}
        eventPropGetter={eventStyleGetter}
        components={{
          event: CustomCalendarEvent,
          toolbar: (props) => <CalendarCustomToolbar {...props} />,
        }}
        formats={formats}
      />

      <Modal size="lg" isOpen={modalCreate} toggle={toggleCreate}>
        <ModalHeader toggle={toggleCreate}>Realizar agendamento</ModalHeader>
        <ModalBody>
          <MakeScheduleContainer
            onCancel={toggleCreate}
            onSubmit={onCreateEventSubmit}
            appointmentData={appointmentData}
            doctorId={selectedDoctor?.value}
            onSubmitCreateAppointment={onSubmitCreateAppointment}
          />
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar agendamento</ModalHeader>
        <ModalBody>
          <MakeScheduleContainer
            onCancel={toggleEdit}
            onSubmit={onEditEventSubmit}
            appointmentData={appointmentData}
          />
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEventSelected} toggle={toggleEventSelected}>
        <ModalHeader toggle={toggleEventSelected}>
          Detalhes do agendamento
        </ModalHeader>
        <ModalBody>
          {eventSelected && (
            <ListSchedule
              data={{ ...eventSelected }}
              onEdit={() => {
                setModalCreate(false);
                setModalEventSelected(false);
                setModalEdit(true);
              }}
              onCancel={() => {
                setModalCreate(false);
                setModalEventSelected(false);
                setModalEdit(false);
              }}
              updateStatus={onUpdateStatus}
              makePayment={onMakePayment}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ScheduleCalendarTab;
