import { dateFormatter } from "helpers/date-formatter";

interface MedicalRecordestimatedReturnDateItemProps {
  estimated_return_date: string | null;
}
export const MedicalRecordEstimatedReturnDateItem: React.FC<
  MedicalRecordestimatedReturnDateItemProps
> = ({ estimated_return_date }) => {
  return (
    <>
      <div className="d-flex row">
        <div className="medical-record-section-title fs-18">Previsão de retorno</div>
        <div className="medical-record-history-row">
          <div className="medical-record-history-title fs-15">
            {dateFormatter(estimated_return_date, "dd/MM/yyyy")}
          </div>
        </div>
      </div>
    </>
  );
};
