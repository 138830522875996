import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormGroup, Input, Row, Label, Col } from "reactstrap";

import { OwnerTypeEnum } from "domain/tenants/entities/enums/owner-type.enum";
import RegisterPF from "./register-pf";
import RegisterPJ from "./register-pj";
import { actionRegisterTenant } from "actions/identity/action-register-tenant";
import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";

export interface RegisterFormValues {
  tenant_name: string;
  user_name: string;
  document_number: string;
  phone: string;
  email: string;
  profession?: ProfessionTypeEnum;
  password: string;
  owner_type: OwnerTypeEnum;
}

const Register: React.FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const [ownerType, setOwnerType] = useState(OwnerTypeEnum.DOCTOR);

  const isClinic = () => ownerType == OwnerTypeEnum.CLINIC;

  const onSubmit = async (values: RegisterFormValues): Promise<void> => {
    if (!executeRecaptcha) {
      throw new Error("Falha ao obter token do Recaptcha");
    }
    const token = await executeRecaptcha("login");

    const result = await actionRegisterTenant({
      ...values,
      token,
      document_number: values.document_number.replace(/\D/g, ""),
    });
    if (result) {
      navigate("/auth/login");
    }
  };

  return (
    <>
      <Row className="d-flex px-0 col-12 col-md-8 d-flex align-items-center row flex-column">
        <img
          className="mx-auto my-4 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <h3 className="text-center text-primary">Cadastro</h3>
          <FormGroup tag="fieldset">
            <legend>Escolha o tipo de conta</legend>
            <FormGroup check>
              <Input
                name="radio1"
                type="radio"
                id="doctor"
                value={OwnerTypeEnum.DOCTOR}
                checked={!isClinic()}
                onChange={(e) =>
                  setOwnerType(e.target.value as unknown as OwnerTypeEnum)
                }
              />
              <Label for="doctor">Sou médico administrador</Label>
            </FormGroup>
            <FormGroup check>
              <Input
                name="radio1"
                type="radio"
                id="manager"
                value={OwnerTypeEnum.CLINIC}
                checked={isClinic()}
                onChange={(e) =>
                  setOwnerType(e.target.value as unknown as OwnerTypeEnum)
                }
              />
              <Label for="manager">Sou gestor da clínica</Label>
            </FormGroup>
          </FormGroup>
          {!isClinic() && <RegisterPF onSubmit={onSubmit} />}
          {isClinic() && <RegisterPJ onSubmit={onSubmit} />}
        </Col>
      </Row>
    </>
  );
};

export default Register;
