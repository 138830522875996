import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { enumIterator } from "helpers/typescript/enum-iterator";

import {
  HealthPlanStatusEnum,
  HealthPlanStatusEnumLabels,
} from "domain/health-plans/entities/enums/health-plan-status.enum";
// import { customStyles } from "../../../../../../components/form/custom-form.style.css";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectHealthPlanStatusFormProps {
  keyName: string;
  isReadOnly?: boolean;
}

export const SelectHealthPlanStatusForm: React.FC<
  SelectHealthPlanStatusFormProps
> = ({ keyName, isReadOnly }: SelectHealthPlanStatusFormProps) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue(keyName, undefined);
    setFieldValue(keyName, selectedOption);
  };

  const optionsUserStatus = enumIterator(HealthPlanStatusEnum).map((key) => ({
    value: HealthPlanStatusEnum[key],
    label: HealthPlanStatusEnumLabels(
      HealthPlanStatusEnum[key] as unknown as HealthPlanStatusEnum
    ),
  }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor={keyName}>Status</Label>
      <Field
        name={keyName}
        id={keyName}
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.[keyName]}
        placeholder="Status"
        options={optionsUserStatus}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
        // styles={customStyles}
      />
      {(errors as any)?.[keyName] && (
        <div className="custom-invalid-feedback">Status inválido</div>
      )}
    </FormGroup>
  );
};
