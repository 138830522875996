import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { CustomPasswordInput } from "components/form/input/custom-password-input";
import { isPasswordValid } from "helpers/password-validator";

interface RecoverPasswordFormValues {
  new_password: string;
  confirm_password: string;
}

export interface OnResetPasswordProps {
  new_password: string;
  confirm_password: string;
}

interface RecoverPasswordFormProps {
  onResetPassword: (input: OnResetPasswordProps) => void;
}

const RecoverPasswordForm: React.FC<RecoverPasswordFormProps> = ({
  onResetPassword,
}: RecoverPasswordFormProps) => {
  const initialValues: RecoverPasswordFormValues = {
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object().shape({
    new_password: Yup.string().test(
      "custom-validation",
      "Senha fraca",
      function (value) {
        return isPasswordValid(value ?? "");
      }
    ),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("new_password"), undefined],
        "As senhas precisam ser iguais"
      )
      .min(8, "Senha deve ter no mínimo 8 caracteres")
      .required("Confirmação de senha é obrigatória"),
  });

  const onSubmit = async (input: RecoverPasswordFormValues): Promise<void> => {
    await onResetPassword(input);
  };

  return (
    <>
      <Row className="d-flex col-12 col-xl-7 d-flex align-items-center row flex-column">
        <img
          className="mx-auto mb-5 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          md="8"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <h3 className="text-center text-primary">Definir nova senha</h3>
          <p className="px-0">Informe uma nova senha com os critérios abaixo</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form>
                <Row className="mt-2">
                  <FormGroup className="col-12">
                    <Label htmlFor="password">Nova Senha</Label>
                    <Field
                      name="new_password"
                      id="new_password"
                      label="Senha"
                      placeholder="Digite nova senha"
                      leftIconClass="fas fa-lock text-primary"
                      component={CustomPasswordInput}
                    />
                  </FormGroup>
                </Row>
                <Row className="col-12 mx-3">
                  <ul className="fs-14">
                    <li>No mínimo 8 caracteres</li>
                    <li>Pelo menos 1 caractere maiúsculo</li>
                    <li>Pelo menos 1 caractere minúsculo</li>
                    <li>Pelo menos 1 número</li>
                  </ul>
                </Row>
                <Row>
                  <FormGroup className="col-12">
                    <Label htmlFor="confirm_password">Confirmar Senha</Label>
                    <Field
                      name="confirm_password"
                      id="confirm_password"
                      label="Confirmar Senha"
                      placeholder="Confirmar senha"
                      leftIconClass="fas fa-lock text-primary"
                      component={CustomPasswordInput}
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <div className="col-12">
                    <Button
                      type="submit"
                      color="primary"
                      className="form-control"
                    >
                      Salvar
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
          <Row className="text-center  mt-4 px-1">
            <Col sm="12" className="d-flex justify-content-end">
              <a href="/auth/login" className="text-primary fs-16">
                <i className="fas fa-user-doctor"></i> Lembrou a senha ? Faça
                login
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RecoverPasswordForm;
