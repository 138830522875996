import { action } from "mobx";
import { IdentityService, LoginProps } from "services/identity-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { TokenTypeEnum } from "domain/auth/entities/enums/token-type.enum";

export const actionLoginUser = action(
  async (input: LoginProps): Promise<TokenTypeEnum | void> => {
    SetIsLoadingState(true);
    const result = await IdentityService.login(input);
    SetIsLoadingState(false);

    return result;
  }
);
