import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import { CreatePatientToRequestMapper } from "./mappers/create-patient-to-request-mapper";
import { actionCreatePatient } from "actions/patients/action-create-patient";
import { CustomTab } from "components/tab/custom-tab";
import {
  FormPatientData,
  PatientDataFormValues,
} from "views/painel/patients/common/form/form-patient-data";
import {
  FormPatientAddress,
  PatientAddressFormValues,
} from "views/painel/patients/common/form/form-patient-address";
import { useNavigate } from "react-router-dom";
import { UserSexEnum } from "domain/user/entities/enums/user-sex.enum";
import {
  FormPatientAdditionalData,
  PatientAdditionalDataFormValues,
} from "../common/form/form-patient-additional-data";
import { actionListAllHealthPlanLight } from "actions/health-plans/action-listall-health-plans-light";

const CreatePatient: React.FC = observer(() => {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState("0");

  const [patientData, setPatientData] = useState<PatientDataFormValues>({
    name: "",
    document_number: "",
    email: "",
    phone: "",
    birthdate: "",
    sex: UserSexEnum.NEUTRAL,
  });

  const [patientAddress, setPatientAddress] =
    useState<PatientAddressFormValues>({
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: null,
      zip_code: "",
    });

  const [patientAdditionalData, setPatientAdditionalData] =
    useState<PatientAdditionalDataFormValues>({
      profession: "",
      father_name: "",
      mother_name: "",
      spouse: "",
      patient_insurance_number: "",
      health_plan: null,
      observation: "",
    });

  const onSubmitData = (values: PatientDataFormValues) => {
    setPatientData(values);
    setTabs(tabs.map((tab) => ({ ...tab, disabled: false })));
    setActiveTab("1");
  };

  const onSubmitAddress = async (values: PatientAddressFormValues) => {
    setPatientAddress(values);
    setActiveTab("2");
  };

  const onSubmitAdditionalData = (values: PatientAdditionalDataFormValues) => {
    setPatientAdditionalData(values);
    setIsSubmitted(true);
  };
  //Submit form
  useEffect(() => {
    (async () => {
      if (isSubmitted) {
        const onSubmit = async () => {
          try {
            const patient = {
              ...patientData,
              address: {
                ...patientAddress,
                state: patientAddress.state?.value ?? "",
              },
              ...patientAdditionalData,
              health_plan: patientAdditionalData?.health_plan
                ? {
                    id: Number(patientAdditionalData.health_plan.value),
                    name: "",
                  }
                : null,
            };

            const result = await actionCreatePatient(
              CreatePatientToRequestMapper.map(patient)
            );
            setIsSubmitted(false);
            navigate(`/painel/paciente/${result.id}`);
          } catch {
          } finally {
            setIsSubmitted(false);
          }
        };

        await onSubmit();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, patientData, patientAddress]);

  useEffect(() => {
    (async () => {
      await actionListAllHealthPlanLight();
    })();
  }, []);
  
  const [tabs, setTabs] = useState([
    {
      label: "Dados pessoais",
      disabled: false,
      element: (
        <div className="mt-4">
          <FormPatientData
            patientData={patientData}
            onSubmit={onSubmitData}
            onBack={() => navigate("/painel/pacientes")}
          />
        </div>
      ),
    },
    {
      label: "Dados de endereço",
      disabled: true,
      element: (
        <div className="mt-4">
          <FormPatientAddress
            patientAddress={patientAddress}
            onSubmit={onSubmitAddress}
            onBack={() => setActiveTab("0")}
          />
        </div>
      ),
    },
    {
      label: "Dados complementares",
      disabled: true,
      element: (
        <div className="mt-4">
          <FormPatientAdditionalData
            data={patientAdditionalData}
            onSubmit={onSubmitAdditionalData}
            onBack={() => setActiveTab("0")}
          />
        </div>
      ),
    },
  ]);

  return (
    <>
      <div className="main-wrapper">
        <CustomTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
});
export default CreatePatient;
