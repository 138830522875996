export enum ProfessionTypeEnum {
  DOCTOR = 0,
  DENTIST = 1,
  PHYSIOTHERAPIST = 2,
  PHARMACIST = 3,
  NURSE = 4,
  BIOMEDIC = 5,
  NUTRITIONIST = 6,
  VETERINARIAN = 7,
  PSYCHOLOGIST = 8,
}

export const ProfessionTypeEnumLabels = (
  profession: ProfessionTypeEnum
): string => {
  const labels = {
    [ProfessionTypeEnum.DOCTOR]: "Médico",
    [ProfessionTypeEnum.DENTIST]: "Dentista",
    [ProfessionTypeEnum.PHYSIOTHERAPIST]: "Fisioterapeuta",
    [ProfessionTypeEnum.PHARMACIST]: "Farmacêutico",
    [ProfessionTypeEnum.NURSE]: "Enfermeiro",
    [ProfessionTypeEnum.BIOMEDIC]: "Biomédico",
    [ProfessionTypeEnum.NUTRITIONIST]: "Nutricionista",
    [ProfessionTypeEnum.VETERINARIAN]: "Veterinário",
    [ProfessionTypeEnum.PSYCHOLOGIST]: "Psicólogo",
  };

  return labels[profession];
};

export const ProfessionTypeRegulatoryBodyLabels = (
  profession: ProfessionTypeEnum
): string => {
  const labels = {
    [ProfessionTypeEnum.DOCTOR]: "CRM",
    [ProfessionTypeEnum.DENTIST]: "CRO",
    [ProfessionTypeEnum.PHYSIOTHERAPIST]: "CREFITO",
    [ProfessionTypeEnum.PHARMACIST]: "CRF",
    [ProfessionTypeEnum.NURSE]: "COREN",
    [ProfessionTypeEnum.BIOMEDIC]: "CRBM",
    [ProfessionTypeEnum.NUTRITIONIST]: "CRN",
    [ProfessionTypeEnum.VETERINARIAN]: "CRMV",
    [ProfessionTypeEnum.PSYCHOLOGIST]: "CRP",
  };

  return labels[profession];
};
