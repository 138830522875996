import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { inputMaskFormatToReal, maskedToCurrency } from "helpers/format-money";
import { CustomInputMoney } from "components/form/input/custom-input-money";
import { SelectPaymentTypeForm } from "components/form/input/select-payment-type-form";
import {
  PaymentTypeEnum,
  PaymentTypeLabels,
} from "domain/schedule/enums/payment-type.enum";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { dateFormatter } from "helpers/date-formatter";
import {
  ScheduleCalendarMakeAppointmentPaymentProps,
} from "../index/tabs/calendar/schedule-calendar-tab";
import { HealthPlanItem, ScheduleTypeItem } from "services/schedule-service";

export interface DataModalMakeAppointmentPaymentProps {
  amount: string;
  payment_type?: PaymentTypeEnum;
  amount_paid?: string;
  paid_at?: string;
  payment_observation?: string;
  health_plan: HealthPlanItem | null;
  type: ScheduleTypeEnum | null;
  schedule_type: ScheduleTypeItem | null;
}

export interface ModalMakeAppointmentPaymentProps {
  data: DataModalMakeAppointmentPaymentProps;
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (input: ScheduleCalendarMakeAppointmentPaymentProps) => void;
}

export const ModalMakeAppointmentPayment: React.FC<
  ModalMakeAppointmentPaymentProps
> = ({ data, isOpen, onSubmit, toggle }: ModalMakeAppointmentPaymentProps) => {
  const {  amount } = data;

  const onHandleSubmit = async (values: any) => {
    await onSubmit({
      payment_type: values.payment_type.value,
      amount_paid: Number(maskedToCurrency(values.amount_paid)),
      paid_at: values.paid_at,
      payment_observation: values.payment_observation,
      health_plan: values.health_plan
        ? { id: values.health_plan.value, name: values.health_plan.label }
        : null,
    });
  };

  const validationSchema = Yup.object().shape({
    payment_type: Yup.object().test(
      "validation-payment-type",
      "Método de pagamento é obrigatório",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    // amount_paid: Yup.string().test(
    //   "validation-amount-paid",
    //   "Valor do pagamento inválido",
    //   (value: any) => {
    //     return value && Number(maskedToCurrency(value)) > 0;
    //   }
    // ),
    // paid_at: Yup.string().test(
    //   "validation-amount-paid",
    //   "Valor do pagamento inválido",
    //   (value: any) => {
    //     return value;
    //   }
    // ),
  });
  const initialValues: any = {
    payment_type: data.payment_type
      ? {
          value: data.payment_type,
          label: PaymentTypeLabels(data.payment_type),
        }
      : null,
    amount_paid: data.amount_paid,
    paid_at: data?.paid_at
      ? dateFormatter(new Date(data?.paid_at), "yyyy-MM-dd")
      : dateFormatter(new Date(), "yyyy-MM-dd"),
    payment_observation: data.payment_observation,
    health_plan: data.health_plan
      ? {
          value: data.health_plan.id,
          label: data.health_plan.name,
        }
      : null,
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Lançar pagamento</ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        {() => (
          <Form className="px-0">
            <ModalBody>
              <Row className="my-2">
                <Col sm="12" md="3" className="form-label  font-weight-500">
                  Atendimento
                </Col>
                <Col sm="12" md="9">
                  {data.type !== null
                    ? ScheduleTypeLabels(data.type)
                    : data.schedule_type?.name ?? "N/A"}
                  {/* <strong>{ScheduleTypeLabels(type)}</strong> */}
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm="12" md="3" className="form-label  font-weight-500">
                  Valor
                </Col>
                <Col sm="12" md="9">
                  <strong>{inputMaskFormatToReal(amount)}</strong>
                </Col>
              </Row>

              <SelectPaymentTypeForm />

              <FormGroup className="col-12 px-0 ">
                <Label htmlFor="amount_paid">Valor pago</Label>
                <Field
                  name="amount_paid"
                  id="amount_paid"
                  placeholder="R$ 0,00"
                  type="text"
                  component={CustomInputMoney}
                />
              </FormGroup>

              <FormGroup className="col-12 px-0">
                <Label htmlFor="paid_at">Data do pagamento</Label>
                <Field
                  name="paid_at"
                  id="paid_at"
                  placeholder="Data"
                  type="date"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 px-0">
                <Label htmlFor="payment_observation">
                  Descrição do pagamento
                </Label>
                <Field
                  name="payment_observation"
                  id="payment_observation"
                  placeholder="Descrição do pagamento"
                  type="textarea"
                  style={{ height: "4rem" }}
                  component={CustomFormInput}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancelar
              </Button>
              <Button color="primary" type="submit">
                Lançar pagamento
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
