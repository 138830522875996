export enum ScheduleTypeStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0,
}

export const ScheduleTypeStatusEnumLabels = (status: ScheduleTypeStatusEnum) => {
  const labels = {
    [ScheduleTypeStatusEnum.ACTIVE]: 'Ativo',
    [ScheduleTypeStatusEnum.INACTIVE]: 'Inativo',
  };

  return labels[status];
};
