import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  CreateScheduleTypeProps,
  ScheduleTypeService,
} from "services/schedule-type-service";
import listAllScheduleTypesStore from "state/schedule-types/list-all-schedule-types-store";

export const actionCreateScheduleType = action(
  async (input: CreateScheduleTypeProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      const result = await ScheduleTypeService.createScheduleType(input);

      listAllScheduleTypesStore.cleanData();
      return result;
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
