import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";
import { ScheduleTypeStatusEnum } from "domain/schedule-types/entities/enums/schedule-type-status.enum";

export interface CreateScheduleTypeProps {
  name: string;
  status: ScheduleTypeStatusEnum
}

export interface UpdateScheduleTypeProps extends CreateScheduleTypeProps {}

export interface ListAllScheduleTypesProps {
  fields?: string[];
  status?: ScheduleTypeStatusEnum;
}

export interface ListAllScheduleTypesResponse {
  id: number;
  name: string;
  status?: ScheduleTypeStatusEnum;
}

export class ScheduleTypeService {
  static async createScheduleType(
    input: CreateScheduleTypeProps
  ): Promise<void> {
    try {
      const result = await httpClient.request({
        url: "/schedule-types",
        method: "post",
        data: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao criar tipo de agendamento");
      throw new Error("Falha ao criar tipo de agendamento");
    }
  }

  static async listAllScheduleTypes(
    input: ListAllScheduleTypesProps
  ): Promise<ListAllScheduleTypesResponse[]> {
    try {
      const result = await httpClient.request({
        url: "/schedule-types",
        method: "get",
        params: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao listar tipos de agendamento");
      throw new Error("Falha ao listar tipos de agendamento");
    }
  }

  static async updateScheduleType(
    id: number,
    input: UpdateScheduleTypeProps
  ): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/schedule-types/${id}`,
        method: "patch",
        data: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao atualizar tipo de agendamento");
      throw new Error("Falha ao atualizar tipo de agendamento");
    }
  }

  static async deleteScheduleType(id: number): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/schedule-types/${id}`,
        method: "delete",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao deletar tipo de agendamento");
      throw new Error("Falha ao deletar tipo de agendamento");
    }
  }
}
