import { jwtDecode } from "jwt-decode";

import { TokenTypeEnum } from "domain/auth/entities/enums/token-type.enum";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import { getLocalAccessToken } from "config/http-client";

interface UserIdentityData {
  tenant_id: number | null;
  tenant_name: string;
  token_type: TokenTypeEnum | null;
  user_id: number | null;
  user_role_id: number | null;
  user_name: string;
  user_role: UserRoleEnum | null;
}

class UserIdentity {
  static getRole(): UserRoleEnum | null {
    return this.getData().user_role;
  }

  static hasRole(roles: UserRoleEnum[]): boolean {
    return (
      this.getData().user_role !== null &&
      roles.includes(this.getData().user_role as UserRoleEnum)
    );
  }

  static isDoctor(): boolean {
    return this.hasRole([UserRoleEnum.DOCTOR]);
  }

  static isAdminDoctor(): boolean {
    return this.hasRole([UserRoleEnum.ADMIN_DOCTOR, UserRoleEnum.OWNER_DOCTOR]);
  }
  
  static canAcessPage(route: string): boolean {
    const adminRole = UserRoleEnum.ADMIN;
    const superAdminRole = UserRoleEnum.SUPERADMIN;
    const clinicManagerRole = UserRoleEnum.CLINIC_MANAGER;
    const doctorRole = UserRoleEnum.DOCTOR;
    const ownerDoctorRole = UserRoleEnum.OWNER_DOCTOR;
    const receptionistRole = UserRoleEnum.RECEPTIONIST;
    const adminDoctor = UserRoleEnum.ADMIN_DOCTOR;

    // Create the Map using variables
    const pagesMap = new Map([
      [
        "/painel/dashboard",
        [
          adminRole,
          superAdminRole,
          clinicManagerRole,
          doctorRole,
          ownerDoctorRole,
          adminDoctor,
        ],
      ],
      [
        "/painel/agenda",
        [
          adminRole,
          superAdminRole,
          clinicManagerRole,
          doctorRole,
          ownerDoctorRole,
          receptionistRole,
          adminDoctor,
        ],
      ],
      [
        "/painel/prescricoes",
        [
          // adminRole,
          // superAdminRole,
          // clinicManagerRole,
          doctorRole,
          ownerDoctorRole,
          adminDoctor,
        ],
      ],
      [
        "/painel/pacientes",
        [
          adminRole,
          superAdminRole,
          clinicManagerRole,
          doctorRole,
          ownerDoctorRole,
          receptionistRole,
          adminDoctor,
        ],
      ],
      [
        "/painel/colaboradores",
        [
          adminRole,
          superAdminRole,
          clinicManagerRole,
          doctorRole,
          ownerDoctorRole,
          adminDoctor,
        ],
      ],
      [
        "/painel/configuracoes",
        [
          adminRole,
          superAdminRole,
          clinicManagerRole,
          ownerDoctorRole,
          adminDoctor,
        ],
      ],
      [
        "/painel/meus-dados",
        [
          adminRole,
          superAdminRole,
          clinicManagerRole,
          doctorRole,
          ownerDoctorRole,
          receptionistRole,
          adminDoctor,
        ],
      ],
      [
        "/painel/compromissos",
        [
          adminRole,
          superAdminRole,
          clinicManagerRole,
          doctorRole,
          ownerDoctorRole,
          receptionistRole,
          adminDoctor,
        ],
      ],
    ]);

    const page = pagesMap.get(route);
    if (!page) return false;

    return page.includes(this.getData().user_role as UserRoleEnum);
  }

  static getData(): UserIdentityData {
    const accessToken = getLocalAccessToken();

    if (!accessToken)
      return {
        tenant_id: null,
        tenant_name: "",
        token_type: null,
        user_id: null,
        user_role_id: null,
        user_name: "",
        user_role: null,
      };

    const decoded: any = jwtDecode(accessToken);

    return {
      tenant_id: decoded?.payload?.tenant_id ?? null,
      tenant_name: decoded?.payload?.tenant_name ?? "",
      token_type: decoded?.payload?.token_type ?? null,
      user_id: decoded?.payload?.user_id ?? null,
      user_role_id: decoded?.payload?.user_role_id ?? null,
      user_name: decoded?.payload?.user_name ?? "",
      user_role: decoded?.payload?.user_role ?? null,
    };
  }

  static getName(): string {
    return this.getData().user_name;
  }
  // static getHomePath() {
  //   const roles = EmployeeAuthorization.getRoles();
  //   return roles.includes(RoleEnum.ADMIN) ? "/admin/index" : "/admin/pedidos";
  // }
}

export default UserIdentity;
