import { makeAutoObservable } from "mobx";
import { UserSexEnum } from "domain/user/entities/enums/user-sex.enum";
import { SelectableFormValue } from "components/form/input/interfaces/selectable-form-value";

export class CreatePatientStore {
  patientData = {
    name: "",
    phone: "",
    birthdate: "",
    email: "",
    sex: UserSexEnum.NEUTRAL,
    document_number: "",
  };

  patientAddress: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: SelectableFormValue | null;
    zip_code: string;
  } = {
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: null,
    zip_code: "",
  };

  patientAdditionalData: {
    profession: string;
    father_name: string;
    mother_name: string;
    spouse: string;
    patient_insurance_number: string;
    health_plan: SelectableFormValue | null;
    observation: string;
  } = {
    profession: "",
    father_name: "",
    mother_name: "",
    spouse: "",
    patient_insurance_number: "",
    health_plan: null,
    observation: "",
  };

  constructor() {
    makeAutoObservable(this);
  }
}
