import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  HealthPlanService,
  ListAllHealthPlansResponse,
} from "services/health-plan-service";
import listAllHealthPlansStore from "state/health-plans/list-all-health-plans-store";
import { HealthPlanStatusEnum } from "domain/health-plans/entities/enums/health-plan-status.enum";

export const actionListAllHealthPlanLight = action(
  async (): Promise<ListAllHealthPlansResponse[]> => {
    if (!listAllHealthPlansStore.healthPlansLightIsEmpty()) {
      return listAllHealthPlansStore.getHealthPlansLight();
    }
    SetIsLoadingState(true);

    try {
      const result = await HealthPlanService.listAllHealthPlans({
        status: HealthPlanStatusEnum.ACTIVE,
        fields: ["id", "name"],
      });

      listAllHealthPlansStore.setHealthPlansLight(result);
      return result;
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
