import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import listAllScheduleTypesStore from "state/schedule-types/list-all-schedule-types-store";
import {
  ListAllScheduleTypesResponse,
  ScheduleTypeService,
} from "services/schedule-type-service";
import { ScheduleTypeStatusEnum } from "domain/schedule-types/entities/enums/schedule-type-status.enum";

export const actionListAllScheduleTypeLight = action(
  async (): Promise<ListAllScheduleTypesResponse[]> => {
    if (!listAllScheduleTypesStore.healthPlansLightIsEmpty()) {
      return listAllScheduleTypesStore.getScheduleTypesLight();
    }
    SetIsLoadingState(true);

    try {
      const result = await ScheduleTypeService.listAllScheduleTypes({
        status: ScheduleTypeStatusEnum.ACTIVE,
        fields: ["id", "name"],
      });

      listAllScheduleTypesStore.setScheduleTypesLight(result);
      return result;
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
