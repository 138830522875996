import { CustomTable, PaginationMeta } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";

export interface ListScheduleTypeItemTable {
  id: number;
  name: any;
  status: any;
}

export interface ListScheduleTypeTableProps {
  data: ListScheduleTypeItemTable[];
  meta?: PaginationMeta | null;
  onSelectData: (id: string) => void;
  onChangePage?: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const ListScheduleTypeTable = ({
  data,
  meta,
  onSelectData,
  onChangePage,
  tableType,
  setTableType,
}: ListScheduleTypeTableProps) => {
  const columnNames = {
    name: "Nome do tipo de agendamento",
    status: "Status",
  };

  return (
    <>
      <CustomTable
        columnNames={columnNames}
        data={data}
        onSelect={onSelectData}
        tableType={tableType}
        setTableType={setTableType}
        btnLabelNotFound=""
        btnClickNotFound={() => {}}
      />
      {meta && onChangePage && (
        <CustomPagination
          {...Object.assign({}, meta)}
          onChangePage={onChangePage}
        />
      )}
    </>
  );
};
