import { action } from "mobx";

import { CreateAdminProps, UserService } from "services/user-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionCreateAdmin = action(
  async (input: CreateAdminProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await UserService.createAdmin(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
