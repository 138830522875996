import { Field, useFormikContext } from "formik";
import { Label } from "reactstrap";

export const CheckboxTermForm = () => {
  const { errors } = useFormikContext();
  return (
    <>
      <Field type="checkbox" id="term" name="term" className="me-1" />
      <Label htmlFor="term" className="font-weight-300 my-auto fs-14">
        <span>
          Eu li e concordo com os{" "}
          <a
            href="/auth/termos-uso"
            target="_blank"
            className="fs-14 text-primary"
          >
            <b>Termos </b>
          </a>{" "}
          &{" "}
          <a
            href="/auth/politica-privacidade"
            target="_blank"
            className="fs-14 text-primary"
          >
            <b>Política </b>
          </a>
        </span>
      </Label>
      {(errors as any)?.term && (
        <div className="custom-invalid-feedback">
          Termos de uso são obrigatórios
        </div>
      )}
    </>
  );
};
