import axios from "axios";
import { errorHandler } from "helpers/http-helper";

export const mapperExtensionToType = (extension: string) => {
  const mapper =  {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    pdf: "application/pdf",
  } as any;

  return mapper[extension] || "application/octet-stream";
};

export const handleDownloadFile = async (
  url: string,
  fileName: string,
  type = "application/pdf"
) => {
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const blob = new Blob([response.data], { type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
    errorHandler(error, "Erro ao realizar download do arquivo");
  }
};
