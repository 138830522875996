import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import {
  AdminDoctorDataProps,
  FormAdminDoctorData,
} from "views/painel/employees/common/admin-doctor/form/form-admin-doctor-data";
import { actionFetchAdminDoctor } from "actions/users/admin-doctor/action-fetch-admin-doctor";
import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";

export const ListAdminDoctor: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [adminDoctorData, setAdminDoctorData] = useState<AdminDoctorDataProps>({
    id: undefined,
    email: "",
    name: "",
    document_number: "",
    phone: "",
    status: UserStatusEnum.ACTIVE,
    profession: ProfessionTypeEnum.DOCTOR,
    register_number: [
      {
        register_number: "",
        register_number_uf: "",
        is_selected: true,
      },
    ],
  });

  const fetchAdminDoctorData = async () => {
    if (id) {
      const response = await actionFetchAdminDoctor(id);
      setAdminDoctorData({
        ...response,
        document_number: response?.document_number?.toString() ?? "",
      });
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchAdminDoctorData();
      } catch {
        navigate(`/painel/colaboradores`);
      }
    })();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          {isLoaded && (
            <FormAdminDoctorData
              adminDoctorData={adminDoctorData}
              onSubmit={() => {}}
              isReadOnly={true}
              onCancel={() => navigate(`/painel/colaboradores`)}
            />
          )}
        </div>
      </div>
    </>
  );
};
