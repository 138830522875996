import { makeAutoObservable } from "mobx";

export interface HealthPlansLight {
  id: number;
  name: string;
}

class ListAllHealthPlansStore {
  healthPlansLight: HealthPlansLight[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setHealthPlansLight(healthPlansLight: HealthPlansLight[]) {
    this.healthPlansLight = healthPlansLight;
  }

  getHealthPlansLight(): HealthPlansLight[] {
    return this.healthPlansLight;
  }

  healthPlansLightIsEmpty(): boolean {
    return !this.healthPlansLight?.length;
  }

  cleanData(): void {
    this.healthPlansLight = [];
  }
}

const listAllHealthPlansStore = new ListAllHealthPlansStore();

export default listAllHealthPlansStore;
