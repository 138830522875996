import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListDoctorsProps,
  ListDoctorsResponse,
  UserService,
} from "services/user-service";
import listAllDoctorsSelectableStore from "state/doctors/list-all-doctors-selectable-store";

export const DEFAULT_DOCTOR_SEARCH_LIMIT = 25;

export const actionFetchAllDoctors = action(
  async (input: ListDoctorsProps): Promise<ListDoctorsResponse> => {
    const isDefaultSearche = (input: ListDoctorsProps) => {
      return (
        input.name === "" &&
        input.page === 1 &&
        input.isSelectable === true &&
        input.limit === DEFAULT_DOCTOR_SEARCH_LIMIT
      );
    };

    SetIsLoadingState(true);
    try {
      if (
        isDefaultSearche(input) &&
        listAllDoctorsSelectableStore.getResponse() !== null
      ) {
        return listAllDoctorsSelectableStore.getResponse() as ListDoctorsResponse;
      }

      const result = await UserService.fetchAllDoctors(input);

      if (isDefaultSearche(input)) {
        listAllDoctorsSelectableStore.setResponse(result);
      }

      return result;
    } catch (error) {
      return {
        items: [],
        meta: {},
      } as unknown as ListDoctorsResponse;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
