import { errorMessage } from "./toast";

export const errorHandler = (error: any, message = "") => {
  let msg;

  if (error?.response?.status === 404) {
    msg = 'Registro não encontrado'
  }
  if (error?.response?.status === 403) {
    return; //bypass
  }
  else if (
    Array.isArray(error?.response?.data?.message) &&
    error?.response?.data?.message.length > 0
  )
    msg = error?.response?.data?.message[0];
  else if (typeof error?.response?.data?.message === "string") {
    msg = error?.response?.data?.message;
  } else {
    msg = message;
  }

  errorMessage(msg);
};
