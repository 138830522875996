import _ from "lodash";

import { Patient } from "domain/patient/entities/patient";
import { UpdatePatientProps } from "services/patient-service";

export class UpdatePatientToRequestMapper {
  static map(input: Patient): UpdatePatientProps {
    return {
      name: input.name,
      phone: `${input.phone?.replace(/\D/g, "")}`,
      birthdate: _.isEmpty(input.birthdate) ? null : input.birthdate,
      email: input.email,
      document_number: _.isEmpty(input.document_number)
        ? null
        : input.document_number,
      sex: input.sex,
      address: {
        street: input.address.street,
        number: input.address.number,
        complement: input.address.complement,
        neighborhood: input.address.neighborhood,
        city: input.address.city,
        state: input.address.state,
        zip_code: input.address.zip_code,
      },
      profession: _.isEmpty(input.profession) ? null : input.profession,
      father_name: _.isEmpty(input.father_name) ? null : input.father_name,
      mother_name: _.isEmpty(input.mother_name) ? null : input.mother_name,
      spouse: _.isEmpty(input.spouse) ? null : input.spouse,
      patient_insurance_number: _.isEmpty(input.patient_insurance_number)
        ? null
        : input.patient_insurance_number,
      health_plan_id: input?.health_plan?.id
        ? (input?.health_plan?.id as number)
        : null,
      observation: _.isEmpty(input.observation) ? null : input.observation,
    };
  }
}
