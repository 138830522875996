import "react-datepicker/dist/react-datepicker.css";
import { Button, FormGroup, Label, Row } from "reactstrap";
import { validateCPF } from "validators/validate-cpf";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  UserStatusEnum,
  UserStatusEnumLabels,
} from "domain/user/entities/enums/user-status.enum";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { SelectUserStatusForm } from "components/form/input/select-user-status-form";
import { isPhoneValid } from "helpers/phone-validator";

export interface FormAdminDataProps {
  adminData: AdminDataProps;
  onSubmit: (admin: AdminDataProps) => void;
  onCancel: () => void;
}

export interface AdminDataProps {
  id?: number;
  email: string;
  name: string;
  phone: string;
  document_number: string | null;
  created_at?: string;
  updated_at?: string;
  status: UserStatusEnum;
}

export interface AdminDataFormValues {
  id?: number;
  email: string;
  name: string;
  phone: string;
  document_number: string | null;
  created_at?: string;
  updated_at?: string;
  status?: {
    value: UserStatusEnum;
    label: string;
  };
}

export const FormAdminData: React.FC<FormAdminDataProps> = ({
  adminData,
  onSubmit,
  onCancel,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    phone: Yup.string().test("phone", "Telefone inválido", (value) => {
      return isPhoneValid(value ?? "");
    }),
    email: Yup.string().email("E-mail inválido").required(),
    document_number: Yup.string().test(
      "custom-validation",
      "CPF inválido",
      function (value) {
        return validateCPF(value ?? "");
      }
    ),
    status: Yup.object().test(
      "custom-validation-status",
      "Status inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
  });

  const initialValues: AdminDataFormValues = {
    id: adminData.id,
    email: adminData.email,
    name: adminData.name,
    document_number: adminData.document_number,
    phone: adminData.phone,
    created_at: adminData.created_at,
    updated_at: adminData.updated_at,
    status: {
      value: adminData.status,
      label: UserStatusEnumLabels(adminData.status),
    },
  };

  const handleSubmit = async (values: AdminDataFormValues) => {
    return await onSubmit({
      ...values,
      document_number:
        values.document_number?.length == 0 ? null : values.document_number,
      status: values.status?.value ?? UserStatusEnum.ACTIVE,
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="name">Nome completo*</Label>
                <Field
                  name="name"
                  id="name"
                  placeholder="Nome do administrador"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="document_number">CPF do administrador</Label>
                <Field
                  name="document_number"
                  id="document_number"
                  placeholder="CPF do administrador"
                  type="text"
                  mask="000.000.000-00"
                  disabled={adminData.id !== undefined}
                  component={CustomMaskInput}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="email">E-mail*</Label>
                <Field
                  name="email"
                  id="email"
                  placeholder="Digite e-mail"
                  type="email"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 ">
                <Label htmlFor="email">Telefone*</Label>
                <Field
                  name="phone"
                  id="phone"
                  placeholder="(00) 00000-0000"
                  type="text"
                  mask="(00) 00000-0000"
                  component={CustomMaskInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 ">
                <SelectUserStatusForm keyName="status" />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <div className="col-12 col-md-3 ">
                <Button
                  color="secondary"
                  className="form-control"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-12 col-md-3 ">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
