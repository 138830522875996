import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";
import { successMessage } from "helpers/toast";
import { PaginationMeta } from "components/table/custom-table";

export interface UploadAttachmentResponse {
  id: number;
  url: string;
  thumbnail: string;
}

interface AttachmentProps {
  id: number;
  description: string;
}
interface AnamnesisProps {
  main_complaint: string;
  current_illness_history: string;
  medical_history: string;
  physical_examination: string;
  diagnosis: string;
  treatment_plan: string;
}
interface ExamsProps {
  title: string;
  description: string;
  attachments: AttachmentProps[];
}
interface ProceduresProps {
  title: string;
  description: string;
  attachments: AttachmentProps[];
}
export interface CreateMedicalRecordProps {
  patient_id: number;
  anamnesis?: AnamnesisProps;
  exams?: ExamsProps[];
  procedures?: ProceduresProps[];
  attachments?: AttachmentProps[];
  estimated_return_time: string | null;
}

export interface Anamnesis {
  main_complaint: string;
  current_illness_history: string;
  medical_history: string;
  physical_examination: string;
  diagnosis: string;
  treatment_plan: string;
}
export interface Attachment {
  id: number;
  description: string;
  url: string;
  thumbnail: string;
  extension: string;
}
export interface Exam {
  title: string;
  description: string;
  attachments: Attachment[];
}
export interface Procedure {
  title: string;
  description: string;
  attachments: Attachment[];
}
export interface ListMedicalRecord {
  id: string;
  doctorId: number;
  patientId: number;
  tenantId: number;
  created_at: Date;
  doctor: {
    id: number;
    name: string;
  };
  estimated_return_date: string | null;
  attachments: Attachment[] | null;
  exams: Exam[] | null;
  procedures: Procedure[] | null;
  anamnesis: Anamnesis | null;
  doctor_name: string | null;
}

export interface ListMedicalRecordResponse {
  items: ListMedicalRecord[];
  meta: PaginationMeta;
}

export class MedicalRecordService {
  static async upload(data: any): Promise<UploadAttachmentResponse> {
    try {
      const response = await httpClient.request({
        url: "/medical-record/file",
        method: "post",
        data: data,
        headers: {
          accept: "application/json",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async createMedicalRecord(
    input: CreateMedicalRecordProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: "/medical-record",
        method: "post",
        data: input,
      });
      successMessage("Atendimento realizado com sucesso");
    } catch (err) {
      errorHandler(err, "Falha ao realizar atendimento");
      throw err;
    }
  }

  static async fetchAllMedicalRecord(
    patientId: number
  ): Promise<ListMedicalRecordResponse> {
    try {
      const response = await httpClient.request({
        url: `/medical-record/patient/${patientId}`,
        method: "get",
      });

      return response.data;
    } catch (err) {
      errorHandler(err, "Falha ao realizar atendimento");
      throw err;
    }
  }

  static async deleteFile(id: number): Promise<void> {
    try {
      await httpClient.request({
        url: `/medical-record/file/${id}`,
        method: "delete",
      });
    } catch (err) {
      throw err;
    }
  }
}
