import { Exam } from "services/medical-record-service";
import {
  handleDownloadFile,
  mapperExtensionToType,
} from "actions/file/handle-download-file";

export interface MedicalRecordExamsItemProps {
  exams: Exam[] | null;
}
export const MedicalRecordExamsItem: React.FC<MedicalRecordExamsItemProps> = ({
  exams,
}) => {
  return (
    <>
      <div className="d-flex row">
        <h4 className="text-primary">Exames Complementares</h4>
        {exams?.map((exam, index) => (
          <div key={`exam-item-${index}`} className="table-card-row">
            <div className="medical-record-history-row ">
              <div className="medical-record-history-title">Nome do Exame</div>
              <div
                className="medical-record-history-value"
                dangerouslySetInnerHTML={{ __html: exam.title }}
              ></div>
            </div>

            <div className="medical-record-history-row ">
              <div className="medical-record-history-title">
                Descrição do Exame
              </div>
              <div
                className="medical-record-history-value"
                dangerouslySetInnerHTML={{ __html: exam.description }}
              ></div>
            </div>

            <div className="table-card-row ">
              {exam.attachments.length > 0 && (
                <>
                  <div className="medical-record-history-title">Anexos</div>

                  <div className="table-card-value">
                    {exam.attachments.map((attachment, index) => (
                      <div
                        className="d-flex flex-wrap align-items-center py-2 mb-2 col-12"
                        style={{ backgroundColor: "#f5f7fe" }}
                        key={`attachment-${index}`}
                      >
                        <div className="d-flex flex-wrap col-12 mb-2">
                          <img
                            src={attachment.thumbnail}
                            height="45px"
                            alt="imagem-download"
                          />
                          <div className="font-weight-500 text-black-300 mx-3">
                            {attachment.description}
                          </div>
                        </div>
                        <div className="d-flex  col-12">
                          <div
                            className="btn-secondary px-3 py-1 rounded-050 fs-13"
                            onClick={() =>
                              handleDownloadFile(
                                attachment.url,
                               `Anexo-${new Date().getDate().toString().padStart(2, '0')}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getFullYear()}.${attachment.extension}`,
                                mapperExtensionToType(attachment.extension)
                              )
                            }
                          >
                            <i
                              className="fa fa-download"
                              aria-hidden="true"
                            ></i>{" "}
                            Download
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {index !== exams.length - 1 && <div className="mb-4" />}
          </div>
        ))}
      </div>
    </>
  );
};
