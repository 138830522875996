import { dateFormatter } from "helpers/date-formatter";
import "./estimated-return.style.css";

interface EstimatedReturnHeaderInfoProps {
  patientName: string;
  doctorName: string;
  currentAppointmentDate: string;
  estimatedReturnDate: string;
}

export const EstimatedReturnHeaderInfo: React.FC<
  EstimatedReturnHeaderInfoProps
> = ({
  patientName,
  doctorName,
  currentAppointmentDate,
  estimatedReturnDate,
}) => {
  return (
    <>
      <div>
        <div className="d-flex col-12">
          <div className="d-flex col-12 col-md-6 row">
            <div className="col-12 mt-1 row">
              <span className="prescription-content-label">Médico</span>
              <span className="prescription-content-text">{doctorName}</span>
            </div>
            <div className="col-12 mt-1 row">
              <span className="prescription-content-label">Paciente</span>
              <span className="prescription-content-text">{patientName}</span>
            </div>
          </div>
          <div className="d-flex col-12 col-md-6 flex-wrap">
            <div className="col-12 mt-1 row">
              <span className="prescription-content-label">
                Data da Consulta
              </span>
              <span className="prescription-content-text">
                {" "}
                {dateFormatter(currentAppointmentDate, "dd/MM/yyyy") ||
                  "Não informado"}
              </span>
            </div>
            <div className="col-12 mt-1 row">
              <span className="prescription-content-label">
                Previsão de retorno
              </span>
              <span className="prescription-content-text">
                {dateFormatter(estimatedReturnDate, "dd/MM/yyyy") ||
                  "Não informado"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
