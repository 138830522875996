import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { customStyles } from "./helpers/react-select-styles";
import { observer } from "mobx-react";
import listAllHealthPlansStore from "state/health-plans/list-all-health-plans-store";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};

interface SelectHealthPlanFormProps {
  isReadOnly?: boolean;
}

export const SelectHealthPlanForm: React.FC<SelectHealthPlanFormProps> =
  observer(({ isReadOnly }: SelectHealthPlanFormProps) => {
    const { setFieldValue, errors, values } = useFormikContext();

    const onChangeHealthPlan = (selectedOption: any) => {
      if (!selectedOption) setFieldValue("health_plan", undefined);
      setFieldValue("health_plan", selectedOption);
    };

    const healthPlans = listAllHealthPlansStore
      .getHealthPlansLight()
      ?.map((healthPlan) => ({
        value: healthPlan.id,
        label: healthPlan.name,
      }));

    return (
      <>
        <FormGroup className="col-12 px-0">
          <Label htmlFor="health_plan">Plano de saúde</Label>
          <Field
            name="health_plan"
            id="health_plan"
            isClearable
            isSearchable
            onChange={onChangeHealthPlan}
            defaultValue={(values as any)?.health_plan}
            placeholder="Plano de saúde"
            options={healthPlans}
            filterOption={createFilter(filterConfig as any)}
            component={Select}
            isDisabled={isReadOnly}
            styles={customStyles}
          />
          {(errors as any)?.payment_type && (
            <div className="custom-invalid-feedback">
              Método de pagamento inválido
            </div>
          )}
        </FormGroup>
      </>
    );
  });
