import { useCallback, useState } from "react";
import OTPInput from "react-otp-input";
import { useParams } from "react-router-dom";
import { Button, Col, Form, Row } from "reactstrap";
import { actionGetLinkDownloadPrescriptionValidationCode } from "actions/eletronic-prescription/action-get-link-download-prescription-validation-code";
import { errorMessage } from "helpers/toast";
import { GetPrescriptionDownloadVerificationCodeResponse } from "services/eletronic-prescription-service";
import { handleDownloadFile } from "actions/file/handle-download-file";
import {
  PrescriptionCategoryEnum,
  PrescriptionCategoryLabels,
} from "domain/eletronic-prescription/entities/enums/prescription-category.enum";

export const PrescriptionValidationCodeContainer = () => {
  let { id } = useParams();

  const [otp, setOtp] = useState("");
  const [result, setResult] = useState<
    GetPrescriptionDownloadVerificationCodeResponse[]
  >([]);
  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (!id) {
      errorMessage("Token inválido");
      return;
    }
    try {
      const result = await actionGetLinkDownloadPrescriptionValidationCode(
        id,
        otp
      );
      setResult(result);
    } catch {}
  };

  return (
    <>
      <Row className="d-flex col-12 col-xl-7 d-flex align-items-center row flex-column">
        <img
          className="mx-auto mb-5 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          md="8"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <h3 className="text-center text-primary"> Prescrição Digital</h3>
          <p className="mt-2">
            Token: <span className="text-primary font-weight-500">{id}</span>
          </p>

          {!result || result.length === 0 ? (
            <>
              <span className="mb-2">
                Para acessar sua prescrição digital, digite o{" "}
                <b className="font-weight-500"> código de acesso (4 números)</b>{" "}
                enviado via Whatsapp.
              </span>

              <Form className="px-0" onSubmit={onSubmit}>
                <div className="d-flex justify-content-center">
                  <OTPInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus
                    inputStyle={{
                      width: "40px",
                      height: "40px",
                      margin: "5px",
                      fontSize: "20px",
                      borderRadius: "4px",
                      border: "1px solid rgba(0,0,0,0.3)",
                      textAlign: "center",
                    }}
                  />
                </div>

                <Button
                  className="my-4 form-control font-weight-600"
                  color="primary"
                  type="submit"
                  onClick={onSubmit}
                >
                  Baixar Prescrição
                </Button>
              </Form>
            </>
          ) : (
            <>
              {result.map((document, index) => (
                <div key={index}>
                  <div
                    className="d-flex justify-content-between align-items-center flex-wrap  py-2"
                    style={{ backgroundColor: "#f5f7fe" }}
                  >
                    <div className="d-flex flex-wrap align-items-center">
                      <div>
                        <img
                          src="https://app.g3med.com/pdf-64.png"
                          height="45px"
                          alt="pdf"
                        />
                      </div>
                      <div className="font-weight-500 text-black-600 mx-3">
                        {PrescriptionCategoryLabels(
                          document.category as PrescriptionCategoryEnum
                        )}
                      </div>
                    </div>
                    <div
                      className="btn-secondary px-3 py-1 rounded-050"
                      onClick={() =>
                        handleDownloadFile(
                          document.link,
                          `${document.category}.pdf`
                        )
                      }
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>{" "}
                      Baixar PDF
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
