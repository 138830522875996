import { Button, Col, Row } from "reactstrap";
import { useState } from "react";
import { ScheduleTypeLabels } from "domain/schedule/enums/schedule-type.enum";
import { dateFormatter } from "helpers/date-formatter";
import { ListAllFinancialStatementItem } from "services/financial-service";
import {
  PaymentTypeEnum,
  PaymentTypeLabels,
} from "domain/schedule/enums/payment-type.enum";
import { labelMaskFormatToReal } from "helpers/format-money";
import {
  ScheduleStatusEnum,
  ScheduleStatusLabels,
} from "domain/schedule/enums/schedule-status.enum";
import {
  ScheduleOriginContactEnum,
  ScheduleOriginContactLabels,
} from "domain/schedule/enums/schedule-origin-contact.enum";
import {
  DataModalMakeAppointmentPaymentProps,
  ModalMakeAppointmentPayment,
} from "views/painel/schedule/list-schedule/modal-make-appointment-payment";
import { ScheduleCalendarMakeAppointmentPaymentProps } from "views/painel/schedule/index/tabs/calendar/schedule-calendar-tab";
import { actionMakeAppointmentPayment } from "actions/schedule/action-make-appointment-payment";
import { action } from "mobx";
import financialStatementStore from "state/financial-statement/financial-statement-store";

interface FinancialStatementDetailProps {
  data: ListAllFinancialStatementItem | null;
}

export const FinancialStatementDetail: React.FC<
  FinancialStatementDetailProps
> = action(({ data }) => {
  const getPaymentType = (data: ListAllFinancialStatementItem) => {
    if (data.payment_type) {
      let result = PaymentTypeLabels(data.payment_type);

      if (data.health_plan?.name) {
        result += ` (${data.health_plan?.name})`;
      }
      return result;
    } else return "N/A";
  };

  const [isMakePaymentOpen, setIsMakePaymentOpen] = useState(false);

  const [makePaymentData, setMakePaymentData] =
    useState<DataModalMakeAppointmentPaymentProps>({
      amount: labelMaskFormatToReal(data?.amount ? String(data?.amount) : "0"),
      amount_paid: labelMaskFormatToReal(
        data?.amount_paid ? String(data?.amount_paid) : "0"
      ),
      payment_type: data?.payment_type ?? undefined,
      paid_at: data?.paid_at ?? "",
      payment_observation: data?.payment_observation ?? "",
      health_plan: data?.health_plan ?? null,
      type: data?.type ?? null,
      schedule_type: data?.schedule_type ?? null,
    });

  const onMakeAppointmentPayment = async (
    input: ScheduleCalendarMakeAppointmentPaymentProps
  ) => {
    if (data) {
      try {
        const payload = {
          amount_paid: input.amount_paid,
          payment_type: input.payment_type,
          paid_at: input.paid_at,
          payment_observation: input.payment_observation,
          health_plan_id: input.health_plan?.id,
        };
        await actionMakeAppointmentPayment(data.id, payload);

        setIsMakePaymentOpen(false);
        financialStatementStore.setShouldReload(true);
      } catch {}
    }
  };

  return (
    <>
      {data && (
        <>
          <Row className="p-0">
            <Row className="mb-3">
              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Nome Profissional
                </Col>
                <Col sm="12" md="7">
                  {data.doctor_name}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Nome Paciente
                </Col>
                <Col sm="12" md="7">
                  {data.patient_name}
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Tipo Atendimento
                </Col>
                <Col sm="12" md="7">
                  {data.type !== null
                    ? ScheduleTypeLabels(data.type)
                    : data.schedule_type?.name ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Status Atendimento
                </Col>
                <Col sm="12" md="7">
                  {ScheduleStatusLabels(
                    data.status as unknown as ScheduleStatusEnum
                  )}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Data Atendimento
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(data.start)}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Valor Atendimento
                </Col>
                <Col sm="12" md="7">
                  {labelMaskFormatToReal(String(data.amount))}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Método Pagamento
                </Col>
                <Col sm="12" md="7">
                  {getPaymentType(data)}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Data Pagamento
                </Col>
                <Col sm="12" md="7">
                  {data.amount_paid
                    ? dateFormatter(data.paid_at, "dd/MM/yyyy")
                    : "Não Pago"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Valor Pago
                </Col>
                <Col sm="12" md="7">
                  {data.amount_paid
                    ? labelMaskFormatToReal(String(data.amount_paid))
                    : "Não Pago"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Origem Contato
                </Col>
                <Col sm="12" md="7">
                  {data.origin_contact
                    ? ScheduleOriginContactLabels(
                        data.origin_contact as unknown as ScheduleOriginContactEnum
                      )
                    : "Não informado"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label  font-weight-500">
                  Descrição pagamento
                </Col>
                <Col sm="12" md="7">
                  {data.payment_observation ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <div className="col-12">
                  <Button
                    color="secondary"
                    onClick={() => setIsMakePaymentOpen(true)}
                    className=" form-control"
                  >
                    Lançar Pagamento
                  </Button>
                </div>
              </Row>

              <ModalMakeAppointmentPayment
                isOpen={isMakePaymentOpen}
                toggle={() => setIsMakePaymentOpen((prev) => !prev)}
                data={makePaymentData}
                onSubmit={onMakeAppointmentPayment}
              />
            </Row>
          </Row>
        </>
      )}
    </>
  );
});
