export enum PaymentTypeEnum {
  MONEY = 1,
  DEBIT_CARD = 2,
  CREDIT_CARD = 3,
  PIX = 4,
  HEALTH_PLAN = 5,
}

export const PaymentTypeLabels = (paymentType: PaymentTypeEnum) => {
  const labels = {
    [PaymentTypeEnum.MONEY]: "Dinheiro",
    [PaymentTypeEnum.CREDIT_CARD]: "Cartão de Crédito",
    [PaymentTypeEnum.DEBIT_CARD]: "Cartão de Débito",
    [PaymentTypeEnum.PIX]: "PIX",
    [PaymentTypeEnum.HEALTH_PLAN]: 'Plano de Saúde',
  };

  return labels[paymentType];
};


export const PaymentTypeColors = {
  [PaymentTypeEnum.PIX]: {
    hex: "#518BD8",
    rgba: "rgba(81, 139, 216, 1)",
  },
  [PaymentTypeEnum.MONEY]: {
    hex: "#62CDBB",
    rgba: "rgba(98, 205, 187, 1)",
  },
  [PaymentTypeEnum.DEBIT_CARD]: {
    hex: "#DFDFDF",
    rgba: "rgba(223, 223, 223, 1)",
  },
  [PaymentTypeEnum.CREDIT_CARD]: {
    hex: "#434F87",
    rgba: "rgba(67, 79, 135, 1)",
  },
  [PaymentTypeEnum.HEALTH_PLAN]: {
    hex: "#96CAC2",
    rgba: "rgba(150, 202, 194, 1)",
  },
};
