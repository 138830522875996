import { useFormikContext } from "formik";
import { Button } from "reactstrap";

export const ButtonSubmitForm = () => {
  const { errors } = useFormikContext();
  return (
    <>
      <Button
        type="submit"
        color="primary"
        className="form-control"
        disabled={(errors as any)?.term}
      >
        Cadastrar
      </Button>
    </>
  );
};
