import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { MedicalRecordActions } from "./medical-record-actions/medical-record-actions";
import { PatientHeaderInfo } from "./patient-header-info/patient-header-info";
import { StartAppointment } from "./start-appointment/start-appointment";

import { actionFetchPatient } from "actions/patients/action-fetch-patient";
import { errorMessage } from "helpers/toast";

import "./medical-record.css";
import { MedicalRecordHistory } from "./start-appointment/medical-record-history/medical-record-history";
import { ListPatientResponse } from "services/patient-service";
import { UserSexEnum } from "domain/user/entities/enums/user-sex.enum";
import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CompletePatientData from "./complete-patient-data/complete-patient-data";

interface PatientDataInterface extends ListPatientResponse {}

export const MedicalRecord: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [patientData, setPatientData] = useState<PatientDataInterface>({
    id: 0,
    name: "",
    birthdate: new Date(),
    status: UserStatusEnum.ACTIVE,
    phone: "",
    sex: UserSexEnum.NEUTRAL,
    document_number: "",
    email: "",
    first_appointment: new Date(),
    last_appointment: new Date(),
    profession: "",
    father_name: "",
    mother_name: "",
    spouse: "",
    patient_insurance_number: "",
    health_plan: null,
    observation: "",
    address: {
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
      city: "",
      state: "",
      zip_code: "",
    },
    created_at: new Date(),
    updated_at: new Date(),
  });

  const [modal, setModal] = useState(false);
  const [viewCompletePatientData, setViewCompletePatientData] = useState(false);
  const toggle = () => setModal(!modal);

  const fetchPatient = async () => {
    if (!id) {
      errorMessage("Paciente não encontrado");
      navigate("/pacientes");
      return;
    }
    try {
      const response = await actionFetchPatient(id);
      setPatientData(response);
    } catch (error) {
      navigate("/pacientes");
      return;
    }
  };

  const onViewPatientData = () => {
    setViewCompletePatientData(true);
  };

  useEffect(() => {
    (async () => {
      fetchPatient();
    })();
  }, []);

  const onStartAppointment = () => {
    toggle();
  };

  const onSubmitAppointment = () => {};

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          <PatientHeaderInfo
            {...patientData}
            onViewPatientData={onViewPatientData}
          />
          <div className="mt-4">
            {!modal && (
              <>
                <MedicalRecordActions onStartAppointment={onStartAppointment} />
                {id !== undefined && <MedicalRecordHistory id={Number(id)} />}
              </>
            )}
            {modal && (
              <StartAppointment
                onSubmit={onSubmitAppointment}
                patient={patientData}
                onCancelAppointment={toggle}
              />
            )}
          </div>
        </div>

        <Modal
          isOpen={viewCompletePatientData === true}
          toggle={() => setViewCompletePatientData(false)}
          size="md"
        >
          <ModalHeader toggle={() => setViewCompletePatientData(false)}>
            Dados do Paciente
          </ModalHeader>
          <ModalBody>
            <CompletePatientData patientData={patientData} />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};
