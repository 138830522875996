import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";
import { PaginationMeta } from "components/table/custom-table";
import { errorHandler } from "helpers/http-helper";
import { httpClient } from "config/http-client";
import { errorMessage } from "helpers/toast";
import { documentMask } from "helpers/document-formatter";

/**
 * DOCTORS
 */
export interface ListDoctorsItems {
  id: string;
  name: string;
  document_number: string;
  created_at: string;
}
export interface ListDoctorsResponse {
  items: ListDoctorsItems[];
  meta: PaginationMeta;
}
export interface ListDoctorsProps {
  name?: string;
  limit: number;
  page: number;
  isSelectable?: boolean;
}
export interface ListDoctorResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: string;
  updated_at: string;
  status: UserStatusEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}
export interface CreateDoctorProps {
  name: string;
  document_number: string | null;
  email: string;
  phone: string;
  status: UserStatusEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}
export interface UpdateDoctorProps {
  name: string;
  document_number: string | null;
  phone: string;
  status: UserStatusEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}

/**
 * RECEPTIONISTS
 */
export interface ListReceptionistsItems {
  id: string;
  name: string;
  document_number: string;
  created_at: string;
}
export interface ListReceptionistsResponse {
  items: ListReceptionistsItems[];
  meta: PaginationMeta;
}
export interface ListReceptionistsProps {
  name?: string;
  limit: number;
  page: number;
}
export interface CreateReceptionistProps {
  name: string;
  document_number: string | null;
  email: string;
  phone: string;
  status: UserStatusEnum;
}
export interface UpdateReceptionistProps {
  name: string;
  document_number: string | null;
  phone: string;
  status: UserStatusEnum;
}

export interface ListReceptionistResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: string;
  updated_at: string;
  status: UserStatusEnum;
}
/**
 * ADMINS
 */

export interface CreateAdminProps {
  name: string;
  document_number: string | null;
  email: string;
  phone: string;
  status: UserStatusEnum;
}

export interface UpdateAdminProps {
  name: string;
  document_number: string | null;
  phone: string;
  status: UserStatusEnum;
}

export interface ListAdminsItems {
  id: string;
  name: string;
  login: string;
  created_at: string;
}
export interface ListAdminsResponse {
  items: ListAdminsItems[];
  meta: PaginationMeta;
}
export interface ListAdminsProps {
  name?: string;
  limit: number;
  page: number;
}

export interface ListAdminResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: string;
  updated_at: string;
  status: UserStatusEnum;
}

/**
 * ADMIN DOCTOR
 */
export interface ListAdminDoctorsItems {
  id: string;
  name: string;
  document_number: string;
  created_at: string;
}
export interface ListAdminDoctorsResponse {
  items: ListAdminDoctorsItems[];
  meta: PaginationMeta;
}
export interface ListAdminDoctorsProps {
  name?: string;
  limit: number;
  page: number;
}
export interface ListAdminDoctorResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: string;
  updated_at: string;
  status: UserStatusEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}
export interface CreateAdminDoctorProps {
  name: string;
  document_number: string | null;
  email: string;
  phone: string;
  status: UserStatusEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}
export interface UpdateAdminDoctorProps {
  name: string;
  document_number: string | null;
  phone: string;
  status: UserStatusEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}

class UserServiceMapper {
  static listUserToDomain<T>(data: any): T {
    const items = data.items.map((item: any) => ({
      id: item.id,
      name: item.name,
      document_number: documentMask(item.document_number),
      created_at: item.created_at,
    }));

    return {
      items,
      meta: data.meta,
    } as T;
  }
}

export class UserService {
  /**
   * DOCTORS
   */
  static async fetchAllDoctors(
    input: ListDoctorsProps
  ): Promise<ListDoctorsResponse> {
    try {
      const result = await httpClient.request({
        url: "/users/doctors",
        method: "get",
        params: input,
      });
      return UserServiceMapper.listUserToDomain<ListDoctorsResponse>(
        result.data
      );
    } catch (err) {
      errorMessage("Falha ao buscar médicos");
      throw new Error("Falha ao buscar médicos");
    }
  }

  static async fetchDoctor(id: string): Promise<ListDoctorResponse> {
    try {
      const response = await httpClient.request({
        url: `/users/doctors/${id}`,
        method: "get",
      });
      return response.data as ListDoctorResponse;
    } catch (err) {
      errorHandler(err, "Falha ao buscar médico");
      throw new Error("Falha ao buscar médico");
    }
  }

  static async createDoctor(input: CreateDoctorProps): Promise<void> {
    try {
      await httpClient.request({
        url: "/users/doctors",
        method: "post",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao criar médico");
      throw new Error("Falha ao criar médico");
    }
  }
  static async updateDoctor(
    id: string,
    input: UpdateDoctorProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: `/users/doctors/${id}`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar médico");
      throw new Error("Falha ao atualizar médico");
    }
  }

  /**
   * RECEPTIONISTS
   */
  static async fetchAllReceptionists(
    input: ListReceptionistsProps
  ): Promise<ListReceptionistsResponse> {
    try {
      const result = await httpClient.request({
        url: "/users/receptionists",
        method: "get",
        params: input,
      });
      return UserServiceMapper.listUserToDomain<ListReceptionistsResponse>(
        result.data
      );
    } catch (err) {
      errorMessage("Falha ao buscar recepcionistas");
      throw new Error("Falha ao buscar recepcionistas");
    }
  }

  static async createReceptionist(
    input: CreateReceptionistProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: "/users/receptionists",
        method: "post",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao criar recepcionista");
      throw new Error("Falha ao criar recepcionista");
    }
  }
  static async updateReceptionist(
    id: string,
    input: UpdateReceptionistProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: `/users/receptionists/${id}`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar recepcionista");
      throw new Error("Falha ao atualizar recepcionista");
    }
  }

  static async fetchReceptionist(
    id: string
  ): Promise<ListReceptionistResponse> {
    try {
      const result = await httpClient.request({
        url: `/users/receptionists/${id}`,
        method: "get",
      });
      return result.data as ListReceptionistResponse;
    } catch (err) {
      errorHandler(err, "Falha ao buscar recepcionista");
      throw new Error("Falha ao buscar recepcionista");
    }
  }
  /**
   * ADMINS
   */

  static async fetchAdmin(id: string): Promise<ListAdminResponse> {
    try {
      const result = await httpClient.request({
        url: `/users/admins/${id}`,
        method: "get",
      });
      return result.data as ListAdminResponse;
    } catch (err) {
      errorHandler(err, "Falha ao buscar administrador");
      throw new Error("Falha ao buscar administrador");
    }
  }

  static async fetchAllAdmins(
    input: ListAdminsProps
  ): Promise<ListAdminsResponse> {
    try {
      const result = await httpClient.request({
        url: "/users/admins",
        method: "get",
        params: input,
      });
      return UserServiceMapper.listUserToDomain<ListAdminsResponse>(
        result.data
      );
    } catch (err) {
      errorMessage("Falha ao buscar administradores");
      throw new Error("Falha ao buscar administradores");
    }
  }

  static async createAdmin(input: CreateAdminProps): Promise<void> {
    try {
      await httpClient.request({
        url: "/users/admins",
        method: "post",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao criar administrador");
      throw new Error("Falha ao criar administrador");
    }
  }
  static async updateAdmin(id: string, input: UpdateAdminProps): Promise<void> {
    try {
      await httpClient.request({
        url: `/users/admins/${id}`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar administrador");
      throw new Error("Falha ao atualizar administrador");
    }
  }

  /**
   * ADMIN DOCTORS
   */
  static async fetchAllAdminDoctors(
    input: ListAdminDoctorsProps
  ): Promise<ListAdminDoctorsResponse> {
    try {
      const result = await httpClient.request({
        url: "/users/admin-doctors",
        method: "get",
        params: input,
      });
      return UserServiceMapper.listUserToDomain<ListAdminDoctorsResponse>(
        result.data
      );
    } catch (err) {
      errorMessage("Falha ao buscar médicos administradores");
      throw new Error("Falha ao buscar médicos administradores");
    }
  }

  static async fetchAdminDoctor(id: string): Promise<ListDoctorResponse> {
    try {
      const response = await httpClient.request({
        url: `/users/admin-doctors/${id}`,
        method: "get",
      });
      return response.data as ListDoctorResponse;
    } catch (err) {
      errorHandler(err, "Falha ao buscar médico");
      throw new Error("Falha ao buscar médico");
    }
  }

  static async createAdminDoctor(input: CreateAdminDoctorProps): Promise<void> {
    try {
      await httpClient.request({
        url: "/users/admin-doctors",
        method: "post",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao criar médico administrador");
      throw new Error("Falha ao criar médico administrador");
    }
  }

  static async updateAdminDoctor(
    id: string,
    input: UpdateAdminDoctorProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: `/users/admin-doctors/${id}`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar médico administrador");
      throw new Error("Falha ao atualizar médico administrador");
    }
  }
}
