import { useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { ListAdminsResponse } from "services/user-service";
import { actionFetchAllAdmins } from "actions/users/action-fetchall-admins";
import { ListAdminsTable } from "./list-admins-table";
import { dateFormatter } from "helpers/date-formatter";
import { getTableType } from "helpers/screen";

export const AdminsContainer: React.FC = () => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();

  const [admins, setAdmins] = useState<ListAdminsResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputSearch, setInputSearch] = useState("");
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());

  const fetchAdmins = async (page?: number, input?: string) => {
    const result = await actionFetchAllAdmins({
      limit: PAGE_SIZE,
      page: page || currentPage,
      name: input || inputSearch,
    });

    const items = result.items.map((item) => ({
      ...item,
      created_at: dateFormatter(new Date(item.created_at), "dd/MM/yyyy hh:mm"),
    }));
    setAdmins({ items, meta: result.meta });
  };
  useEffect(() => {
    (async () => {
      await fetchAdmins();
    })();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
    await fetchAdmins(1, inputSearch);
  };

  const onSelectAdmin = (doctorId: string) => {
    navigate(`/painel/administrador/editar/${doctorId}`);
  };

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchAdmins(page);
  };

  return (
    <div className="main-wrapper">
      <Row>
        <Col sm="12" md="6">
          <Form
            className="d-flex col-12 col-md-7 col-xl-5 justify-content-start my-4"
            onSubmit={onSubmit}
          >
            <InputGroup>
              <Input
                type="text"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                className="border-right-0"
                placeholder="Buscar por nome"
              />
              <InputGroupText className="bg-white">
                <button type="submit" className="border-0 bg-white">
                  <i
                    className="fa fa-search text-primary fs-20"
                    aria-hidden="true"
                  ></i>
                </button>
              </InputGroupText>
            </InputGroup>
          </Form>
        </Col>

        <Col
          sm="12"
          md="6"
          className="d-flex align-self-center justify-content-end"
        >
          <Button
            color="primary"
            onClick={() => navigate("/painel/administrador/criar")}
          >
            + Criar Administrador
          </Button>
        </Col>
      </Row>

      <ListAdminsTable
        admins={admins}
        onSelectAdmin={onSelectAdmin}
        onChangePage={onChangePage}
        tableType={tableType}
        setTableType={setTableType}
      />
    </div>
  );
};
