import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";
import { customStyles } from "./helpers/react-select-styles";
import listAllScheduleTypesStore from "state/schedule-types/list-all-schedule-types-store";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectScheduleTypeFormProps {
  isReadOnly?: boolean;
}
export const SelectScheduleTypeIdForm: React.FC<
  SelectScheduleTypeFormProps
> = ({ isReadOnly }: SelectScheduleTypeFormProps) => {
  const { setFieldValue, errors, values } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue("schedule_type", undefined);
    setFieldValue("schedule_type", selectedOption);
  };

  const optionsScheduleType = listAllScheduleTypesStore
    .getScheduleTypesLight()
    ?.map((scheduleType) => ({
      value: scheduleType.id,
      label: scheduleType.name,
    }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor="email">Tipo de agendamento</Label>
      <Field
        name="type"
        id="type"
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.schedule_type}
        placeholder="Tipo de agendamento"
        options={optionsScheduleType}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
        styles={customStyles}
      />
      {(errors as any)?.schedule_type && (
        <div className="custom-invalid-feedback">
          Tipo de agendamento inválido
        </div>
      )}
    </FormGroup>
  );
};
