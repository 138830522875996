import { Button, Row } from "reactstrap";
import { observer } from "mobx-react";
import { useState } from "react";

import { AnamnesisForm } from "./anamnesis/anamnesis-form";
import { CustomTab } from "components/tab/custom-tab";
import { AttachmentsForm } from "./attachments/attachments-form";
import { ProcedureForm } from "./procedure/procedure-form";
import { ComplementaryExamsForm } from "./complementary-exams/complementary-exams-form";
import medicalRecordAnamnesisStore from "state/medical-record/medical-record-anamnesis-store";
import medicalRecordAttachmentsStore from "state/medical-record/medical-record-attachments-store";
import medicalRecordComplementaryExamsStore from "state/medical-record/medical-record-complementary-exams-store";
import medicalRecordProceduresStore from "state/medical-record/medical-record-procedures-store";
import { actionCreateMedicalRecord } from "actions/medical-record/action-create-medical-record";
import { CreateMedicalRecordProps } from "services/medical-record-service";
import { MedicalRecordHistory } from "./medical-record-history/medical-record-history";
import { ModalFinishAppointment } from "./modal-finish-appointment";
import { errorMessage } from "helpers/toast";
import { actionStartPrescription } from "actions/eletronic-prescription/action-start-prescription";
import { ErrorCodeException } from "domain/error-codes/error-code-exception";
import ErrorCodesEnum from "domain/error-codes/error-code.enum";
import { useNavigate } from "react-router-dom";
import { StartPrescription } from "../start-prescription/start-prescription";
import usePrompt from "hooks/use-prompt-exit";

interface StartAppointmentProps {
  patient: {
    name: string;
    id: number;
  };
  onSubmit: () => void;
  onCancelAppointment: () => void;
}

export const StartAppointment: React.FC<StartAppointmentProps> = observer(
  ({ onCancelAppointment, patient: { name, id } }) => {
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("1");
    const [toggle, setToggle] = useState(false);
    const [urlWebview, setUrlWebview] = useState("");

    const onConfirmationAppointment = () => {
      setToggle(true);
    };

    const onSubmit = async (
      estimatedReturnDate: Date | null,
      estimatedReturnObservation?: string
    ) => {
      const data: Record<string, any> = { patient_id: id };

      if (!medicalRecordAnamnesisStore.isEmpty()) {
        data["anamnesis"] = medicalRecordAnamnesisStore.getFormData();
      }
      if (!medicalRecordComplementaryExamsStore.isEmpty()) {
        data["exams"] = medicalRecordComplementaryExamsStore.getFormData();
      }
      if (!medicalRecordProceduresStore.isEmpty()) {
        data["procedures"] = medicalRecordProceduresStore.getFormData();
      }
      if (!medicalRecordAttachmentsStore.isEmpty()) {
        data["attachments"] =
          medicalRecordAttachmentsStore.getFormData().attachments;
      }
      data["estimated_return_date"] = estimatedReturnDate;
      data["estimated_return_observation"] = estimatedReturnObservation;

      try {
        await actionCreateMedicalRecord(data as CreateMedicalRecordProps);

        medicalRecordAnamnesisStore.cleanData();
        medicalRecordComplementaryExamsStore.cleanData();
        medicalRecordProceduresStore.cleanData();
        medicalRecordAttachmentsStore.cleanData();
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    };

    const onStartPrescription = async () => {
      try {
        if (!id) {
          errorMessage("Falha ao obter paciente");
          return;
        }

        const response = await actionStartPrescription({
          patient_id: Number(id),
        });

        setUrlWebview(response.ModalURL);
      } catch (error) {
        if (error instanceof ErrorCodeException) {
          if (error.code === ErrorCodesEnum.NEEDS_UPDATE_DATA) {
            navigate(`/painel/meus-dados`);
          }
        }
      }
    };

    const onCancelPrescription = () => {
      setUrlWebview("");
    };

    const [tabs, setTabs] = useState([
      {
        label: "Histórico de atendimento",
        disabled: false,
        element: (
          <div className="mt-4">
            <MedicalRecordHistory id={id} />
          </div>
        ),
      },
      {
        label: "Anamnese",
        disabled: false,
        element: (
          <div className="mt-4">
            <AnamnesisForm />
          </div>
        ),
      },
      {
        label: "Exames Complementares",
        disabled: false,
        element: (
          <div className="mt-4">
            <ComplementaryExamsForm />
          </div>
        ),
      },
      {
        label: "Procedimentos",
        disabled: false,
        element: (
          <div className="mt-4">
            <ProcedureForm />
          </div>
        ),
      },
      {
        label: "Anexos",
        disabled: false,
        element: (
          <div className="mt-4">
            <AttachmentsForm />
          </div>
        ),
      },
    ]);

    window.addEventListener(
      "message",
      (event) => {
        const cancelPrescription = ["cancel", "excluded"];
        const submitPrescription = ["prescricao"];
        if (cancelPrescription.includes(event.data.type)) {
          onCancelPrescription();
        } else if (submitPrescription.includes(event.data.type)) {
          console.log("Prescrição finalizada", JSON.stringify(event.data));
          onCancelPrescription();
        }
      },
      false
    );

    // const [isFormDirty, setIsFormDirty] = useState(true);

    // // Set to true when user starts filling the form
    // const handleInputChange = (event) => {
    //   setIsFormDirty(true);
    // };

    // Hook to trigger the confirmation when navigating
    // usePrompt("Do you really want to exit from this page?", isFormDirty);

    return (
      <>
        <div className="wrapper-start-appointment">
          <Row>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="col-12 col-md-12 col-xl-6 d-flex text-center align-self-end justify-content-end">
                <div className="col-12 col-md-12 col-xl-6 ">
                  <Button
                    color="secondary"
                    className="font-weight-500 form-control"
                    onClick={onCancelAppointment}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
              <div className="col-12 col-md-12 col-xl-6 text-end d-flex flex-wrap justify-content-between">
                <div className="col-12 col-md-12 col-xl-5 px-0 px-xl-2 mt-2">
                  <Button
                    color="primary"
                    className="form-control"
                    onClick={onStartPrescription}
                  >
                    Realizar prescrição
                  </Button>
                </div>

                <div className="col-12 col-md-12 col-xl-7 px-0 px-xl-2 mt-2">
                  <Button
                    color="danger"
                    className="font-weight-500 form-control text-white"
                    onClick={onConfirmationAppointment}
                  >
                    Finalizar Atendimento
                  </Button>
                </div>
              </div>
            </div>
          </Row>
          <CustomTab
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        <ModalFinishAppointment
          isOpen={toggle}
          toggle={() => setToggle((prev) => !prev)}
          onSubmit={onSubmit}
        />

        <StartPrescription
          urlWebview={urlWebview}
          onCancel={onCancelPrescription}
        />
      </>
    );
  }
);
