import { Button, FormGroup, Label, Row } from "reactstrap";
import { validateCPF } from "validators/validate-cpf";
import "react-datepicker/dist/react-datepicker.css";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  ProfessionTypeEnum,
  ProfessionTypeEnumLabels,
} from "domain/user/entities/enums/profession-type.enum";
import { SelectProfessionTypeForm } from "components/form/input/select-profession-type-form";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { SelectUfForm } from "components/form/input/select-uf-form";
import { isPhoneValid } from "helpers/phone-validator";
import { getCityByUf } from "helpers/cities-list";

export interface FormMyDataDoctorProps {
  data: DataProps;
  onSubmit: (data: DataProps) => void;
}

export interface DataProps {
  email: string;
  name: string;
  document_number: string | null;
  phone: string;
  profession: ProfessionTypeEnum;
  register_number: [
    {
      id?: number;
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
}
export interface DataFormValues {
  email: string;
  name: string;
  phone: string;
  document_number: string | null;
  profession: {
    value: ProfessionTypeEnum;
    label: string;
  };
  register_number: string;
  register_number_uf: {
    value: string;
    label: string;
  };
}

export const FormMyDataOwnerDoctor: React.FC<FormMyDataDoctorProps> = ({
  data,
  onSubmit,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    phone: Yup.string().test("phone", "Telefone inválido", (value) => {
      return isPhoneValid(value ?? "");
    }),
    email: Yup.string().email("E-mail inválido").required("E-mail obrigatório"),
    register_number: Yup.string()
      .required("Número de registro é obrigatório")
      .min(3, "Número de registro inválido"),
    register_number_uf: Yup.object().test(
      "custom-validation-uf",
      "UF inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    profession: Yup.object().test(
      "custom-validation-profession",
      "Conselho inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
  });

  const initialValues: DataFormValues = {
    email: data.email,
    name: data.name,
    document_number: data.document_number,
    phone: data.phone,
    profession: {
      value: data.profession,
      label: ProfessionTypeEnumLabels(data.profession),
    },
    register_number: data.register_number[0].register_number,
    register_number_uf: getCityByUf(
      data.register_number[0].register_number_uf
    ) as any,
  };

  const handleSubmit = async (values: DataFormValues) => {
    return await onSubmit({
      ...values,
      profession: values.profession?.value ?? ProfessionTypeEnum.DOCTOR,
      register_number: [
        {
          id: data.register_number?.[0]?.id,
          register_number: values.register_number,
          register_number_uf: values.register_number_uf.value,
          is_selected: true,
        },
      ],
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0 justify-content-end">
              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="name">Nome completo*</Label>
                <Field
                  name="name"
                  id="name"
                  placeholder="Nome"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="document_number">CPF</Label>
                <Field
                  name="document_number"
                  id="document_number"
                  placeholder="CPF"
                  type="text"
                  mask="000.000.000-00"
                  disabled={true}
                  component={CustomMaskInput}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0 justify-content-end">
              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="email">E-mail*</Label>
                <Field
                  name="email"
                  id="email"
                  placeholder="Digite e-mail"
                  type="email"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="email">Telefone*</Label>
                <Field
                  name="phone"
                  id="phone"
                  placeholder="(00) 00000-0000"
                  type="text"
                  mask="(00) 00000-0000"
                  component={CustomMaskInput}
                />
              </FormGroup>

              <FormGroup className="col-12 col-md-4 col-xl-4">
                <SelectProfessionTypeForm isReadOnly={true} />
              </FormGroup>
              <FormGroup className="col-12 col-md-4 col-xl-4">
                <Label htmlFor="email">Número de registro</Label>
                <Field
                  name="register_number"
                  id="register_number"
                  placeholder="Número de registro"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-4 col-xl-4">
                <SelectUfForm keyName="register_number_uf" />
              </FormGroup>
              <div className="col-12 col-md-4 col-xl-3 align-self-center">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
