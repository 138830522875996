import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { actionFetchPatient } from "actions/patients/action-fetch-patient";
import { CustomTab } from "components/tab/custom-tab";
import {
  FormPatientData,
  PatientDataFormValues,
} from "views/painel/patients/common/form/form-patient-data";
import {
  FormPatientAddress,
  PatientAddressFormValues,
} from "views/painel/patients/common/form/form-patient-address";
import { dateFormatter } from "helpers/date-formatter";
import { errorMessage } from "helpers/toast";
import { UserSexEnum } from "domain/user/entities/enums/user-sex.enum";
import {
  FormPatientAdditionalData,
  PatientAdditionalDataFormValues,
} from "../common/form/form-patient-additional-data";
import { getCityByUf } from "helpers/cities-list";

const ListPatient: React.FC = observer(() => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState("0");
  const [isLoaded, setIsLoaded] = useState(false);
  const [patientData, setPatientData] = useState<PatientDataFormValues>({
    name: "",
    document_number: "",
    email: "",
    phone: "",
    birthdate: "",
    sex: UserSexEnum.NEUTRAL,
  });

  const [patientAddress, setPatientAddress] =
    useState<PatientAddressFormValues>({
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: null,
      zip_code: "",
    });

  const [patientAdditionalData, setPatientAdditionalData] =
    useState<PatientAdditionalDataFormValues>({
      profession: "",
      father_name: "",
      mother_name: "",
      spouse: "",
      patient_insurance_number: "",
      health_plan: null,
      observation: "",
    });

  const onSubmitData = (values: PatientDataFormValues) => {
    setActiveTab("1");
  };

  const onSubmitAddress = async (values: PatientAddressFormValues) => {
    setActiveTab("2");
  };

  const onSubmitAdditionalData = (
    values: PatientAdditionalDataFormValues
  ) => {};

  const fetchPatient = async (id: string) => {
    try {
      const response = await actionFetchPatient(id);
      setPatientData({
        id: response.id?.toString(),
        name: response.name,
        document_number:
          response.document_number === null ? "" : response.document_number,
        email: response.email,
        phone: response.phone,
        birthdate: response.birthdate
          ? dateFormatter(response.birthdate, "yyyy-MM-dd")
          : "",
        sex: response.sex ?? UserSexEnum.NEUTRAL,
      });

      setPatientAddress({
        street: response.address?.street ?? "",
        number: response.address?.number ?? "",
        complement: response.address?.complement ?? "",
        neighborhood: response.address?.neighborhood ?? "",
        city: response.address?.city ?? "",
        state: response.address?.state
          ? getCityByUf(response.address?.state) ?? null
          : null,
        zip_code: response.address?.zip_code ?? "",
      });

      setPatientAdditionalData({
        profession: response.profession ?? "",
        father_name: response.father_name ?? "",
        mother_name: response.mother_name ?? "",
        spouse: response.spouse ?? "",
        patient_insurance_number: response.patient_insurance_number ?? "",
        health_plan: response.health_plan?.id
          ? {
              value: response.health_plan?.id?.toString(),
              label: response.health_plan.name,
            }
          : null,
        observation: response.observation ?? "",
      });

      setIsLoaded(true);
    } catch (err) {
      navigate("/painel/pacientes");
    }
  };
  const tabs = useMemo(() => {
    return [
      {
        label: "Dados pessoais",
        disabled: false,
        element: (
          <div className="mt-4">
            <FormPatientData
              patientData={patientData}
              onSubmit={onSubmitData}
              onBack={() => navigate("/painel/pacientes")}
              isReadOnly={true}
            />
          </div>
        ),
      },
      {
        label: "Dados de endereço",
        disabled: false,
        element: (
          <div className="mt-4">
            <FormPatientAddress
              patientAddress={patientAddress}
              onSubmit={onSubmitAddress}
              onBack={() => setActiveTab("0")}
              isReadOnly={true}
            />
          </div>
        ),
      },
      {
        label: "Dados complementares",
        disabled: false,
        element: (
          <div className="mt-4">
            <FormPatientAdditionalData
              data={patientAdditionalData}
              onSubmit={onSubmitAdditionalData}
              onBack={() => setActiveTab("1")}
              isReadOnly={true}
            />
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData, patientAddress]);
  useEffect(() => {
    (async () => {
      if (id) {
        await fetchPatient(id);
      } else {
        errorMessage("Falha ao obter paciente");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="main-wrapper">
        {isLoaded && (
          <CustomTab
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </div>
    </>
  );
});
export default ListPatient;
