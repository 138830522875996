import React, { useState } from "react";
import { Button, Row, Col, Form } from "reactstrap";

import "./forgot-password.style.css";
import OTPInput from "react-otp-input";
import OTPResendButton from "./otp-resend-button";

interface ValidateOtpFormProps {
  prefix: string;
  email: string;
  onResendCode: () => void;
  onValidateOtp: (otp: string) => void;
}

const ValidateOtpForm: React.FC<ValidateOtpFormProps> = ({
  prefix,
  email,
  onResendCode,
  onValidateOtp,
}: ValidateOtpFormProps) => {
  const [otp, setOtp] = useState("");

  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    await onValidateOtp(otp);
  };

  return (
    <>
      <Row className="d-flex col-12 col-xl-7 d-flex align-items-center row flex-column">
        <img
          className="mx-auto mb-5 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          md="8"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <h3 className="text-center text-primary">Recuperar senha</h3>
          <p className="px-0">
            Informe o código de 6 dígitos enviado para seu e-mail:  <i>{email}</i>
          </p>
          <Form className="px-0" onSubmit={onSubmit}>
            <div className="input-group d-flex ">
              <div className="text-prefix-otp">{prefix}</div>{" "}
              <div>
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus
                  inputStyle={{
                    width: "40px",
                    height: "40px",
                    margin: "5px",
                    fontSize: "20px",
                    borderRadius: "4px",
                    border: "1px solid rgba(0,0,0,0.3)",
                    textAlign: "center",
                  }}
                />
              </div>
            </div>
            <Button
              className="my-4 form-control font-weight-600"
              color="primary"
              type="submit"
            >
              Continuar
            </Button>
          </Form>
          <OTPResendButton onSubmit={onResendCode} />
        </Col>
      </Row>
    </>
  );
};

export default ValidateOtpForm;
