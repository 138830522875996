import { Button, FormGroup, Label, Col, Row, Input } from "reactstrap";
import { Field, Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";

import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import {
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";

import { SelectDoctorForm } from "components/form/input/select-doctor-form";

import { dateFormatter } from "helpers/date-formatter";
import { SelectScheduleTypeForm } from "components/form/input/select-schedule-type-form";
import { SelectPatientForm } from "components/form/input/select-patient-form";
import React, { useState } from "react";
import { MakeScheduleProps } from "services/schedule-service";
import { isPhoneValid } from "helpers/phone-validator";
import {
  ScheduleOriginContactEnum,
  ScheduleOriginContactLabels,
} from "domain/schedule/enums/schedule-origin-contact.enum";
import { SelectScheduleOriginContactForm } from "components/form/input/select-origin-of-contact-form";
import { CustomInputMoney } from "components/form/input/custom-input-money";
import { maskedToCurrency } from "helpers/format-money";
import { validateCPF } from "validators/validate-cpf";
import { SelectScheduleTypeIdForm } from "components/form/input/select-schedule-type-id-form";
import { ScheduleBlockedStatusEnum } from "domain/schedule/enums/schedule-blocked-status.enum";
import { SelectDoctorStateProps } from "components/form/input/select-doctor-state-form";

export interface MakeScheduleDataProps {
  id?: number;
  type: ScheduleTypeEnum | null;
  start: Date;
  end: Date;
  observation: string;
  origin_contact: ScheduleOriginContactEnum | null;
  doctor: {
    id?: number;
    name: string;
  };
  patient: {
    id?: number;
    name: string;
    phone: string;
  };
  schedule_type: {
    id: number;
    name: string;
  } | null;
  amount: string;
  is_schedule_blocked?: ScheduleBlockedStatusEnum;
}

export interface MakeScheduleFormValues {
  type:
    | {
        value: ScheduleTypeEnum;
        label: string;
      }
    | string;
  schedule_type:
    | {
        value: number;
        label: string;
      }
    | string;
  date_selected: string;
  start: string;
  end: string;
  phone: string;
  observation: string;
  origin_contact: {
    value: ScheduleOriginContactEnum;
    label: string;
  } | null;
  doctor: {
    value?: number;
    label: string;
  };
  patient: {
    value?: number;
    label: string;
  };
  amount: string;
}

export interface PatientSelectableProps {
  value: number;
  label: string;
}

export interface MakeScheduleContainerProps {
  onCancel: () => void;
  onSubmit: (input: MakeScheduleProps) => Promise<void>;
  appointmentData: MakeScheduleDataProps;
  doctorId?: number;
  onSubmitCreateAppointment?: (input: any) => void;
}

export const MakeScheduleContainer: React.FC<MakeScheduleContainerProps> = ({
  onCancel,
  onSubmit,
  appointmentData,
  doctorId,
  onSubmitCreateAppointment,
}: MakeScheduleContainerProps) => {
  // const [isPhoneDisabled, setIsPhoneDisabled] = useState<boolean>(true);
  const [defaultPhone, setDefaultPhone] = useState<string>("");
  const [disableDocumentNumber, setDisableDocumentNumber] =
    useState<boolean>(false);

  const [isScheduleBlocked, setIsScheduleBlocked] = useState<boolean>(
    !!appointmentData?.is_schedule_blocked
  );

  const [isReadOnly, setIsReadOnly] = useState<boolean | null>(
    appointmentData?.patient?.id ? !!appointmentData?.patient?.id : null
  );

  const isUpdateLegacyType = () =>
    Boolean(
      appointmentData.id &&
        appointmentData.type !== null &&
        appointmentData.type !== undefined &&
        appointmentData.schedule_type === null
    );

  const validationSchema = Yup.object().shape({
    phone: Yup.string().test("phone", "Telefone inválido", (value) => {
      if (isScheduleBlocked) return true;
      return isPhoneValid(value ?? "");
    }),
    start: Yup.string().required("Hora de início é obrigatória").length(5),
    end: Yup.string()
      .length(5)
      .required("Hora de término é obrigatória")
      .test(
        "is-greater",
        "A hora de início deve ser menor que a hora de término",
        function (value) {
          const { start } = this.parent;
          return start < value;
        }
      ),
    type: Yup.object().test(
      "custom-validation-type",
      "Tipo de agendamento é obrigatório",
      function (value: any) {
        if (isScheduleBlocked) return true;
        if (!isUpdateLegacyType()) return true;
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    schedule_type: Yup.object().test(
      "custom-validation-type",
      "Tipo de agendamento é obrigatório",
      function (value: any) {
        if (isScheduleBlocked) return true;
        if (isUpdateLegacyType()) return true;
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    patient: Yup.object().test(
      "custom-validation-patient",
      "Paciente é obrigatório",
      function (value: any) {
        if (isScheduleBlocked) return true;
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    doctor: Yup.object().test(
      "custom-validation-doctor",
      "Médico é obrigatório",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    document_number: Yup.string().test(
      "custom-validation",
      "CPF inválido",
      function (value: any) {
        if (isScheduleBlocked) return true;
        return isReadOnly || disableDocumentNumber || validateCPF(value ?? "");
      }
    ),
  });

  let dateSelected = "";
  let startTime = "";
  let endTime = "";
  let doctor;
  let patient;

  dateSelected = dateFormatter(appointmentData?.start, "yyyy-MM-dd");
  startTime = dateFormatter(appointmentData?.start, "hh:mm");
  endTime = dateFormatter(appointmentData?.end, "hh:mm");

  doctor = {
    value: appointmentData.doctor.id,
    label: appointmentData.doctor.name,
  };

  patient = {
    value: appointmentData.patient.id,
    label: appointmentData.patient.name,
  };

  const origin_contact = appointmentData.origin_contact
    ? {
        value: appointmentData.origin_contact,
        label: ScheduleOriginContactLabels(appointmentData.origin_contact),
      }
    : null;

  const [selectedDoctor, setSelectedDoctor] =
    useState<SelectDoctorStateProps | null>(
      appointmentData.doctor?.id ? (doctor as SelectDoctorStateProps) : null
    );

  const initialValues: MakeScheduleFormValues = {
    type: isUpdateLegacyType()
      ? {
          value: appointmentData.type as ScheduleTypeEnum,
          label: ScheduleTypeLabels(appointmentData.type as ScheduleTypeEnum),
        }
      : "",
    date_selected: dateSelected,
    start: startTime,
    end: endTime,
    phone: appointmentData.patient.phone,
    observation: appointmentData.observation,
    origin_contact: origin_contact,
    doctor,
    patient,
    schedule_type:
      !isUpdateLegacyType() && appointmentData.schedule_type
        ? {
            value: appointmentData.schedule_type.id,
            label: appointmentData.schedule_type.name,
          }
        : "",
    amount: appointmentData.amount,
  };

  const handleSubmit = async (values: any) => {
    const input = {
      doctor_id: values.doctor.value,
      patient_id:
        typeof values.patient?.value === "number"
          ? values.patient?.value
          : null,
      patient_name: isScheduleBlocked ? "" : values.patient.label,
      patient_phone: values.phone?.replace(/[^0-9]/g, ""),
      patient_document: values?.document_number?.replace(/[^0-9]/g, "") || null,
      is_phone_changed: values.phone?.replace(/[^0-9]/g, "") !== defaultPhone,
      status: isScheduleBlocked ? null : ScheduleStatusEnum.SCHEDULED,
      start: `${values.date_selected}T${values.start}:00`,
      end: `${values.date_selected}T${values.end}:00`,
      type: values.type?.value ?? null,
      observation: values.observation,
      origin_contact: values.origin_contact?.value ?? null,
      amount: Number(maskedToCurrency(values.amount) ?? 0),
      schedule_type_id: values.schedule_type?.value ?? null,
      is_schedule_blocked: isScheduleBlocked
        ? ScheduleBlockedStatusEnum.BLOCKED
        : ScheduleBlockedStatusEnum.NOT_BLOCKED,
    };

    await onSubmit(input);
    onSubmitCreateAppointment && (await onSubmitCreateAppointment(input));
  };

  const DocumentNumberCheckboxForm: React.FC = () => {
    const handleOnChangeDocumentNumberCheckbox = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setDisableDocumentNumber(e.target.checked);
    };

    return (
      <FormGroup check>
        <Input
          id="disable_document_number"
          type="checkbox"
          name="disable_document_number"
          checked={disableDocumentNumber}
          onChange={handleOnChangeDocumentNumberCheckbox}
        />{" "}
        <Label htmlFor="disable_document_number" check>
          Paciente não possui CPF
        </Label>
      </FormGroup>
    );
  };

  const ScheduleBlockedCheckboxForm: React.FC = () => {
    const onChangeScheduleBlocked = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setIsScheduleBlocked(e.target.checked);
    };

    return (
      <FormGroup className="col-12  ">
        <Label htmlFor="birthdate">Bloquear Agenda?</Label>
        <br />
        <FormGroup check>
          <Input
            id="is_schedule_blocked"
            type="checkbox"
            name="is_schedule_blocked"
            checked={isScheduleBlocked}
            onChange={onChangeScheduleBlocked}
          />{" "}
          <Label htmlFor="is_schedule_blocked" className="fs-14" check>
            Bloquear Agenda
          </Label>
        </FormGroup>
      </FormGroup>
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="px-0">
            <span className="title-section">Dados do agendamento</span>

            <Row className="d-flex col-12 mx-0 mt-2">
              <ScheduleBlockedCheckboxForm />
            </Row>
            <Row className="d-flex col-12 mx-0 mt-0">
              <SelectDoctorForm />
            </Row>

            {!isScheduleBlocked && (
              <Row className="d-flex col-12 mx-0 mt-2">
                {isUpdateLegacyType() ? (
                  <SelectScheduleTypeForm />
                ) : (
                  <>
                    <SelectScheduleTypeIdForm />
                  </>
                )}
              </Row>
            )}

            {!isScheduleBlocked && (
              <Row className="d-flex col-12 mx-0 px-0 ">
                <FormGroup className="col-12 px-0">
                  <Label htmlFor="amount">Valor do atendimento</Label>
                  <Field
                    name="amount"
                    id="amount"
                    placeholder="R$ 0,00"
                    type="text"
                    component={CustomInputMoney}
                  />
                </FormGroup>
              </Row>
            )}
            <Row className="d-flex col-12 mx-0 mb-3">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="date_selected">Data do agendamento</Label>
                <Field
                  name="date_selected"
                  id="date_selected"
                  placeholder="Data"
                  type="date"
                  component={CustomFormInput}
                />
              </FormGroup>

              <FormGroup className="col-6 col-md-3 ">
                <Label htmlFor="start">Início</Label>
                <Field
                  name="start"
                  id="start"
                  placeholder="Data"
                  type="text"
                  mask="00:00"
                  component={CustomMaskInput}
                />
              </FormGroup>

              <FormGroup className="col-6 col-md-3 ">
                <Label htmlFor="end">Fim</Label>
                <Field
                  name="end"
                  id="end"
                  placeholder="Fim"
                  type="text"
                  mask="00:00"
                  component={CustomMaskInput}
                />
              </FormGroup>
            </Row>

            {!isScheduleBlocked && (
              <>
                <span className="title-section">Dados do paciente</span>

                <Row className="d-flex col-12 mx-0 mt-2">
                  <SelectPatientForm
                    onSetIsReadonly={setIsReadOnly}
                    onSetDefaultPhoneValue={setDefaultPhone}
                  />
                </Row>
              </>
            )}

            {!isScheduleBlocked && (
              <>
                {isReadOnly === false && (
                  <Row className="d-flex col-12 mx-0 ">
                    <FormGroup className="col-12 px-0">
                      <Label htmlFor="document_number">CPF do paciente</Label>
                      <Field
                        name="document_number"
                        id="document_number"
                        placeholder={`${
                          disableDocumentNumber
                            ? "Paciente sem CPF"
                            : "CPF do paciente"
                        } `}
                        disabled={disableDocumentNumber}
                        type="text"
                        mask="000.000.000-00"
                        component={CustomMaskInput}
                      />
                      <DocumentNumberCheckboxForm />
                    </FormGroup>
                  </Row>
                )}
              </>
            )}

            {!isScheduleBlocked && (
              <Row className="d-flex col-12 mx-0 ">
                <FormGroup className="col-12 px-0">
                  <Label htmlFor="phone">Telefone</Label>
                  <Field
                    name="phone"
                    id="phone"
                    label="Telefone"
                    placeholder="(00) 00000-0000"
                    type="text"
                    mask="(00) 00000-0000"
                    // disabled={isPhoneDisabled}
                    component={CustomMaskInput}
                  />
                </FormGroup>
              </Row>
            )}

            {!isScheduleBlocked && (
              <Row className="d-flex col-12 mx-0 ">
                <SelectScheduleOriginContactForm />
              </Row>
            )}
            <Row className="d-flex col-12 mx-0 ">
              <FormGroup className="col-12 px-0">
                <Label htmlFor="observation">Observação</Label>
                <Field
                  name="observation"
                  id="observation"
                  type="textarea"
                  style={{ height: "5rem" }}
                  component={CustomFormInput}
                />
              </FormGroup>
            </Row>
            <Col className="d-flex justify-content-end flex-wrap">
              <Button className="mx-2" onClick={onCancel}>
                Cancelar
              </Button>
              <Button
                color="primary"
                className=" font-weight-400"
                type="submit"
              >
                Confirmar
              </Button>
            </Col>
          </Form>
        )}
      </Formik>
    </>
  );
};
