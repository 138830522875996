import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { PaginationMeta } from "components/table/custom-table";
import { errorMessage, successMessage } from "helpers/toast";
import { errorHandler } from "helpers/http-helper";
import { httpClient } from "config/http-client";
import { UserSexEnum } from 'domain/user/entities/enums/user-sex.enum';

export interface ListPatientsItems {
  id: string;
  name: string;
  birthdate: string | null;
  phone: string;
}

export interface ListPatientsResponse {
  items: ListPatientsItems[];
  meta: PaginationMeta;
}

export interface ListPatientsProps {
  document_number?: string;
  name?: string;
  phone?: string;
  limit: number;
  page: number;
}

interface PatientHealthPlan {
  id: string;
  name: string;
}
export interface ListPatientResponse {
  id: number;
  name: string;
  phone: string;
  status: UserStatusEnum;
  document_number: string;
  email: string;
  birthdate: Date | null;
  sex: UserSexEnum;
  address: {
    street: string;
    number: string;
    neighborhood: string;
    complement: string;
    city: string;
    state: string;
    zip_code: string;
  };
  created_at: Date;
  updated_at: Date;
  first_appointment: Date | null;
  last_appointment: Date | null;
  profession: string;
  father_name: string;
  mother_name: string;
  spouse: string;
  patient_insurance_number: string;
  health_plan: PatientHealthPlan | null;
  observation: string;
}

export interface CreatePatientProps {
  name: string;
  document_number: string | null;
  email: string;
  phone: string;
  birthdate: string | null;
  sex: UserSexEnum;
  address: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    zip_code: string;
  };
  profession: string | null;
  father_name: string | null;
  mother_name: string | null;
  spouse: string | null;
  patient_insurance_number: string | null;
  health_plan_id: number | null;
  observation: string | null;
}

export interface UpdatePatientProps extends CreatePatientProps {}

export interface CreatePatientResponse {
  id: number;
}
export class PatientService {
  static async fetchAllPatients(
    input: ListPatientsProps
  ): Promise<ListPatientsResponse> {
    try {
      const result = await httpClient.request({
        url: "/patients",
        method: "get",
        params: input,
      });

      return result.data as ListPatientsResponse;
    } catch (err) {
      errorMessage("Falha ao listar pacientes");
      return {} as ListPatientsResponse;
    }
  }

  static async fetchPatient(id: string): Promise<ListPatientResponse> {
    try {
      const result = await httpClient.request({
        url: `/patients/${id}`,
        method: "get",
      });

      return result.data as ListPatientResponse;
    } catch (err) {
      errorHandler(err, "Falha ao buscar paciente");
      throw new Error("Falha ao buscar paciente");
    }
  }

  static async createPatient(
    input: CreatePatientProps
  ): Promise<CreatePatientResponse> {
    try {
      const result = await httpClient.request({
        url: "/patients",
        method: "post",
        data: input,
      });

      successMessage("Paciente criado com sucesso");
      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao criar paciente");
      throw new Error("Falha ao criar paciente");
    }
  }

  static async updatePatinet(
    id: string,
    input: UpdatePatientProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: `/patients/${id}`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar paciente");
      throw new Error("Falha ao atualizar paciente");
    }
  }
}
