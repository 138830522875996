export const CalendarCustomToolbar = ({
  label,
  onNavigate,
  onView,
  view,
}: any) => {
  return (
    <div className="rbc-toolbar d-flex col-12 justify-content-between ">
      <div className="rbc-btn-group col-12 col-xl-4 text-center text-lg-start">
        <button onClick={() => onNavigate("PREV")}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <button onClick={() => onNavigate("TODAY")}>Hoje</button>
        <button onClick={() => onNavigate("NEXT")}>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>

      <span className="rbc-toolbar-label col-12 col-xl-4 my-2 my-lg-0">{label}</span>

      <div className="rbc-btn-group col-12  col-xl-4 text-center text-lg-end">
        <button
          onClick={() => onView("week")}
          className={view === "week" ? "rbc-active" : ""}
        >
          Semana
        </button>
        <button
          onClick={() => onView("day")}
          className={view === "day" ? "rbc-active" : ""}
        >
          Dia
        </button>
        <button
          onClick={() => onView("agenda")}
          className={view === "agenda" ? "rbc-active" : ""}
        >
          Calendário
        </button>
      </div>
    </div>
  );
};
