import { CustomTable, PaginationMeta } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { ListEstimatedReturnAppointmentItemTable } from "./estimated-returns.container";

export interface EstimatedReturnTableProps {
  estimatedReturns: ListEstimatedReturnAppointmentItemTable[] | null;
  meta: PaginationMeta | null;
  onSelectSchedule: (scheduleId: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const EstimatedReturnTable = ({
  estimatedReturns,
  meta,
  onSelectSchedule,
  onChangePage,
  tableType,
  setTableType,
}: EstimatedReturnTableProps) => {
  const columnNames = {
    patient_name: "Nome Paciente",
    doctor_name: "Nome Profissional",
    current_appointment_date: "Data atendimento",
    estimated_return_date: "Previsão de retorno",
  };

  return (
    <>
      <CustomTable
        columnNames={columnNames}
        data={estimatedReturns}
        onSelect={onSelectSchedule}
        tableType={tableType}
        setTableType={setTableType}
        btnLabelNotFound=""
        btnClickNotFound={() => {}}
      />
      <CustomPagination
        {...Object.assign({}, meta)}
        onChangePage={onChangePage}
      />{" "}
    </>
  );
};
