import { makeAutoObservable } from "mobx";

class FinancialStatementStore {
  shouldReload: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShouldReload(shouldReload: boolean): void {
    this.shouldReload = shouldReload;
  }

  getShouldReload(): boolean {
    return this.shouldReload;
  }
}

const financialStatementStore = new FinancialStatementStore();

export default financialStatementStore;
