import { useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import { ListAdminDoctorsTable } from "views/painel/employees/index/list-admin-doctors/list-admin-doctors-table";
import { actionFetchAllAdminDoctors } from "actions/users/admin-doctor/action-fetchall-admin-doctors";
import { dateFormatter } from "helpers/date-formatter";
import { PaginationMeta } from "components/table/custom-table";
import { getTableType } from "helpers/screen";

export interface ListDoctorItemTable {
  id: string;
  document_number: any;
  name: any;
  created_at: any;
  actions: any;
}

export const AdminDoctorsContainer: React.FC = () => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();

  const [adminDoctors, setAdminDoctors] = useState<ListDoctorItemTable[]>([]);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [inputSearch, setInputSearch] = useState("");
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());

  const redirectToEditDoctor = (id: string) => {
    navigate(`/painel/medico-administrador/editar/${id}`);
  };

  const redirectToViewDoctor = (id: string) => {
    navigate(`/painel/medico-administrador/${id}`);
  };

  const doctorItemActions = (id: string) => {
    return (
      <>
        <div className="d-flex flex-wrap">
          <div
            className="mx-2"
            onClick={() => redirectToEditDoctor(id)}
            id={`btn-edit-${id}`}
          >
            <i className="fas fa-pen-to-square text-warning fa-lg"></i>
          </div>
          <div className="mx-2" onClick={() => redirectToViewDoctor(id)}>
            <i className="fa-solid fa-eye text-primary "></i>
          </div>
        </div>
      </>
    );
  };
  const doctorItemDocumentNumber = (documentNumber: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToViewDoctor(id)}>
        <span>{documentNumber}</span>
      </div>
    );
  };

  const doctorItemName = (name: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToViewDoctor(id)}>
        <span>{name}</span>
      </div>
    );
  };

  const doctorCreatedAt = (createdAt: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToViewDoctor(id)}>
        <span>{dateFormatter(new Date(createdAt), "dd/MM/yyyy hh:mm")}</span>
      </div>
    );
  };

  const fetchAdminDoctors = async (page?: number, input?: string) => {
    const result = await actionFetchAllAdminDoctors({
      limit: PAGE_SIZE,
      page: page || currentPage,
      name: input || inputSearch,
    });

    const items = result.items.map((item) => ({
      id: item.id,
      document_number: doctorItemDocumentNumber(item.document_number, item.id),
      name: doctorItemName(item.name, item.id),
      created_at: doctorCreatedAt(item.created_at, item.id),
      actions: doctorItemActions(item.id),
    }));

    setAdminDoctors(items);
    setMeta(result.meta);
  };
  useEffect(() => {
    (async () => {
      await fetchAdminDoctors();
    })();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
    await fetchAdminDoctors(1, inputSearch);
  };

  const onSelectDoctor = (_doctorId: string) => {};

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchAdminDoctors(page);
  };

  return (
    <div className="main-wrapper">
      <Row className="d-flex justify-content-between mt-2 mb-3">
        <Form
          className="d-flex col-12 col-md-12 col-xl-5 align-self-center"
          onSubmit={onSubmit}
        >
          <InputGroup>
            <Input
              type="text"
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              className="border-right-0"
              placeholder="Buscar por nome"
            />
            <InputGroupText className="bg-white">
              <button type="submit" className="border-0 bg-white">
                <i
                  className="fa fa-search text-primary fs-20"
                  aria-hidden="true"
                ></i>
              </button>
            </InputGroupText>
          </InputGroup>
        </Form>

        <div className="d-flex col-12 col-xl-3 my-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => navigate("/painel/medico-administrador/criar")}
          >
            + Criar Médico Gestor
          </Button>
        </div>
      </Row>

      <ListAdminDoctorsTable
        adminDoctors={adminDoctors}
        meta={meta}
        onSelectDoctor={onSelectDoctor}
        onChangePage={onChangePage}
        tableType={tableType}
        setTableType={setTableType}
      />
    </div>
  );
};
