import { useNavigate } from "react-router-dom";

import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import {
  AdminDoctorDataProps,
  FormAdminDoctorData,
} from "views/painel/employees/common/admin-doctor/form/form-admin-doctor-data";
import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";
import { successMessage } from "helpers/toast";
import { actionCreateAdminDoctor } from "actions/users/admin-doctor/action-create-admin-doctor";

export const CreateAdminDoctor: React.FC = () => {
  const navigate = useNavigate();

  const adminDoctorData: AdminDoctorDataProps = {
    email: "",
    name: "",
    document_number: "",
    phone: "",
    status: UserStatusEnum.ACTIVE,
    profession: ProfessionTypeEnum.DOCTOR,
    register_number: [
      {
        register_number: "",
        register_number_uf: "",
        is_selected: true,
      },
    ],
  };

  const onSubmitData = async (values: AdminDoctorDataProps) => {
    try {
      const input = {
        ...values,
        document_number:
          values?.document_number?.replace(/[^0-9]/g, "") ?? null,
        status: values.status,
        profession: values.profession,
        register_number: values.register_number,
      };
      await actionCreateAdminDoctor(input);
      successMessage("Médico criado com sucesso");
      navigate(`/painel/colaboradores/medicos-administradores`);
    } catch {}
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          <FormAdminDoctorData
            adminDoctorData={adminDoctorData}
            onSubmit={onSubmitData}
            onCancel={() => navigate(`/painel/colaboradores/medicos-administradores`)}
          />
        </div>
      </div>
    </>
  );
};
