import { Input, InputGroup, InputGroupText } from "reactstrap";
import { FieldProps, useFormikContext } from "formik";

import "components/form/custom-form.style.css";
import { IMaskInput } from "react-imask";

interface CustomMaskInputProps extends FieldProps {
  label: string;
  leftIconClass?: string;
}

export const CustomMaskInput: React.FC<CustomMaskInputProps> = ({
  field,
  form,
  label,
  leftIconClass,
  ...props
}) => {
  const { name } = field;
  const errorMessage = form.submitCount && form.errors[name];

  return (
    <>
      <InputGroup>
        {leftIconClass && (
          <InputGroupText
            className={`text-secondary bg-white ${
              !!errorMessage ? "is-invalid" : ""
            } }`}
          >
            <i className={leftIconClass}></i>
          </InputGroupText>
        )}
        <Input
          {...field}
          {...props}
          className={` ${leftIconClass ? "border-left-0" : ""}`}
          invalid={!!errorMessage}
          tag={IMaskInput}
          // onChange={onChange}
        />
      </InputGroup>
      {!!errorMessage && (
        <div className="custom-invalid-feedback">{errorMessage}</div>
      )}
    </>
  );
};
