import React from "react";
import { Row, Col } from "reactstrap";
import { ListPatientResponse } from "services/patient-service";
import {
  calculateAgeFromBirthdate,
  dateFormatter,
} from "helpers/date-formatter";
import { maskedPhoneNumber } from "helpers/phone-formatter";
import { documentMask } from "helpers/document-formatter";

interface CompletePatientDataProps {
  patientData: ListPatientResponse;
}

const CompletePatientData: React.FC<CompletePatientDataProps> = ({
  patientData,
}) => {
  return (
    <>
      {patientData && (
        <>
          <Row className="p-0">
            <Row className="mb-3">
              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Nome
                </Col>
                <Col sm="12" md="7">
                  {patientData.name}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Data de Nascimento
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(patientData.birthdate, "dd/MM/yyyy") ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Telefone
                </Col>
                <Col sm="12" md="7">
                  {maskedPhoneNumber(patientData.phone) ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Sexo
                </Col>
                <Col sm="12" md="7">
                  {patientData.sex ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  CPF
                </Col>
                <Col sm="12" md="7">
                  {documentMask(patientData.document_number || "") ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Email
                </Col>
                <Col sm="12" md="7">
                  {patientData.email ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Primeira Consulta
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(patientData.first_appointment) ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Última Consulta
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(patientData.last_appointment) ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Profissão
                </Col>
                <Col sm="12" md="7">
                  {patientData.profession ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Nome do Pai
                </Col>
                <Col sm="12" md="7">
                  {patientData.father_name ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Nome da Mãe
                </Col>
                <Col sm="12" md="7">
                  {patientData.mother_name ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Cônjuge
                </Col>
                <Col sm="12" md="7">
                  {patientData.spouse ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Número do Seguro do Paciente
                </Col>
                <Col sm="12" md="7">
                  {patientData.patient_insurance_number ?? "N/A" ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Plano de Saúde
                </Col>
                <Col sm="12" md="7">
                  {patientData.health_plan?.name ?? "N/A"}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Endereço
                </Col>
                <Col sm="12" md="7">
                  {`${patientData.address.street}, ${patientData.address.number}, ${patientData.address.neighborhood}, ${patientData.address.complement}, ${patientData.address.city}, ${patientData.address.state}, ${patientData.address.zip_code}`}
                </Col>
              </Row>

              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Observação
                </Col>
                <Col sm="12" md="7">
                  {patientData.observation ?? "N/A"}
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm="12" md="5" className="form-label font-weight-500">
                  Criado em
                </Col>
                <Col sm="12" md="7">
                  {dateFormatter(patientData.created_at)}
                </Col>
              </Row>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};

export default CompletePatientData;
