import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Media,
} from "reactstrap";

import "./painel-navbar.style.css";
import UserIdentity from "domain/user/entities/user-identity";
import { actionLogout } from "actions/identity/action-logout";

const PainelNavbar: React.FC = (props: any) => {
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        {props.brandProps.path ? (
          <>
            <Link
              className="breadcrumb-item d-lg-inline-block"
              to={props.brandProps.path}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
              {props.brandProps.name}
            </Link>
          </>
        ) : (
          <>
            <span className="breadcrumb-item">{props.brandProps.name}</span>
          </>
        )}

        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="d-flex align-items-center">
                <span className="mx-2">
                  <img
                    alt="..."
                    src={`https://ui-avatars.com/api/?name=${UserIdentity.getName()}&size=40&rounded=true&color=fff&background=1632D2`}
                  />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="text-user-name">
                    {UserIdentity.getName()}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu-arrow"
              end
              style={{ top: "50px" }}
            >
              <DropdownItem to="/painel/meus-dados" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Meus Dados</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                className="mt-4"
                href="/auth/login"
                onClick={(e) => actionLogout()}
              >
                <i className="ni ni-user-run" />
                <span>Sair</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </>
  );
};

export default PainelNavbar;
