import { dateFormatter } from "helpers/date-formatter";

interface MedicalRecordDoctorNameItemProps {
  doctor_name: string | null;
}
export const MedicalRecordDoctorNameItem: React.FC<
  MedicalRecordDoctorNameItemProps
> = ({ doctor_name }) => {
  return (
    <>
      <div className="d-flex row">
        <div className="medical-record-section-title fs-18">Profissional</div>
        <div className="medical-record-history-row">
          <div className="medical-record-history-title fs-15">
            {doctor_name}
          </div>
        </div>
      </div>
    </>
  );
};
