import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";
import { PaginationMeta } from "components/table/custom-table";
import { PaymentTypeEnum } from "domain/schedule/enums/payment-type.enum";

export interface ListFinancialStatementProps {
  start: Date;
  end: Date;
  doctor_id?: number;
  status?: string;
  payment_type?: string;
  health_plan?: string;
  limit: number;
  page: number;
}

export interface ListAllFinancialStatementResponse {
  items: ListAllFinancialStatementItem[];
  meta: PaginationMeta;
}

interface HealthPlanItem {
  id: number;
  name: string;
}
interface ScheduleTypeItem {
  id: number;
  name: string;
}

export interface ListAllFinancialStatementItem {
  id: number;
  status: number;
  start: string;
  type: number;
  amount: number;
  amount_paid: number;
  paid_at: string;
  payment_type: PaymentTypeEnum;
  origin_contact: string;
  patient_name: string;
  doctor_name: string;
  payment_observation: string;
  health_plan: HealthPlanItem | null;
  schedule_type: ScheduleTypeItem | null;
}

export class FinancialService {
  static async listFinancialStatement(
    input: ListFinancialStatementProps
  ): Promise<ListAllFinancialStatementResponse> {
    try {
      const result = await httpClient.request({
        url: "/financial/statement",
        method: "get",
        params: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao gerar extrato financeiro");
      throw new Error("Falha ao gerar extrato financeiro");
    }
  }
}
