import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListAdminDoctorsProps,
  ListAdminDoctorsResponse,
  UserService,
} from "services/user-service";

export const actionFetchAllAdminDoctors = action(
  async (input: ListAdminDoctorsProps): Promise<ListAdminDoctorsResponse> => {
    SetIsLoadingState(true);
    try {
      return UserService.fetchAllAdminDoctors(input);
    } catch (error) {
      return {
        items: [],
        meta: {},
      } as unknown as ListAdminDoctorsResponse;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
