import { Button, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { CustomFormInput } from "components/form/input/custom-form-input";
import {
  HealthPlanStatusEnum,
  HealthPlanStatusEnumLabels,
} from "domain/health-plans/entities/enums/health-plan-status.enum";
import { SelectHealthPlanStatusForm } from "./select-health-plan-status-form";

export interface FormHealthPlanDataProps {
  data: HealthPlanProps | null;
  onSubmit: (data: HealthPlanProps) => void;
  onCancel: () => void;
}

export interface HealthPlanProps {
  id?: number;
  code: string;
  name: string;
  status: HealthPlanStatusEnum;
}

export interface HealthPlanFormValues {
  id?: number;
  code: string;
  name: string;
  status?: {
    value: HealthPlanStatusEnum;
    label: string;
  };
}

export const FormHealthPlan: React.FC<FormHealthPlanDataProps> = ({
  data,
  onSubmit,
  onCancel,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    status: Yup.object().test(
      "custom-validation-status",
      "Status inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
  });

  const initialValues: HealthPlanFormValues = {
    id: data?.id,
    code: data?.code ?? "",
    name: data?.name ?? "",

    status: {
      value: data?.status ?? HealthPlanStatusEnum.ACTIVE,
      label: HealthPlanStatusEnumLabels(
        data?.status ?? HealthPlanStatusEnum.ACTIVE
      ),
    },
  };

  const handleSubmit = async (values: HealthPlanFormValues) => {
    return await onSubmit({
      ...values,
      status: values.status?.value ?? HealthPlanStatusEnum.ACTIVE,
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <FormGroup className="col-12 ">
              <Label htmlFor="code">Código</Label>
              <Field
                name="code"
                id="code"
                placeholder="Código do plano de saúde"
                type="text"
                component={CustomFormInput}
              />
            </FormGroup>
            <FormGroup className="col-12 ">
              <Label htmlFor="name">Nome</Label>
              <Field
                name="name"
                id="name"
                placeholder="Nome do plano de saúde"
                type="text"
                component={CustomFormInput}
              />
            </FormGroup>

            <FormGroup className="col-12">
              <SelectHealthPlanStatusForm keyName="status" />
            </FormGroup>
            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <div className="col-12 col-md-3 ">
                <Button
                  color="secondary"
                  className="form-control"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-12 col-md-3 ">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
