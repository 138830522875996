import { Row, Table } from "reactstrap";

import "./custom-table.style.css";
import { CustomTableNotFound } from "./custom-table-not-found";
import { getTableType } from "helpers/screen";

export interface CustomTableProps {
  columnNames: Record<string, string>;
  data: Record<string, any>[] | null;
  onSelect: (id: string) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
  btnLabelNotFound: string;
  btnClickNotFound: () => void;
}

export interface PaginationMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export const CustomTable = ({
  columnNames,
  data,
  onSelect,
  tableType,
  setTableType,
  btnLabelNotFound,
  btnClickNotFound
}: CustomTableProps) => {
  const isTableRow = () => tableType === "table";
  const isEmpty = () => data?.length === undefined || data.length === 0;
  const ToggleComponent: React.FC = () => {
    return (
      <>
        {setTableType !== undefined ? (
          <div className="d-flex justify-content-end">
            <div className="custom-table-toggle d-md-block flex-wrap">
              <span className="flex-wrap m-0">
                <div
                  className={`table-toggle-item ${
                    isTableRow() ? "table-toggle-active" : ""
                  }`}
                  onClick={() => setTableType("table")}
                >
                  <i
                    className={`fa-solid fa-list  ${
                      isTableRow() ? "text-white" : " text-primary"
                    }`}
                  ></i>
                </div>
                <div
                  className={`table-toggle-item ${
                    !isTableRow() ? "table-toggle-active" : " text-primary"
                  }`}
                  onClick={() => setTableType("card")}
                >
                  <i
                    className={`fa-solid fa-border-all  ${
                      !isTableRow() ? "text-white" : ""
                    }`}
                    onClick={() => setTableType("card")}
                  ></i>
                </div>
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const TableComponentRow: React.FC = () => {
    return (
      <>
        <div className="custom-table">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                {Object.keys(columnNames).map((key) => (
                  <th key={key}>{columnNames[key]}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} onClick={() => onSelect(item.id)}>
                  {Object.keys(columnNames).map((key) => (
                    <td key={index + key}>{item[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  const TableComponentCard: React.FC = () => {
    return (
      <>
        <div className="d-flex col-12 flex-wrap">
          {data?.map((item, index) => (
            <div
              className="d-flex col-12 col-md-6 col-lg-3 p-2"
              key={index}
              onClick={() => onSelect(item.id)}
            >
              <div className="custom-table-card-item">
                {Object.keys(columnNames).map((key) => (
                  <div className="table-card-row">
                    <div className="table-card-title">{columnNames[key]}</div>
                    <div className="table-card-value">{item[key]}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  return (
    <>
      {isEmpty() ? (
        <CustomTableNotFound buttonName={btnLabelNotFound} onClick={btnClickNotFound}/>
      ) : (
        <>
          <ToggleComponent />
          {isTableRow() ? <TableComponentRow /> : <TableComponentCard />}
        </>
      )}
    </>
  );
};
