import { Button, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { CustomPasswordInput } from "components/form/input/custom-password-input";
import { isPasswordValid } from "helpers/password-validator";
import { actionUpdatePassword } from "actions/me/action-update-password";
import { successMessage } from "helpers/toast";
import { useNavigate } from "react-router-dom";

interface UpdatePasswordFormValues {
  current_password: string;
  new_password: string;
  confirm_password: string;
}
export const UpdatePasswordContainer = () => {
  const navigate = useNavigate();
  const initialValues: UpdatePasswordFormValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().test(
      "custom-validation-current-password",
      "Senha fraca",
      function (value) {
        return isPasswordValid(value ?? "");
      }
    ),
    new_password: Yup.string().test(
      "custom-validation",
      "Senha fraca",
      function (value) {
        return isPasswordValid(value ?? "");
      }
    ),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("new_password"), undefined],
        "As senhas precisam ser iguais"
      )
      .min(8, "Senha deve ter no mínimo 8 caracteres")
      .required("Confirmação de senha é obrigatória"),
  });

  const onSubmit = async (input: UpdatePasswordFormValues): Promise<void> => {
    try {
      await actionUpdatePassword(input);
      successMessage("Senha alterada com sucesso");
      navigate("/painel");
    } catch {}
  };

  return (
    <div className="main-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <Row>
              <FormGroup className="col-12 col-xl-6">
                <Label htmlFor="password">Senha Atual</Label>
                <Field
                  name="current_password"
                  id="current_password"
                  label="Senha"
                  placeholder="Digite senha atual "
                  leftIconClass="fas fa-lock text-primary"
                  component={CustomPasswordInput}
                />
              </FormGroup>
            </Row>
            <Row className="col-12 mx-3">
              <ul className="fs-14">
                <li>No mínimo 8 caracteres</li>
                <li>Pelo menos 1 caractere maiúsculo</li>
                <li>Pelo menos 1 caractere minúsculo</li>
                <li>Pelo menos 1 número</li>
              </ul>
            </Row>
            <Row className="mt-2">
              <FormGroup className="col-12 col-xl-6">
                <Label htmlFor="password">Nova Senha</Label>
                <Field
                  name="new_password"
                  id="new_password"
                  label="Senha"
                  placeholder="Digite nova senha"
                  leftIconClass="fas fa-lock text-primary"
                  component={CustomPasswordInput}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-12 col-xl-6">
                <Label htmlFor="confirm_password">Confirmar Senha</Label>
                <Field
                  name="confirm_password"
                  id="confirm_password"
                  label="Confirmar Senha"
                  placeholder="Confirmar senha"
                  leftIconClass="fas fa-lock text-primary"
                  component={CustomPasswordInput}
                />
              </FormGroup>
            </Row>
            <Row>
              <div className="col-12 col-xl-6">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
