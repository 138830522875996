import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListAllEstimatedReturnAppointmentsResponse,
  ListAllEstimatedReturnnAppointmentProps,
  ScheduleService,
} from "services/schedule-service";

export const actionListAllEstimatedReturnAppointments = action(
  async (
    input: ListAllEstimatedReturnnAppointmentProps
  ): Promise<ListAllEstimatedReturnAppointmentsResponse> => {
    SetIsLoadingState(true);
    try {
      return await ScheduleService.listAllEstimatedReturnAppointments(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
