import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";
import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import { errorHandler } from "helpers/http-helper";
import { httpClient } from "config/http-client";
import { successMessage } from "helpers/toast";

export interface ListSuperadminResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: Date;
  updated_at: Date;
  status: UserStatusEnum;
  role: UserRoleEnum;
}

export interface ListClinicManagerResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: Date;
  updated_at: Date;
  status: UserStatusEnum;
  role: UserRoleEnum;
}

export interface ListAdminResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: Date;
  updated_at: Date;
  status: UserStatusEnum;
  role: UserRoleEnum;
}

export interface ListDoctorResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: Date;
  updated_at: Date;
  status: UserStatusEnum;
  role: UserRoleEnum;
  register_number: [
    {
      id?: number
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}

export interface ListOwnerDoctorResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: Date;
  updated_at: Date;
  status: UserStatusEnum;
  role: UserRoleEnum;
  register_number: [
    {
      id?: number
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}

export interface ListAdminDoctorResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: Date;
  updated_at: Date;
  status: UserStatusEnum;
  role: UserRoleEnum;
  register_number: [
    {
      id?: number
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
  profession: ProfessionTypeEnum;
}

export interface ListReceptionistResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  document_number: string;
  created_at: Date;
  updated_at: Date;
  status: UserStatusEnum;
  role: UserRoleEnum;
}

export interface UpdatePasswordProps {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

export interface UpdateDataProps {
  name: string;
  phone: string;
  email: string;
  profession: ProfessionTypeEnum;
  register_number: [
    {
      id?: number
      register_number: string;
      register_number_uf: string;
      is_selected: boolean;
    }
  ];
}

export interface ListServiceLocationResponse {
  name_service_location: string;
  cnes: string;
  commercial_phone: string;
  zip_code: string;
  street: string;
  number: string;
  neighborhood: string;
  state: string;
  city: string;
}

export interface UpdateServiceLocationDto {
  name_service_location: string;
  cnes: string;
  commercial_phone: string;
  zip_code: string;
  street: string;
  number: string;
  neighborhood: string;
  state: string;
  city: string;
}

export class MeService {
  static async getMyData(): Promise<
    | ListSuperadminResponse
    | ListClinicManagerResponse
    | ListAdminResponse
    | ListDoctorResponse
    | ListOwnerDoctorResponse
    | ListReceptionistResponse
  > {
    try {
      const result = await httpClient.request({
        url: "/users/me",
        method: "get",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao buscar dados");
      throw err;
    }
  }

  static async updatePassword(input: UpdatePasswordProps): Promise<void> {
    try {
      await httpClient.request({
        url: `/users/me/password`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar senha");
      throw new Error("Falha ao atualizar senha");
    }
  }

  static async updateData(input: UpdateDataProps): Promise<void> {
    try {
      await httpClient.request({
        url: `/users/me`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar dados");
      throw new Error("Falha ao atualizar dados");
    }
  }

  static async getServiceLocationData(): Promise<ListServiceLocationResponse> {
    try {
      const result = await httpClient.request({
        url: "/users/me/service-location",
        method: "get",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao buscar local de atendimento");
      throw err;
    }
  }

  static async updateServiceLocationData(
    input: UpdateServiceLocationDto
  ): Promise<void> {
    try {
      await httpClient.request({
        url: `/users/me/service-location`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar local de atendimento");
      throw new Error("Falha ao atualizar local de atendimento");
    }
  }
}
