import { useEffect, useState } from "react";
import Select from "react-select";
import { ListAllEstimatedReturnAppointmentItems } from "services/schedule-service";
import {
  Badge,
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {
  SelectDoctorState,
  SelectDoctorStateProps,
} from "components/form/input/select-doctor-state-form";
import UserIdentity from "domain/user/entities/user-identity";
import { PaginationMeta } from "components/table/custom-table";
import { dateFormatter } from "helpers/date-formatter";
import { getTableType } from "helpers/screen";
import { actionListAllEstimatedReturnAppointments } from "actions/schedule/action-list-all-estimated-return-appointments";
import { EstimatedReturnTable } from "./estimated-return-table";
import { EstimatedReturnDetail } from "./estimated-return-detail";
import { actionListAllScheduleTypeLight } from "actions/schedule-types/action-listall-health-plans-light";
import scheduleStore from "~/state/schedule/schedule-store";

const OFFSET_DAYS = 1;
const PAGE_SIZE = 20;

interface FormValues {
  start?: Date;
  end?: Date;
  limit: number;
  page: number;
  patient_name?: string;
  doctor?: SelectDoctorStateProps;
  is_scheduled?: boolean;
}

export interface ListEstimatedReturnAppointmentItemTable {
  id: number;
  patient_name: any;
  doctor_name: any;
  current_appointment_date: any;
  estimated_return_date: any;
}

export const EstimatedReturnsContainer = () => {
  const [rawData, setRawData] = useState<
    ListAllEstimatedReturnAppointmentItems[]
  >([]);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());
  const [data, setData] = useState<ListEstimatedReturnAppointmentItemTable[]>(
    []
  );
  const [selectedItem, setSelectedItem] =
    useState<ListAllEstimatedReturnAppointmentItems | null>(null);

  const [formValues, setFormValues] = useState<FormValues>({
    start: undefined,
    end: undefined,
    patient_name: undefined,
    doctor: undefined,
    is_scheduled: false,
    limit: PAGE_SIZE,
    page: 1,
  });

  const onChangePage = async (page: number) => {
    if (page) {
      setFormValues({ ...formValues, page });
    }

    await fetchData(page);
  };

  const onRefresh = async () => {
    await fetchData();
    setSelectedItem(null);
  };

  const fetchData = async (page?: number) => {
    try {
      const response = await actionListAllEstimatedReturnAppointments({
        ...formValues,
        start: formValues.start
          ? new Date(formValues.start).toISOString()
          : undefined,
        end: formValues.end
          ? new Date(formValues.end).toISOString()
          : undefined,
        page: page ?? formValues.page,
        doctor_id: formValues.doctor
          ? String(formValues.doctor.value)
          : undefined,
      });

      const result = response.items.map((item) => ({
        id: item.id,
        patient_name: patientItemName(
          item.patient?.name ?? "",
          String(item.id)
        ),
        doctor_name: doctorItemName(item.doctor.name, String(item.id)),
        current_appointment_date: currentAppointmentDateItem(
          item.current_appointment_date,
          String(item.id)
        ),
        estimated_return_date: estimatedReturnDateItem(
          item.estimated_return_date,
          String(item.id)
        ),
      }));

      setMeta(response.meta);
      setRawData(response.items);
      setData(result);
    } catch {}
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      await actionListAllScheduleTypeLight();
    })();
  }, []);

  const onViewItem = (id: string) => {
    const result = rawData?.find((item) => item.id === Number(id));
    if (result) {
      setSelectedItem(result);
    }
  };

  const patientItemName = (name: string, id: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const doctorItemName = (name: string, id: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const currentAppointmentDateItem = (date: string, id: string) => {
    return (
      <div className="w-100">
        <span>{dateFormatter(date, "dd/MM/yyyy")}</span>
      </div>
    );
  };

  const estimatedReturnDateItem = (date: string, id: string) => {
    return (
      <div className="w-100">
        <span>{dateFormatter(date, "dd/MM/yyyy")}</span>
      </div>
    );
  };

  const optionsScheduleStatus = [
    {
      value: true,
      label: "Agendado",
    },
    {
      value: false,
      label: "Não Agendado",
    },
  ];

  return (
    <>
      <div className="main-wrapper">
        <div className="d-flex col-12 flex-wrap">
          {!UserIdentity.isDoctor() && (
            <div className="col-12 col-md-12 col-xl-6 px-2">
              <SelectDoctorState
                label="Profissional"
                selectedDoctor={formValues.doctor}
                setSelectedDoctor={(value: SelectDoctorStateProps) => {
                  setFormValues({
                    ...formValues,
                    doctor: value,
                  });
                }}
                setDefaultDoctor={true}
              />
            </div>
          )}

          <FormGroup className="col-12 col-md-12 col-xl-6 px-2">
            <Label htmlFor="name">Digite nome do paciente</Label>
            <InputGroup>
              <Input
                type="text"
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    patient_name: e.target.value,
                  });
                }}
                placeholder="Digite o nome do paciente"
                className="border-right-0"
              />
            </InputGroup>
          </FormGroup>
        </div>
        <div className="d-flex col-12 flex-wrap">
          <FormGroup className="col-12 col-md-12 col-xl-3 px-2">
            <Label htmlFor="name">Status do Retorno</Label>
            <Select
              name="status"
              options={optionsScheduleStatus}
              classNamePrefix="select"
              placeholder="Selecione status"
              defaultValue={optionsScheduleStatus[1]}
              onChange={(value) => {
                if (value) {
                  setFormValues({
                    ...formValues,
                    is_scheduled: value?.value,
                  });
                }
              }}
            />
          </FormGroup>

          <FormGroup className="col-6 col-md-6 col-xl-3 px-2">
            <Label htmlFor="name">Retorno (Início)</Label>
            <InputGroup>
              <Input
                type="date"
                onChange={(e) => {
                  const date = e.target.value
                    ? new Date(e.target.value)
                    : undefined;

                  setFormValues({
                    ...formValues,
                    start: date,
                  });
                }}
                className="border-right-0"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-6 col-md-6 col-xl-3 px-2">
            <Label htmlFor="name">Retorno (Fim)</Label>
            <InputGroup>
              <Input
                type="date"
                onChange={(e) => {
                  const date = e.target.value
                    ? new Date(e.target.value)
                    : undefined;

                  setFormValues({
                    ...formValues,
                    end: date,
                  });
                }}
                className="border-right-0"
              />
            </InputGroup>
          </FormGroup>

          <FormGroup className="col-12 col-md-12 col-xl-3 px-2 d-flex align-items-end">
            <Button
              color="primary"
              className="form-control"
              onClick={() => fetchData()}
            >
              Filtrar
            </Button>
          </FormGroup>
        </div>

        <div>
          <EstimatedReturnTable
            estimatedReturns={data}
            meta={meta}
            onSelectSchedule={onViewItem}
            onChangePage={onChangePage}
            tableType={tableType}
            setTableType={setTableType}
          />
        </div>

        <Modal
          isOpen={selectedItem !== null}
          toggle={() => setSelectedItem(null)}
          size="md"
        >
          <ModalHeader toggle={() => setSelectedItem(null)}>
            Detalhes
          </ModalHeader>
          <ModalBody>
            <EstimatedReturnDetail data={selectedItem} onRefresh={onRefresh} />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};
