import AsyncCreatableSelect from "react-select/async-creatable";
import { FormGroup, Label } from "reactstrap";
import { useFormikContext } from "formik";
import debounce from 'debounce-promise';

import { actionFetchAllPatients } from "actions/patients/action-fetchall-patients";
import { customStyles } from "./helpers/react-select-styles";

interface SelectPatientFormProps {
  onSelectChange?: (selectedOption: any) => void;
  onCreateOption?: (inputValue: string) => void;
  onSetIsReadonly?: (isReadonly: boolean) => void;
  onSetDefaultPhoneValue?: (phone: string) => void;
}
export const SelectPatientForm: React.FC<SelectPatientFormProps> = ({
  onSelectChange,
  onCreateOption,
  onSetDefaultPhoneValue,
  onSetIsReadonly
}: SelectPatientFormProps) => {
  const { setFieldValue, errors, values } = useFormikContext();

  const loadPatients = debounce(async (inputValue: string, callback: any) => {
    try {
      const response = await actionFetchAllPatients({
        name: inputValue,
        limit: 10,
        page: 1,
      });

      const options = response.items.map((user: any) => ({
        value: user.id,
        label: user.name,
        phone: user.phone,
        document_number: user.document_number,
      }));

      callback(options);
    } catch (error) {
      console.error("Error fetching users:", error);
      callback([]);
    }
  }, 700);

  const handleSelectChange = (selectedOption: any) => {
    setFieldValue("patient", selectedOption);
    setFieldValue("phone", selectedOption.phone);
    setFieldValue("document_number", selectedOption.document_number ?? "");
    
    if (onSetDefaultPhoneValue) onSetDefaultPhoneValue(selectedOption.phone);
    if (onSelectChange) onSelectChange(selectedOption);
    if (onSetIsReadonly) onSetIsReadonly(true);
  };

  const handleCreateOption = (inputValue: string) => {
    setFieldValue("patient", { label: inputValue, value: inputValue });
    setFieldValue("phone", "");

    if (onCreateOption) onCreateOption(inputValue);
    if (onSetIsReadonly) onSetIsReadonly(false);
  };

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor="name">Paciente</Label>
      <AsyncCreatableSelect
        cacheOptions  
        defaultOptions  
        name="patient"
        id="patient"
        value={(values as any)?.patient}
        loadOptions={loadPatients as any}
        onChange={handleSelectChange}
        placeholder="Selecione ou cadastre um paciente"
        onCreateOption={handleCreateOption}
        styles={customStyles}
      />
      {(errors as any)?.patient && (
        <div className="custom-invalid-feedback">Paciente é obrigatório</div>
      )}
    </FormGroup>
  );
};
