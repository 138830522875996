import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  HealthPlanService,
  UpdateHealthPlanProps,
} from "services/health-plan-service";
import listAllHealthPlansStore from "state/health-plans/list-all-health-plans-store";

export const actionUpdateHealthPlan = action(
  async (id: number, input: UpdateHealthPlanProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      const result = await HealthPlanService.updateHealthPlan(id, input);
      listAllHealthPlansStore.cleanData();
      return result;
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
