import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { enumIterator } from "helpers/typescript/enum-iterator";
import {
  ScheduleTypeStatusEnum,
  ScheduleTypeStatusEnumLabels,
} from "domain/schedule-types/entities/enums/schedule-type-status.enum";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectScheduleTypeStatusFormProps {
  keyName: string;
  isReadOnly?: boolean;
}

export const SelectScheduleTypeStatusForm: React.FC<
  SelectScheduleTypeStatusFormProps
> = ({ keyName, isReadOnly }: SelectScheduleTypeStatusFormProps) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue(keyName, undefined);
    setFieldValue(keyName, selectedOption);
  };

  const optionsUserStatus = enumIterator(ScheduleTypeStatusEnum).map((key) => ({
    value: ScheduleTypeStatusEnum[key],
    label: ScheduleTypeStatusEnumLabels(
      ScheduleTypeStatusEnum[key] as unknown as ScheduleTypeStatusEnum
    ),
  }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor={keyName}>Status</Label>
      <Field
        name={keyName}
        id={keyName}
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.[keyName]}
        placeholder="Status"
        options={optionsUserStatus}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
      />
      {(errors as any)?.[keyName] && (
        <div className="custom-invalid-feedback">Status inválido</div>
      )}
    </FormGroup>
  );
};
