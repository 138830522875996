import { ListMedicalRecord } from "services/medical-record-service";
import { MedicalRecordAnamensisItem } from "./components/medical-record-anamnesis-item";
import { MedicalRecordExamsItem } from "./components/medical-record-exams-item";
import { MedicalRecordProceduresItem } from "./components/medical-record-procedures-item";
import { MedicalRecordAttachmentsItem } from "./components/medical-record-attachments-item";
import { MedicalRecordEstimatedReturnDateItem } from "./components/medical-record-estimated-return-date-item";
import { MedicalRecordDoctorNameItem } from "./components/medical-record-doctor-name-item";

export const MedicalRecordItem: React.FC<ListMedicalRecord> = (item) => {
  return (
    <>
      <div className="d-flex row px-3">
        {item.estimated_return_date && (
          <div className="mt-3">
            <MedicalRecordEstimatedReturnDateItem
              estimated_return_date={item.estimated_return_date}
            />
          </div>
        )}
        {item.doctor_name && (
          <div className="mt-3">
            <MedicalRecordDoctorNameItem
              doctor_name={item.doctor_name}
            />
          </div>
        )}

        {item.anamnesis && (
          <div className="mt-3">
            <MedicalRecordAnamensisItem {...item.anamnesis} />
          </div>
        )}

        {item.exams && (
          <div className="mt-3">
            <MedicalRecordExamsItem exams={item.exams} />
          </div>
        )}
        {item.procedures && (
          <div className="mt-3">
            <MedicalRecordProceduresItem procedures={item.procedures} />
          </div>
        )}
        {item.attachments && (
          <div className="mt-3">
            <MedicalRecordAttachmentsItem attachments={item.attachments} />
          </div>
        )}
      </div>
    </>
  );
};
