import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";
import { HealthPlanStatusEnum } from "domain/health-plans/entities/enums/health-plan-status.enum";

export interface CreateHealthPlanProps {
  code: string;
  name: string;
  status: HealthPlanStatusEnum;
}

export interface UpdateHealthPlanProps extends CreateHealthPlanProps {}

export interface ListAllHealthPlansProps {
  status?: HealthPlanStatusEnum;
  fields?: string[];
}

export interface ListAllHealthPlansResponse {
  id: number;
  code?: string;
  name: string;
  status?: HealthPlanStatusEnum;
}

export class HealthPlanService {
  static async createHealthPlan(input: CreateHealthPlanProps): Promise<void> {
    try {
      const result = await httpClient.request({
        url: "/health-plans",
        method: "post",
        data: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao criar plano de saúde");
      throw new Error("Falha ao criar plano de saúde");
    }
  }

  static async listAllHealthPlans(input: ListAllHealthPlansProps): Promise<ListAllHealthPlansResponse[]> {
    try {
      const result = await httpClient.request({
        url: "/health-plans",
        method: "get",
        params: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao listar planos de saúde");
      throw new Error("Falha ao listar planos de saúde");
    }
  }

  static async updateHealthPlan(
    id: number,
    input: UpdateHealthPlanProps
  ): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/health-plans/${id}`,
        method: "patch",
        data: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao atualizar plano de saúde");
      throw new Error("Falha ao atualizar plano de saúde");
    }
  }

  static async deleteHealthPlan(id: number): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/health-plans/${id}`,
        method: "delete",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao deletar plano de saúde");
      throw new Error("Falha ao deletar plano de saúde");
    }
  }
}
