import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { CustomFormInput } from "components/form/input/custom-form-input";
import { SelectScheduleTypeStatusForm } from "components/form/input/select-schedule-type-status-form";
import {
  ScheduleTypeStatusEnum,
  ScheduleTypeStatusEnumLabels,
} from "domain/schedule-types/entities/enums/schedule-type-status.enum";
import { useState } from "react";

export interface FormScheduleTypeDataProps {
  data: ScheduleTypeProps | null;
  onSubmit: (data: ScheduleTypeProps) => void;
  onCancel: () => void;
}

export interface ScheduleTypeProps {
  id?: number;
  name: string;
  status: ScheduleTypeStatusEnum;
}

export interface ScheduleTypeFormValues {
  id?: number;
  name: string;
  status?: {
    value: ScheduleTypeStatusEnum;
    label: string;
  };
}

export const FormScheduleType: React.FC<FormScheduleTypeDataProps> = ({
  data,
  onSubmit,
  onCancel,
}) => {
  const [isSelectedColor, setIsSelectedColor] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    status: Yup.object().test(
      "custom-validation-status",
      "Status inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
  });

  const initialValues: ScheduleTypeFormValues = {
    id: data?.id,
    name: data?.name ?? "",

    status: {
      value: data?.status ?? ScheduleTypeStatusEnum.ACTIVE,
      label: ScheduleTypeStatusEnumLabels(
        data?.status ?? ScheduleTypeStatusEnum.ACTIVE
      ),
    },
  };

  const handleSubmit = async (values: ScheduleTypeFormValues) => {
    return await onSubmit({
      ...values,
      status: values.status?.value ?? ScheduleTypeStatusEnum.ACTIVE,
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            {/* <FormGroup className="col-12 ">
              <Label htmlFor="name">Cor do Agendamento</Label>
              <div className="">
                <Button onClick={() => setIsSelectedColor(true)}>
                  CHANGE_ME
                </Button>
              </div>
            </FormGroup> */}

            <FormGroup className="col-12 ">
              <Label htmlFor="name">Nome</Label>
              <Field
                name="name"
                id="name"
                placeholder="Nome do tipo de agendamento"
                type="text"
                component={CustomFormInput}
              />
            </FormGroup>

            <FormGroup className="col-12">
              <SelectScheduleTypeStatusForm keyName="status" />
            </FormGroup>
            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <div className="col-12 col-md-3 ">
                <Button
                  color="secondary"
                  className="form-control"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-12 col-md-3 ">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>

      {/* <Modal
        isOpen={isSelectedColor}
        toggle={() => setIsSelectedColor(false)}
        size="md"
      >
        <ModalHeader toggle={() => setIsSelectedColor(false)}>
          Cor do Agendamento
        </ModalHeader>
        <ModalBody>
          <ul className="reset-list pull-left" style={{marginRight: '2px'}}>
            <li
              data-name="Vermelho 1"
              data-id="12"
              style={{color: "#ffffff", backgroundColor: "#e0594b", borderColor: "#c63f31"}}
            >
              A
            </li>
           
          </ul>
        </ModalBody>
      </Modal> */}
    </>
  );
};
