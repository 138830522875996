import { useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import { ListReceptionistsTable } from "views/painel/employees/index/list-receptionists/list-receptionists-table";
import { ListReceptionistsResponse } from "services/user-service";
import { actionFetchAllReceptionists } from "actions/users/action-fetchall-receptionists";
import { dateFormatter } from "helpers/date-formatter";
import { getTableType } from "helpers/screen";

export const ReceptionistsContainer: React.FC = () => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();

  const [receptionists, setReceptionists] =
    useState<ListReceptionistsResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputSearch, setInputSearch] = useState("");
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());

  const fetchReceptionists = async (page?: number, input?: string) => {
    const result = await actionFetchAllReceptionists({
      limit: PAGE_SIZE,
      page: page || currentPage,
      name: input || inputSearch,
    });

    const items = result.items.map((item) => ({
      ...item,
      created_at: dateFormatter(new Date(item.created_at), "dd/MM/yyyy hh:mm"),
    }));
    setReceptionists({ items, meta: result.meta });
  };
  useEffect(() => {
    (async () => {
      await fetchReceptionists();
    })();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
    await fetchReceptionists(1, inputSearch);
  };

  const onSelectReceptionist = (receptionistId: string) => {
    navigate(`/painel/recepcionista/editar/${receptionistId}`);
  };

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchReceptionists(page);
  };

  return (
    <div className="main-wrapper">
      <Row className="d-flex justify-content-between mt-2 mb-3">
        <Form
          className="d-flex col-12  col-xl-5 align-self-center"
          onSubmit={onSubmit}
        >
          <InputGroup>
            <Input
              type="text"
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              className="border-right-0"
              placeholder="Buscar por nome"
            />
            <InputGroupText className="bg-white">
              <button type="submit" className="border-0 bg-white">
                <i
                  className="fa fa-search text-primary fs-20"
                  aria-hidden="true"
                ></i>
              </button>
            </InputGroupText>
          </InputGroup>
        </Form>

        <div className="d-flex col-12 col-xl-3 my-2 justify-content-end">
            <Button
              color="primary"
              onClick={() => navigate("/painel/recepcionista/criar")}
            >
              + Criar Recepcionista
            </Button>
        </div>
      </Row>

      <ListReceptionistsTable
        receptionists={receptionists}
        onSelectReceptionist={onSelectReceptionist}
        onChangePage={onChangePage}
        tableType={tableType}
        setTableType={setTableType}
      />
    </div>
  );
};
