import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  HealthPlanService,
  ListAllHealthPlansProps,
  ListAllHealthPlansResponse,
} from "services/health-plan-service";

export const actionListAllHealthPlan = action(
  async (
    input: ListAllHealthPlansProps
  ): Promise<ListAllHealthPlansResponse[]> => {
    SetIsLoadingState(true);

    try {
      return await HealthPlanService.listAllHealthPlans(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
