import "react-datepicker/dist/react-datepicker.css";
import { Button, FormGroup, Label, Row } from "reactstrap";
import { validateCPF } from "validators/validate-cpf";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { isPhoneValid } from "helpers/phone-validator";

export interface FormMyDataAdminProps {
  data: DataProps;
  onSubmit: (data: DataProps) => void;
}

export interface DataProps {
  email: string;
  name: string;
  phone: string;
  document_number: string | null;
}

export interface DataFormValues {
  email: string;
  name: string;
  phone: string;
  document_number: string | null;
}

export const FormMyDataAdmin: React.FC<FormMyDataAdminProps> = ({
  data,
  onSubmit,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    phone: Yup.string().test("phone", "Telefone inválido", (value) => {
      return isPhoneValid(value ?? "");
    }),
    email: Yup.string().email("E-mail inválido").required(),
  });

  const initialValues: DataFormValues = {
    name: data.name,
    email: data.email,
    document_number: data.document_number,
    phone: data.phone,
  };

  const handleSubmit = async (values: DataFormValues) => {
    return await onSubmit(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="name">Nome completo*</Label>
                <Field
                  name="name"
                  id="name"
                  placeholder="Nome "
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="document_number">CPF</Label>
                <Field
                  name="document_number"
                  id="document_number"
                  placeholder="CPF"
                  type="text"
                  mask="000.000.000-00"
                  disabled={true}
                  component={CustomMaskInput}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="email">E-mail*</Label>
                <Field
                  name="email"
                  id="email"
                  placeholder="Digite e-mail"
                  type="email"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="email">Telefone*</Label>
                <Field
                  name="phone"
                  id="phone"
                  placeholder="(00) 00000-0000"
                  type="text"
                  mask="(00) 00000-0000"
                  component={CustomMaskInput}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <div className="col-12 col-md-3 ">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
