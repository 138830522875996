import { Button } from "reactstrap";
import "./custom-table-not-found.style.css";

export interface CustomTableNotFoundProps {
  buttonName: string;
  onClick: () => void;
}
export const CustomTableNotFound: React.FC<CustomTableNotFoundProps> = ({
  buttonName,
  onClick,
}) => {
  return (
    <div className="table-not-found-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 24 24"
        height="100"
        width="100"
      >
        <path d="m15.5,15c-1.378,0-2.5,1.122-2.5,2.5v3c0,1.378,1.122,2.5,2.5,2.5s2.5-1.122,2.5-2.5v-3c0-1.378-1.122-2.5-2.5-2.5Zm1.5,5.5c0,.827-.673,1.5-1.5,1.5s-1.5-.673-1.5-1.5v-3c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v3ZM19.5,1H4.5C2.019,1,0,3.019,0,5.5v13c0,2.481,2.019,4.5,4.5,4.5h2c.276,0,.5-.224.5-.5s-.224-.5-.5-.5h-2c-1.93,0-3.5-1.57-3.5-3.5v-9.5h22v3.5c0,.276.224.5.5.5s.5-.224.5-.5v-7c0-2.481-2.019-4.5-4.5-4.5ZM1,8v-2.5c0-1.93,1.57-3.5,3.5-3.5h15c1.93,0,3.5,1.57,3.5,3.5v2.5H1Zm4-3c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm3,0c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm3,0c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm13,10.5v7c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-1.5h-2c-1.103,0-2-.897-2-2v-3.5c0-.276.224-.5.5-.5s.5.224.5.5v3.5c0,.551.449,1,1,1h2v-4.5c0-.276.224-.5.5-.5s.5.224.5.5Zm-12,0v7c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-1.5h-2c-1.103,0-2-.897-2-2v-3.5c0-.276.224-.5.5-.5s.5.224.5.5v3.5c0,.551.449,1,1,1h2v-4.5c0-.276.224-.5.5-.5s.5.224.5.5Z" />
      </svg>
      <h4 className="mt-3">Não encontramos nenhum resultado para sua busca</h4>
      <br />
      {buttonName && (
        <Button color="secondary" onClick={onClick}>
          {buttonName}
        </Button>
      )}
    </div>
  );
};
