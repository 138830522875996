import { CreateReceptionist } from "views/painel/employees/create/create-receptionist/create-receptionist";
import { UpdateReceptionist } from "views/painel/employees/update/update-receptionist/update-receptionist";
import { CreateDoctor } from "views/painel/employees/create/create-doctor/create-doctor";
import { UpdateDoctor } from "views/painel/employees/update/update-doctor/update-doctor";
import { ListDoctor } from "views/painel/employees/list/list-doctor/list-doctor";
import { MedicalRecord } from "views/painel/medical-record/index/medical-record";
import UpdatePatient from "views/painel/patients/update-patient/update-patient";
import ForgetPassword from "views/auth/forgot-password/forgot-password";
import CreatePatient from "views/painel/patients/create/create-patient";
import ListPatient from "views/painel/patients/list/list-patient";
import SelectTenant from "views/auth/select-tenant/select-tenant";
import Employees from "views/painel/employees/index/employees";
import Patients from "views/painel/patients/index/patients";
import ScheduleContainer from "views/painel/schedule/index/schedule.container";
import DashboardContainer from "views/painel/dashboard/dashboard.container";
import MyData from "views/painel/my-data/index/my-data";
import Register from "views/auth/register/register";
import Login from "views/auth/login/login";
import FirstLogin from "views/auth/first-login/first-login";
import Term from "views/term/term";
import { PrescriptionContainer } from "views/painel/prescription/index/prescription.container";
import UserIdentity from "./domain/user/entities/user-identity";
import { UserRoleEnum } from "./domain/user/entities/enums/user-role.enum";
import Privacy from "views/term/privacy";
import { ListPrescriptionContainer } from "views/painel/prescription/list/list-prescription.container";
import { FinancialStatementContainer } from "./views/painel/financial/statement/index/financial-statement.container";
import { CreateAdminDoctor } from "./views/painel/employees/create/create-admin-doctor/create-admin-doctor";
import { UpdateAdminDoctor } from "./views/painel/employees/update/update-admin-doctor/update-admin-doctor";
import { ListAdminDoctor } from "./views/painel/employees/list/list-admin-doctor/list-admin-doctor";
import { CreateAdmin } from "./views/painel/employees/create/create-admin/create-admin";
import { UpdateAdmin } from "./views/painel/employees/update/update-admin/update-receptionist";
import { DoctorsContainer } from "./views/painel/employees/index/list-doctors/doctors.container";
import { AdminDoctorsContainer } from "./views/painel/employees/index/list-admin-doctors/admin-doctors.container";
import { AdminsContainer } from "./views/painel/employees/index/list-admins/admins.container";
import { ReceptionistsContainer } from "./views/painel/employees/index/list-receptionists/receptionists.container";
import { UpdatePasswordContainer } from "./views/painel/my-data/index/tabs/update-password/update-password.container";
import { MyDataContainer } from "./views/painel/my-data/index/my-data/my-data.container";
import { TenantDataContainer } from "./views/painel/configurations/tabs/tenant-data/tenant-data.container";
import { PrescriptionValidationCodeContainer } from "./views/auth/prescription-validation-code/prescription-validation-code.container";
import { HealthPlanContainer } from "./views/painel/configurations/tabs/health-plans/health-plan.container";
import { ScheduleTypeContainer } from "./views/painel/configurations/tabs/schedule-types/schedule-types.container";
import { ScheduleHistoryContainer } from "./views/painel/appointments/history-schedule/schedule-history.container";
import { EstimatedReturnsContainer } from "./views/painel/appointments/returns/estimated-returns.container";

export interface RouteItemProps {
  path: string;
  name: string;
  icon: string;
  component: React.FC;
  layout: string;
  isHidden: boolean;
  breadcrumb?: {
    path: string;
    name: string;
  };
}

export interface RouteProps extends RouteItemProps {
  children?: RouteItemProps[];
}

export const routes: RouteProps[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-line",
    component: DashboardContainer,
    layout: "/painel",
    isHidden: !UserIdentity.canAcessPage("/painel/dashboard"),
  },
  {
    path: "/agenda",
    name: "Agenda",
    icon: "fas fa-calendar-days",
    component: ScheduleContainer,
    layout: "/painel",
    isHidden: !UserIdentity.canAcessPage("/painel/agenda"),
  },
  {
    path: "/compromissos",
    name: "Compromissos",
    icon: "fas fa-address-card",
    component: ScheduleContainer,
    layout: "/painel",
    isHidden: !UserIdentity.canAcessPage("/painel/compromissos"),
    children: [
      {
        path: "/compromissos/retornos",
        name: "Retornos",
        icon: "fas fa-users",
        component: EstimatedReturnsContainer,
        layout: "/painel",
        isHidden: !UserIdentity.hasRole([
          UserRoleEnum.SUPERADMIN,
          UserRoleEnum.ADMIN,
          UserRoleEnum.CLINIC_MANAGER,
          UserRoleEnum.OWNER_DOCTOR,
          UserRoleEnum.ADMIN_DOCTOR,
          UserRoleEnum.RECEPTIONIST,
        ]),
        breadcrumb: {
          path: "/painel//compromissos/retornos",
          name: "Lista de Retornos",
        },
      },
      {
        path: "/compromissos/historico-agendamentos",
        name: "Histórico ",
        icon: "fas fa-users",
        component: ScheduleHistoryContainer,
        layout: "/painel",
        isHidden: !UserIdentity.hasRole([
          UserRoleEnum.SUPERADMIN,
          UserRoleEnum.ADMIN,
          UserRoleEnum.CLINIC_MANAGER,
          UserRoleEnum.OWNER_DOCTOR,
          UserRoleEnum.ADMIN_DOCTOR,
          UserRoleEnum.RECEPTIONIST,
        ]),
        breadcrumb: {
          path: "/painel//compromissos/historico-agendamentos",
          name: "Lista de Histórico de Agendamentos",
        },
      },
    ],
  },
  {
    path: "/prescricoes",
    name: "Prescrições",
    icon: "fas fa-pen-clip",
    component: PrescriptionContainer,
    layout: "/painel",
    isHidden: !UserIdentity.canAcessPage("/painel/prescricoes"),
  },
  {
    path: "/pacientes",
    name: "Prontuários",
    icon: "fas fa-user-injured",
    component: Patients,
    layout: "/painel",
    isHidden: !UserIdentity.hasRole([
      UserRoleEnum.DOCTOR,
      UserRoleEnum.OWNER_DOCTOR,
      UserRoleEnum.RECEPTIONIST,
      UserRoleEnum.ADMIN_DOCTOR,
      UserRoleEnum.ADMIN,
    ]),
  },
  {
    path: "/colaboradores",
    name: "Colaboradores",
    icon: "fas fa-users",
    component: Employees,
    layout: "/painel",
    isHidden: !UserIdentity.hasRole([
      UserRoleEnum.SUPERADMIN,
      UserRoleEnum.ADMIN,
      UserRoleEnum.CLINIC_MANAGER,
      UserRoleEnum.OWNER_DOCTOR,
      UserRoleEnum.DOCTOR,
      UserRoleEnum.ADMIN_DOCTOR,
    ]),
    children: [
      {
        path: "/colaboradores/medicos-administradores",
        name: "Médicos Admin",
        icon: "fas fa-users",
        component: AdminDoctorsContainer,
        layout: "/painel",
        isHidden: !UserIdentity.hasRole([
          UserRoleEnum.SUPERADMIN,
          UserRoleEnum.ADMIN,
          UserRoleEnum.CLINIC_MANAGER,
          UserRoleEnum.OWNER_DOCTOR,
          UserRoleEnum.ADMIN_DOCTOR,
        ]),
        breadcrumb: {
          path: "/painel/colaboradores/medicos-administradores",
          name: "Lista de Médicos Administradores",
        },
      },
      {
        path: "/colaboradores/medicos",
        name: "Médicos",
        icon: "fas fa-users",
        component: DoctorsContainer,
        layout: "/painel",
        isHidden: !UserIdentity.hasRole([
          UserRoleEnum.SUPERADMIN,
          UserRoleEnum.ADMIN,
          UserRoleEnum.CLINIC_MANAGER,
          UserRoleEnum.OWNER_DOCTOR,
          UserRoleEnum.ADMIN_DOCTOR,
        ]),
        breadcrumb: {
          path: "/colaboradores/medicos",
          name: "Lista de Médicos",
        },
      },
      {
        path: "/colaboradores/administradores",
        name: "Administradores",
        icon: "fas fa-users",
        component: AdminsContainer,
        layout: "/painel",
        isHidden: !UserIdentity.hasRole([
          UserRoleEnum.SUPERADMIN,
          UserRoleEnum.ADMIN,
          UserRoleEnum.CLINIC_MANAGER,
          UserRoleEnum.OWNER_DOCTOR,
          UserRoleEnum.ADMIN_DOCTOR,
        ]),
      },
      {
        path: "/colaboradores/recepcionistas",
        name: "Recepcionistas",
        icon: "fas fa-users",
        component: ReceptionistsContainer,
        layout: "/painel",
        isHidden: !UserIdentity.hasRole([
          UserRoleEnum.SUPERADMIN,
          UserRoleEnum.ADMIN,
          UserRoleEnum.CLINIC_MANAGER,
          UserRoleEnum.OWNER_DOCTOR,
          UserRoleEnum.ADMIN_DOCTOR,
          UserRoleEnum.DOCTOR,
        ]),
      },
    ],
  },
  {
    path: "/financeiro/extrato",
    name: "Extrato Financeiro",
    icon: "fas fa-bars",
    component: FinancialStatementContainer,
    layout: "/painel",
    isHidden: !UserIdentity.hasRole([
      UserRoleEnum.SUPERADMIN,
      UserRoleEnum.ADMIN,
      UserRoleEnum.CLINIC_MANAGER,
      UserRoleEnum.OWNER_DOCTOR,
      UserRoleEnum.DOCTOR,
      UserRoleEnum.ADMIN_DOCTOR,
      UserRoleEnum.RECEPTIONIST,
    ]),
  },
  {
    path: "/configuracoes",
    name: "Configurações",
    icon: "fas fa-gears",
    component: TenantDataContainer,
    layout: "/painel",
    isHidden: !UserIdentity.canAcessPage("/painel/configuracoes"),
    children: [
      {
        path: "/clinica",
        name: "Clínica",
        icon: "fas fa-users",
        component: TenantDataContainer,
        layout: "/painel",
        isHidden: false,
      },
      {
        path: "/convenios",
        name: "Convênios",
        icon: "fas fa-users",
        component: HealthPlanContainer,
        layout: "/painel",
        isHidden: false,
      },
      {
        path: "/tipos-agendamentos",
        name: "Tipos Agendamentos",
        icon: "fas fa-users",
        component: ScheduleTypeContainer,
        layout: "/painel",
        isHidden: false,
      },
    ],
  },
  {
    path: "/meus-dados",
    name: "Minha Conta",
    icon: "fas fa-id-card",
    component: MyData,
    layout: "/painel",
    isHidden: false,
    children: [
      {
        path: "/meus-dados",
        name: "Meus Dados",
        icon: "fas fa-users",
        component: MyDataContainer,
        layout: "/painel",
        isHidden: false,
      },
      {
        path: "/meus-dados/alterar-senha",
        name: "Alterar Senha",
        icon: "fas fa-users",
        component: UpdatePasswordContainer,
        layout: "/painel",
        isHidden: false,
      },
    ],
  },
  {
    path: "/prescricao/:id",
    name: "Prescrições",
    icon: "fas fa-pen-clip",
    component: ListPrescriptionContainer,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/prescricoes",
      name: "Lista de Prescrições",
    },
  },
  {
    path: "/prontuario/:id",
    name: "Prontuário",
    icon: "fas fa-clipboard-user",
    component: MedicalRecord,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de prontuários",
    },
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/cadastrar",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/esqueci-senha",
    name: "Forget Password",
    icon: "ni ni-key-25 text-info",
    component: ForgetPassword,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/aplicacoes",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: SelectTenant,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/primeiro-acesso",
    name: "Primeiro Acesso",
    icon: "ni ni-key-25 text-info",
    component: FirstLogin,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/pacientes/criar",
    name: "Pacientes",
    icon: "fas fa-user-injured",
    component: CreatePatient,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de Pacientes",
    },
  },
  {
    path: "/paciente/editar/:id",
    name: "Paciente",
    icon: "fas fa-user-injured",
    component: UpdatePatient,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de Pacientes",
    },
  },
  {
    path: "/paciente/:id",
    name: "Paciente",
    icon: "fas fa-user-injured",
    component: ListPatient,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de Pacientes",
    },
  },
  {
    path: "/medico/criar",
    name: "Médico",
    icon: "fas fa-user-injured",
    component: CreateDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/medicos",
      name: "Lista de Médicos",
    },
  },
  {
    path: "/medico/editar/:id",
    name: "Médico",
    icon: "fas fa-user-injured",
    component: UpdateDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/medicos",
      name: "Lista de médicos",
    },
  },
  {
    path: "/medico/:id",
    name: "Médico",
    icon: "fas fa-user-injured",
    component: ListDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/medicos",
      name: "Lista de médicos",
    },
  },
  {
    path: "/recepcionista/criar",
    name: "Recepcionista",
    icon: "fas fa-user-injured",
    component: CreateReceptionist,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/recepcionistas",
      name: "Lista de Recepcionistas",
    },
  },
  {
    path: "/recepcionista/editar/:id",
    name: "Recepcionista",
    icon: "fas fa-user-injured",
    component: UpdateReceptionist,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/recepcionistas",
      name: "Lista de Recepcionistas",
    },
  },
  {
    path: "/medico-administrador/criar",
    name: "Médico Administrador",
    icon: "fas fa-user-injured",
    component: CreateAdminDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/medicos-administradores",
      name: "Lista de Médicos Administradores",
    },
  },
  {
    path: "/medico-administrador/editar/:id",
    name: "Médico Administrador",
    icon: "fas fa-user-injured",
    component: UpdateAdminDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/medicos-administradores",
      name: "Lista de Médicos Administradores",
    },
  },
  {
    path: "/medico-administrador/:id",
    name: "Médico Administrador",
    icon: "fas fa-user-injured",
    component: ListAdminDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/administradores",
      name: "Lista de médicos",
    },
  },
  {
    path: "/administrador/criar",
    name: "Administrador",
    icon: "fas fa-user-injured",
    component: CreateAdmin,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/administradores",
      name: "Lista de Administradores",
    },
  },
  {
    path: "/administrador/editar/:id",
    name: "Administrador",
    icon: "fas fa-user-injured",
    component: UpdateAdmin,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores/administradores",
      name: "Lista de Administradores",
    },
  },
  {
    path: "/p/:id",
    name: "Validação Prescrição",
    icon: "ni ni-key-25 text-info",
    component: PrescriptionValidationCodeContainer,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/termos-uso",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Term,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/politica-privacidade",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Privacy,
    layout: "/auth",
    isHidden: true,
  },
];
