import {
  calculateAgeFromBirthdate,
  dateFormatter,
} from "helpers/date-formatter";
import "./patient-header-info.style.css";
import { documentMask } from "helpers/document-formatter";
import { maskedPhoneNumber } from "helpers/phone-formatter";
import { Button } from "reactstrap";

interface PatientHeaderInfoProps {
  id: number;
  name: string;
  birthdate?: Date | null;
  phone: string;
  document_number?: string;
  email?: string;
  first_appointment?: Date | null;
  last_appointment?: Date | null;
  onViewPatientData?: () => void;
}

export const PatientHeaderInfo: React.FC<PatientHeaderInfoProps> = ({
  name,
  birthdate,
  phone,
  document_number,
  first_appointment,
  last_appointment,
  onViewPatientData,
}) => {
  return (
    <>
      <div className="patient-header-info">
        <div className="patient-header-top" />
        <div className="patient-header-content">
          <div className="patient-content-img">
            <img
              alt="..."
              src={`https://ui-avatars.com/api/?name=${name}&size=40&rounded=true&color=fff&background=1632D2`}
            />
          </div>
          <div className="patient-content-data">
            <div className="d-flex col-12 col-md-6 row">
              <div className="col-12 align-self-center">
                <b className="font-weight-500 fs-20 text-black-500 w-100">
                  {name}
                </b>
              </div>
              <div className="col-12 mt-1 row">
                <span className="patient-content-label">Telefone</span>
                <span className="patient-content-text">
                  {maskedPhoneNumber(phone) || "Não informado"}
                </span>
              </div>
              <div className="col-12 mt-1 row">
                <span className="patient-content-label">CPF</span>
                <span className="patient-content-text">
                  {documentMask(document_number || "") || "Não informado"}
                </span>
              </div>
            </div>
            <div className="d-flex col-12 col-md-6 flex-wrap">
              <div className="col-12 mt-1 row">
                <span className="patient-content-label">Idade</span>
                <span className="patient-content-text">
                  {calculateAgeFromBirthdate(birthdate) || "Não informado"}
                </span>
              </div>
              <div className="col-12 mt-1 row">
                <span className="patient-content-label">
                  Primeiro atendimento
                </span>
                <span className="patient-content-text">
                  {dateFormatter(first_appointment) || "Não informado"}
                </span>
              </div>
              <div className="col-12 mt-1 row">
                <span className="patient-content-label">
                  Último atendimento
                </span>
                <span className="patient-content-text">
                  {dateFormatter(last_appointment) || "Não informado"}
                </span>
              </div>
              {onViewPatientData && (
                <div className="col-12 mt-1 row justify-content-end">
                  <Button
                    style={{ width: "fit-content" }}
                    onClick={onViewPatientData}
                  >
                    Visualizar dados do paciente
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
