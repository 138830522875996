import { action } from "mobx";

import { UpdateDoctorProps, UserService } from "services/user-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import listAllDoctorsSelectableStore from "state/doctors/list-all-doctors-selectable-store";

export const actionUpdateDoctor = action(
  async (id: string, input: UpdateDoctorProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await UserService.updateDoctor(id, input);
      listAllDoctorsSelectableStore.cleanData();
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
