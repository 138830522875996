import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  FinancialService,
  ListAllFinancialStatementResponse,
  ListFinancialStatementProps,
} from "services/financial-service";

export const actionListFinancialStatement = action(
  async (
    input: ListFinancialStatementProps
  ): Promise<ListAllFinancialStatementResponse> => {
    SetIsLoadingState(true);
    try {
      return await FinancialService.listFinancialStatement(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
