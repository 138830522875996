import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { observer } from "mobx-react";
import { routes, RouteProps } from "routes";

import PainelNavbar from "components/navbars/painel-navbar";
import LoadingSpinner from "components/spinner/loading-spinner";
import Sidebar from "components/sidebar/sidebar";
import { SpinnerState } from "state/spinner/spinner-state";

import "./painel-layout.style.css";
import { ToastContainer } from "react-toastify";
import DefaultFooter from "components/footers/default-footer/default-footer";
import { WhatsappIcon } from "components/icons/whatsapp-icon";

interface BrandProps {
  name: string;
  path?: string;
}
const PainelLayout = observer((props: any) => {
  const { isLoading } = SpinnerState;
  const mainContent = React.useRef(null);
  const location = useLocation();

  const getRoutes = (routes: RouteProps[]) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/painel") {
        const result = [];

        if (prop.children?.length) {
          result.push(
            prop.children.map((child, key2) => {
              return (
                <Route
                  path={child.path}
                  element={<child.component />}
                  key={`${key}-${key2}`}
                />
              );
            })
          );
        } else {
          result.push(
            <Route path={prop.path} element={<prop.component />} key={key} />
          );
        }

        return result;
      } else {
        return null;
      }
    });
  };

  const matchPath = (path: string, pattern: string) => {
    const pathSegments = path.split("/").filter(Boolean);
    const patternSegments = pattern.split("/").filter(Boolean);

    if (pathSegments.length !== patternSegments.length) {
      return false;
    }

    const params = {};
    for (let i = 0; i < pathSegments.length; i++) {
      if (patternSegments[i].startsWith(":")) {
        const paramName = patternSegments[i].slice(1);
        params[paramName] = pathSegments[i];
      } else if (pathSegments[i] !== patternSegments[i]) {
        return false;
      }
    }

    return params;
  };

  const getBrand = (): BrandProps => {
    for (let i = 0; i < routes.length; i++) {
      if (matchPath(location.pathname, routes[i].layout + routes[i].path)) {
        return routes[i].breadcrumb ?? { name: routes[i].name };
      }
    }
    return { name: "Início" };
  };

  return (
    <>
      <ToastContainer />
      {isLoading ? <LoadingSpinner /> : <></>}
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/painel/index",
          imgSrc: require("components/../../public/logo-white.png"),
          imgAlt: "Logo",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <PainelNavbar {...props} brandProps={getBrand()} />
        <Routes>
          {getRoutes(routes)}
          <Route
            path="*"
            element={<Navigate to="/painel/dashboard" replace />}
          />
        </Routes>
        <Container fluid>
          <DefaultFooter style="my-4 text-center fs-14 text-primary" />
        </Container>

        <a
          href="https://api.whatsapp.com/send?phone=+556296233808&text=Ola, tenho uma dúvida."
          className="float-button-whatsapp"
          target="_blank"
        >
          <WhatsappIcon marginLeft="0px" />
        </a>
      </div>
    </>
  );
});

export default PainelLayout;
