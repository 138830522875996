import { action } from "mobx";

import { UpdateAdminProps, UserService } from "services/user-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionUpdateAdmin = action(
  async (id: string, input: UpdateAdminProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await UserService.updateAdmin(id, input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
