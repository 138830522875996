import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";

import {
  FormReceptionistData,
  ReceptionistDataProps,
} from "views/painel/employees/common/receptionist/form/form-receptionist-data";
import { actionUpdateReceptionist } from "actions/users/action-update-receptionist";
import { actionFetchReceptionist } from "actions/users/action-fetch-receptionist";
import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { errorMessage, successMessage } from "helpers/toast";
import { onlyNumbers } from "helpers/only-numbers";

export const UpdateReceptionist: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [receptionistData, setReceptionistData] =
    useState<ReceptionistDataProps>({
      id: undefined,
      email: "",
      name: "",
      document_number: "",
      phone: "",
      status: UserStatusEnum.ACTIVE,
    });

  const fetchReceptionistData = async () => {
    if (id) {
      const response = await actionFetchReceptionist(id);

      const result = {
        ...response,
        document_number: _.isEmpty(response.document_number)
          ? ""
          : response.document_number,
      };

      setReceptionistData(result);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchReceptionistData();
    })();
  }, []);

  const onSubmitData = async (values: ReceptionistDataProps) => {
    try {
      if (id) {
        const input = {
          ...values,
          document_number: onlyNumbers(values?.document_number ?? "") ?? null,
          status: (values.status ?? UserStatusEnum.ACTIVE) as UserStatusEnum,
        };
        await actionUpdateReceptionist(id, input);
        successMessage("Recepcionista atualizada com sucesso");
        navigate(`/painel/colaboradores`);
      } else {
        errorMessage("Falha ao obter o id do colaborador");
      }
    } catch {}
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          {isLoaded && (
            <FormReceptionistData
              receptionistData={receptionistData}
              onSubmit={onSubmitData}
              onCancel={() => navigate(`/painel/colaboradores/recepcionistas`)}
            />
          )}
        </div>
      </div>
    </>
  );
};
