import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import {
  ListAllScheduleTypesProps,
  ListAllScheduleTypesResponse,
  ScheduleTypeService,
} from "services/schedule-type-service";

export const actionListAllScheduleType = action(
  async (
    input: ListAllScheduleTypesProps
  ): Promise<ListAllScheduleTypesResponse[]> => {
    SetIsLoadingState(true);

    try {
      return await ScheduleTypeService.listAllScheduleTypes(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
