import { makeAutoObservable } from "mobx";
import { ListDoctorsResponse } from "services/user-service";

class ListAllDoctorsSelectableStore {
  response: ListDoctorsResponse | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setResponse(response: ListDoctorsResponse) {
    this.response = response;
  }

  getResponse(): ListDoctorsResponse | null {
    return this.response;
  }

  cleanData(): void {
    this.response = null;
  }
}

const listAllDoctorsSelectableStore = new ListAllDoctorsSelectableStore();

export default listAllDoctorsSelectableStore;
