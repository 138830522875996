import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessage } from "helpers/toast";
import { ListEletronicPrescriptionItemResponse } from "services/eletronic-prescription-service";
import { actionListPrescription } from "actions/eletronic-prescription/action-list-prescription";
import { PrescriptionHeaderInfo } from "./prescription-header-info/prescription-header-info";
import { ListPrescriptionTabs } from './list-prescription-tabs/list-prescription-tabs';

export const ListPrescriptionContainer = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [prescription, setPrescription] =
    useState<ListEletronicPrescriptionItemResponse>();

  const fetchPrescription = async (id: string) => {
    try {
      const response = await actionListPrescription(Number(id));
      setPrescription(response);
    } catch (error) {
      navigate("/painel/prescricoes");
    }
  };
  useEffect(() => {
    (async () => {
      if (id) {
        await fetchPrescription(id);
      } else {
        errorMessage("Falha ao obter prescrição");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-wrapper">
      {prescription && (
        <>
          <PrescriptionHeaderInfo
            {...{
              patientName: prescription.patient_name,
              doctorName: prescription.doctor_name,
              date: prescription.created_at,
              validationCode: prescription.validation_code ?? prescription.external_validation_code,
            }}
          />
          <ListPrescriptionTabs {...prescription} />
        </>
      )}
    </div>
  );
};
