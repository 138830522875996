export enum ScheduleBlockedStatusEnum {
  BLOCKED = 1,
  NOT_BLOCKED = 0,
}

export const ScheduleBlockedStatusLabels = (scheduleStatus: ScheduleBlockedStatusEnum) => {
  const labels = {
    [ScheduleBlockedStatusEnum.BLOCKED]: 'Bloqueado',
    [ScheduleBlockedStatusEnum.NOT_BLOCKED]: 'Não Bloqueado',
  };

  return labels[scheduleStatus];
};
