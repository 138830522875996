export enum UserSexEnum {
  MALE = "M",
  FEMALE = "F",
  NEUTRAL = "N",
}

export const UserSexEnumLabels = (sex: UserSexEnum) => {
  const labels = {
    [UserSexEnum.MALE]: "Masculino",
    [UserSexEnum.FEMALE]: "Feminino",
    [UserSexEnum.NEUTRAL]: "Não informado",
  };

  return labels[sex];
};
