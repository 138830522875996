import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";

import {
  FormAdminData,
  AdminDataProps,
} from "views/painel/employees/common/admin/form/form-admin-data";
import { actionUpdateAdmin } from "actions/users/admin/action-update-admin";
import { actionFetchAdmin } from "actions/users/admin/action-fetch-admin";
import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { errorMessage, successMessage } from "helpers/toast";
import { onlyNumbers } from "helpers/only-numbers";

export const UpdateAdmin: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [adminData, setAdminData] = useState<AdminDataProps>({
    id: undefined,
    email: "",
    name: "",
    document_number: "",
    phone: "",
    status: UserStatusEnum.ACTIVE,
  });

  const fetchAdminData = async () => {
    if (id) {
      const response = await actionFetchAdmin(id);

      const result = {
        ...response,
        document_number: _.isEmpty(response.document_number)
          ? ""
          : response.document_number,
      };

      setAdminData(result);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAdminData();
    })();
  }, []);

  const onSubmitData = async (values: AdminDataProps) => {
    try {
      if (id) {
        const input = {
          ...values,
          document_number: onlyNumbers(values?.document_number ?? "") ?? null,
          status: (values.status ?? UserStatusEnum.ACTIVE) as UserStatusEnum,
        };
        await actionUpdateAdmin(id, input);
        successMessage("Recepcionista atualizada com sucesso");
        navigate(`/painel/colaboradores`);
      } else {
        errorMessage("Falha ao obter o id do colaborador");
      }
    } catch {}
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          {isLoaded && (
            <FormAdminData
              adminData={adminData}
              onSubmit={onSubmitData}
              onCancel={() => navigate(`/painel/colaboradores/administradores`)}
            />
          )}
        </div>
      </div>
    </>
  );
};
