import { useEffect, useState } from "react";

import { CustomTab } from "components/tab/custom-tab";
import ScheduleCalendarTab from "./tabs/calendar/schedule-calendar-tab";
import { ScheduleHistoryTab } from "./tabs/history/schedule-history-tab";
import { actionListAllScheduleTypeLight } from "actions/schedule-types/action-listall-health-plans-light";

const ScheduleContainer: React.FC = () => {
  useEffect(() => {
    (async () => {
      await actionListAllScheduleTypeLight();
    })();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        <ScheduleCalendarTab />
      </div>
    </>
  );
};
export default ScheduleContainer;
