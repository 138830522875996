import * as Yup from "yup";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Button, FormGroup, Input, Label, Row } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { useState } from "react";
import { validateCPF } from "validators/validate-cpf";
import { isPhoneValid } from "helpers/phone-validator";
import { UserSexEnum, UserSexEnumLabels } from 'domain/user/entities/enums/user-sex.enum';

export interface FormPatientDataProps {
  patientData: PatientDataFormValues;
  onSubmit: (patient: PatientDataFormValues) => void;
  onBack: () => void;
  isReadOnly?: boolean;
}

export interface PatientDataFormValues {
  id?: string;
  name: string;
  document_number: string;
  email: string;
  phone: string;
  birthdate: string;
  sex: UserSexEnum;
}

export const FormPatientData: React.FC<FormPatientDataProps> = ({
  patientData,
  onSubmit,
  onBack,
  isReadOnly,
}) => {
  const [disableDocumentNumber, setDisableDocumentNumber] = useState(
    patientData.id !== undefined && patientData.document_number === ""
  );

  let validationSchema = null;
  if (!isReadOnly) {
    validationSchema = Yup.object().shape({
      name: Yup.string().required("Nome é obrigatório"),
      phone: Yup.string().test("phone", "Telefone inválido", (value) => {
        return isPhoneValid(value ?? "");
      }),
      email: Yup.string().email("E-mail inválido"),
      document_number: Yup.string().test(
        "custom-validation",
        "CPF inválido",
        function (value) {
          if (disableDocumentNumber) return true;

          return validateCPF(value ?? "");
        }
      ),
    });
  }

  const initialValues: PatientDataFormValues = {
    name: patientData.name,
    document_number: patientData.document_number,
    email: patientData.email,
    phone: patientData.phone,
    birthdate: patientData.birthdate,
    sex: patientData.sex,
  };

  const handleSubmit = async (values: PatientDataFormValues) => {
    return await onSubmit({
      ...values,
      document_number: disableDocumentNumber ? "" : values.document_number,
    });
  };

  const DocumentNumberCheckboxForm: React.FC = () => {
    const { setFieldValue } = useFormikContext<PatientDataFormValues>();

    const handleOnChangeDocumentNumberCheckbox = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setDisableDocumentNumber(e.target.checked);
      if (e.target.checked) setFieldValue("document_number", "000.000.000-00");
      else setFieldValue("document_number", "");
    };

    return (
      <FormGroup check>
        <Input
          id="disable_document_number"
          type="checkbox"
          name="disable_document_number"
          checked={disableDocumentNumber}
          onChange={handleOnChangeDocumentNumberCheckbox}
          disabled={isReadOnly}
        />{" "}
        <Label htmlFor="disable_document_number" check>
          Paciente não possui CPF
        </Label>
      </FormGroup>
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="name">Nome completo*</Label>
                <Field
                  name="name"
                  id="name"
                  placeholder="Nome do paciente"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="document_number">CPF do paciente</Label>
                <Field
                  name="document_number"
                  id="document_number"
                  placeholder={`${
                    disableDocumentNumber
                      ? "Paciente sem CPF"
                      : "CPF do paciente"
                  } `}
                  disabled={disableDocumentNumber || isReadOnly}
                  type="text"
                  mask="000.000.000-00"
                  component={CustomMaskInput}
                />
                <DocumentNumberCheckboxForm />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="email">E-mail</Label>
                <Field
                  name="email"
                  id="email"
                  placeholder="Digite seu e-mail"
                  type="email"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="email">Telefone*</Label>
                <Field
                  name="phone"
                  id="phone"
                  placeholder="(00) 00000-0000"
                  type="text"
                  mask="(00) 00000-0000"
                  component={CustomMaskInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="birthdate">Data de nascimento</Label>
                <Field
                  name="birthdate"
                  id="birthdate"
                  placeholder="Data de nascimento"
                  type="date"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="sex">Sexo do paciente</Label>
                <Field
                  name="sex"
                  id="sex"
                  className="form-select form-control"
                  placeholder="Sexo do paciente"
                  type="select"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                >
                  <option value={UserSexEnum.NEUTRAL}>{UserSexEnumLabels(UserSexEnum.NEUTRAL)}</option>
                  <option value={UserSexEnum.MALE}>{UserSexEnumLabels(UserSexEnum.MALE)}</option>
                  <option value={UserSexEnum.FEMALE}>{UserSexEnumLabels(UserSexEnum.FEMALE)}</option>
                </Field>
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <Row className="d-flex col-12 col-md-3 mx-0">
                <Button
                  color="secondary"
                  className="form-control"
                  onClick={onBack}
                >
                  Voltar
                </Button>
              </Row>
              <Row className="d-flex col-12 col-md-3 px-0 mx-0">
                <Button type="submit" color="primary" className="form-control">
                  Continuar
                </Button>
              </Row>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
